import React, { useEffect } from "react";
import { createStyles, TextInput } from "@mantine/core";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { ISiteData } from "types";

interface IProps {
  data: Array<ISiteData>;
  onSelect: (args: any) => void;
}

const useStyles = createStyles(() => ({
  inputIcon: {
    marginLeft: "3px",
  },
  input: {
    paddingLeft: "2.2rem !important",
  },
}));

const AgencySites = ({ data, onSelect }: IProps) => {
  const [value, setValue] = useState("");
  const [store, setStore] = useState(data);
  const { classes } = useStyles();

  useEffect(() => {
    setStore(data);
  }, [data]);

  const renderData = () =>
    store.map((item) => (
      <div
        key={item.siteData.siteID}
        className="my-2 cursor-pointer"
        onClick={() => {
          onSelect(item);
        }}
      >
        <span
          style={{ backgroundColor: item.siteData.colorTag }}
          className={`inline-block  h-[8px] w-[8px] mr-1 rounded-[50%]`}
        ></span>
        <span className="text-[15px] capitalize">{item.siteData.siteName}</span>
      </div>
    ));

  const handleSearch = (search) => {
    setValue(search);
    const searchBySiteName = data.filter(({ siteData: { siteName } }) => {
      return search
        ? RegExp(`^${search}`, "ig").test(siteName.trim().toLowerCase())
        : true;
    });
    const result = searchBySiteName.length ? searchBySiteName : data;
    setStore(result);
  };

  return (
    <div>
      <TextInput
        icon={<AiOutlineSearch size={18} />}
        placeholder="Search site"
        classNames={{
          input: classes.input,
          icon: classes.inputIcon,
        }}
        value={value}
        onChange={(evt) => handleSearch(evt.target.value)}
      />
      <div className="h-[300px] m-0 mb-5 overflow-auto">{renderData()}</div>
    </div>
  );
};

export default React.memo(AgencySites);
