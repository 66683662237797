import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FaChevronDown } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { Avatar, Menu } from "@mantine/core";
import DashboardLayout from "components/DashboardLayout";
import {
  MenuIcon,
  // NotificationIcon
} from "components/Svgs";
import BarChart from "components/BarChart";
import ProgressCard from "components/ProgressCard";
import CreateSiteCard from "components/CreateSiteCard";
import NotificationHover from "./NotificationCard";
import ProfileCard from "./ProfileCard";
import { IUser, SelectType } from "types";
import { queryKeys } from "utils/queryKeys";
import { getRequest } from "utils/apiCalls";
import { agencyRequest } from "services/request";
import MapScreen from "./MapScreen";
import { PROTECTED_PATHS } from "routes";
import SubScriberContext from "context/SubscriberContext";
import style from "styles/Home.module.css";
import useRolePermissions from "hooks/useRolePermissions";
import { baseURL } from "services/httpService";
import GotoSettingsModal from "components/GoToSettingsModal";

const Home = () => {
  const subscriber = useContext(SubScriberContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showMonths, setShowMonths] = useState("All");
  const [currYear, setCurrYear] = useState("currYear");
  const [modals, setModals] = useState({
    profile: false,
    site: false,
  });
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;
  const { permissions } = useRolePermissions(user);

  const { data: { graphData = {}, home = {} } = {} } = useQuery(
    [queryKeys.homeSummary],
    async () =>
      getRequest({ url: agencyRequest.HOME_SUMMARY(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: liveShifts = [] } = useQuery(
    [queryKeys.liveShift],
    async () =>
      getRequest({ url: agencyRequest.GET_LIVE_SHIFT(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: todayShifts = [] } = useQuery(
    [queryKeys.todayShifts],
    async () =>
      getRequest({ url: agencyRequest.TODAY_SHIFTS(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const showModal = (modal: string) => {
    setModals((prev) => ({
      ...prev,
      [modal]: true,
    }));
  };
  const hideModal = () => {
    setModals({
      profile: false,
      site: false,
    });
  };
  const changeMonth: SelectType = (e) => {
    const { value } = e.target;
    setShowMonths(value);
  };

  const changeYear: SelectType = (e) => {
    const { value } = e.target;
    setCurrYear(value);
  };

  const showSidebar = () => {
    const sideBarElement = document.getElementById("sidebar");
    sideBarElement?.classList.add("slideIn");
  };
  const yearOptions = [
    {
      name: graphData?.["workedAndBookedForCurrentYear"]?.[0]?.[0].year,
      value: "currYear",
    },
    {
      name: graphData?.["workedAndBookedForPreviousYear"]?.[0]?.[0].year,
      value: "prevYear",
    },
  ];

  return (
    <>
      <GotoSettingsModal visible={user.rulesSet} />
      <DashboardLayout>
        <div className={style.header}>
          <div className="flex items-center h-fit">
            <div
              className="block sm:hidden mr-3 cursor-pointer"
              onClick={showSidebar}
            >
              <MenuIcon />
            </div>
            <p className={style.title}>{user.agency.agencyName}</p>
          </div>
          <div className={`${style.user} items-center`}>
            <Menu width={200} shadow="md" offset={-5} position="bottom-start">
              <Menu.Target>
                <div className="flex items-center gap-1 cursor-pointer">
                  <p className="font-semibold text-base text-[#8353f7]">
                    {" "}
                    Help
                  </p>
                  <FaChevronDown color="#8353f7" size={15} />
                </div>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  component="a"
                  href={`${baseURL}/help/guide`}
                  target="_blank"
                  icon={<FiExternalLink />}
                >
                  Guide
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <div className="hidden sm:block w-fit">
              <p className="whitespace-nowrap">{user.staff.staffName}</p>
              <span className="whitespace-nowrap">{user.role.roleName}</span>
            </div>

            <div className="relative">
              <div
                className=" h-10 w-10 rounded-full cursor-pointer"
                // onClick={() => showModal("profile")}
              >
                <Avatar src={user.agencyLogo} alt="logo" radius="xl" />
              </div>
              {modals.profile && (
                <div className={style.profileContainer}>
                  <ProfileCard
                    hideModal={hideModal}
                    imageUrl={user.agencyLogo}
                  />
                </div>
              )}
            </div>
            <section className={style.notification}>
              <NotificationHover notifications={subscriber.notifications} />
              {/* <NotificationIcon />
            <div className={style.card}>
              <NotificationCard notifications={subscriber.notifications} />
            </div> */}
            </section>
          </div>
        </div>

        <span>Let's see how you are doing</span>
        <div className={style.progressContainer}>
          <ProgressCard
            name="Staff"
            value={home?.totalStaff}
            percentage={home.percentageStaffFromLastMonth}
            color="#CFE5FC"
            progress={home?.staffArrow}
          />
          <ProgressCard
            name="Rotas"
            value={home?.totalShifts}
            percentage={home.percentageShiftFromLastWeek}
            color="#CFE5FC"
            progress={home?.shiftArrow}
          />
          <ProgressCard
            name="Active Site"
            value={home?.totalActiveSitesForToday}
            percentage={home.percentageSitesFromYesterday}
            color="#FFE4F1"
            progress={home?.sitesArrow}
          />
          <ProgressCard
            name="Hours"
            value={home?.totalHoursWorkedForMonth}
            percentage={home.percentageHoursFromLastMonth}
            color="#AE90E8"
            progress={home?.hoursArrow}
          />
          <ProgressCard
            name="Payout"
            value={home?.totalPayout}
            percentage={home.percentagePayOutFromLastMonth}
            color="#D3D5FE"
            progress={home?.payArrow}
          />
        </div>
        <div className={style.sitesShift}>
          <div className={style.sitesContainer}>
            <p className={style.active}>Live Shifts</p>
            <MapScreen siteMap={liveShifts} />
            {(permissions.all || permissions.shifts) && (
              <button
                className={style.addNew}
                onClick={() => showModal("site")}
              >
                + New Site
              </button>
            )}

            <div className="max-w-full px-10 sm:px-0">
              {modals.site && (
                <CreateSiteCard
                  hideModal={hideModal}
                  agencyID={user.agency.agencyID}
                />
              )}
            </div>
          </div>
          <div className={style.shiftContainer}>
            <div className={style.header}>
              <p>Rotas for today</p>
              {/* <Button
              classNames={{ root: classes.root }}
              onClick={() => navigate(PROTECTED_PATHS.SHIFTS)}
            >
              Create Shift
            </Button> */}
              {(permissions.all || permissions.shifts) && (
                <button
                  className={style.btn}
                  onClick={() => navigate(PROTECTED_PATHS.SHIFTS)}
                >
                  Create Rota
                </button>
              )}
            </div>
            <div className={style.subHeader}>
              <span>Site</span>
              <span>Time</span>
              <span>Assigned To</span>
            </div>
            {[...todayShifts].slice(0, 5).map((item, idx) => (
              <div className={style.singleShift} key={idx}>
                <div className={style.site}>
                  <div className={style.indicator}></div>
                  <span className="font-semibold">{item.site.siteName}</span>
                </div>
                <div className={`${style.time}`}>
                  <span className="mb-3">
                    {" "}
                    {item.startTime} - {item.endTime}
                  </span>
                </div>
                <div className={style.assigned}>
                  <span className="mb-3">
                    {item.user.userFirstname} {item.user.userLastname}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="relative mt-10  border-4 border-text pt-20 lg:pt-4 sm:px-6 -mx-4 mb-5 sm:mx-0 rounded-[15px]">
          <BarChart
            currYear={currYear}
            showMonth={showMonths}
            data={graphData}
          />
          <div className="absolute top-4 right-3 flex">
            <select
              className="w-36 text-text mr-4 p-2 border rounded-lg border-[#D5D6D7] outline-[#D5D6D7]"
              name=""
              id=""
              onChange={changeYear}
            >
              {yearOptions.map(({ name, value }, index) => (
                <option value={value} key={index} defaultChecked={index === 0}>
                  {name}
                </option>
              ))}
            </select>
            <select
              onChange={changeMonth}
              className="w-36 text-text mr-4 p-2 border rounded-lg border-[#D5D6D7] outline-[#D5D6D7]"
              name=""
              id=""
            >
              <option className="" value="All" defaultChecked>
                All Months
              </option>
              {monthsDropDown.map((month, index) => (
                <option value={month} key={index}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Home;

const monthsDropDown = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
