import React, { useEffect } from "react";
import { Checkbox, createStyles, TextInput } from "@mantine/core";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { ISiteData } from "types";

interface IProps {
  sites: Array<ISiteData>;
  paymentSchedule: string;
  onSelect: (args: any) => void;
}

const useStyles = createStyles(() => ({
  inputIcon: {
    marginLeft: "3px",
  },
  input: {
    paddingLeft: "2.2rem !important",
  },
}));

const AgencySites = ({ sites, paymentSchedule, onSelect }: IProps) => {
  const [value, setValue] = useState("");
  const [store, setStore] = useState(sites);
  const [siteIDs, setSiteIDs] = useState<string[]>([]);
  const { classes } = useStyles();

  useEffect(() => {
    setStore(sites);
  }, [sites]);

  const handleSelect = (curr: string) => {
    const position = siteIDs.indexOf(curr);
    if (position === -1) {
      //   onSelect([...siteIDs, curr]);
      setSiteIDs([...siteIDs, curr]);
    } else {
      let data = [...siteIDs];
      data.splice(position, 1);
      // onSelect(data);
      setSiteIDs(data);
    }
  };

  const handleSelectAll = () => {
    const allSiteIDs = store.map((item) => item.siteData.siteID.toString());
    setSiteIDs(allSiteIDs);
  };

  const renderData = () =>
    store.map((item) => (
      <div
        key={item.siteData.siteID}
        className="my-2 cursor-pointer flex items-center gap-2"
      >
        <Checkbox
          checked={siteIDs.includes(item?.siteData.siteID.toString())}
          onChange={() => handleSelect(item?.siteData.siteID.toString())}
        />
        <span
          style={{ backgroundColor: item.siteData.colorTag }}
          className={`inline-block h-[8px] w-[8px] mr-1 rounded-[50%]`}
        ></span>
        <span className="text-[15px] capitalize">{item.siteData.siteName}</span>
      </div>
    ));

  const handleSearch = (search) => {
    setValue(search);
    const searchBySiteName = sites.filter(({ siteData: { siteName } }) => {
      return search
        ? RegExp(`^${search}`, "ig").test(siteName.trim().toLowerCase())
        : true;
    });
    const result = searchBySiteName.length ? searchBySiteName : sites;
    setStore(result);
  };

  return (
    <div>
      <h3 className="mb-2 text-base font-medium">{paymentSchedule}</h3>
      <div className="flex gap-3 items-center ">
        <TextInput
          className="flex-grow"
          icon={<AiOutlineSearch size={18} />}
          placeholder="Search site"
          classNames={{
            input: classes.input,
            icon: classes.inputIcon,
          }}
          value={value}
          onChange={(evt) => handleSearch(evt.target.value)}
        />
        <p
          className="text-xs font-medium text-primary cursor-pointer mb-5"
          onClick={handleSelectAll}
        >
          Select all
        </p>
      </div>
      <div className="h-[300px] m-0 mb-5 overflow-auto">{renderData()}</div>
      <button
        //   onClick={() => displayModal("site")}
        className="px-0 w-full block mt-auto text-sm"
      >
        Download Payment Schedule
      </button>
    </div>
  );
};

export default React.memo(AgencySites);
// export default AgencySites;
