import { Navigate } from "react-router-dom";
import Login from "pages/auth/Login";
import RecoverPassword from "pages/auth/RecoverPassword";
import ResetPassword from "pages/auth/ResetPassword";
import SignUp from "pages/auth/SignUp";
// import Welcome from "pages/auth/Welcome";
import Home from "pages/home";
import { AppRoute } from "./types";
import Staff from "pages/staff";
import Shift from "pages/shift";
import Financials from "pages/financials";
import Messages from "pages/messages";
import Settings from "pages/settings";
import EmailConfirmation from "pages/auth/EmailConfirmation";
import NotFound from "pages/auth/NotFound";
import ValidateShift from "pages/shift/ValidateShift";
import InviteAgency from "pages/auth/InviteAgency";
import OnboardingConfirmation from "pages/auth/OnboardingConfirmation";
import PaymentSuccess from "pages/financials/PaymentSuccess";
import Validations from "pages/validations";

/**
 * Paths available to users who are not logged in
 * @constant
 */
export const PUBLIC_PATHS = {
  LOGIN: "/login",
  REGISTER: "/register",
  CONFIRMATION: "/agency-confirmed",
  RECOVER_PASSWORD: "/recover-password",
  RESET_PASSWORD: "/reset-password",
  VALIDATE_SHIFT: "/validate-shift",
  INVITE_AGENCY: "/invite",
  ONBOARDED: "/onboarded",
  PAYMENT_SUCCESS: "/payment/success",
};

/**
 * Paths available to users who are logged in
 * @constant
 */
export const PROTECTED_PATHS = {
  HOME: "/",
  STAFF: "/staff",
  SHIFTS: "/shifts",
  VALIDATIONS: "/validations",
  FINANCIALS: "/financials",
  MESSAGES: "/messages",
  SETTINGS: "/settings",
  HARD_RESET: "/reset-password",
  VALIDATE_SHIFT: "/validate-shift",
  PAYMENT_SUCCESS: "/payment/success",
};

const {
  LOGIN,
  REGISTER,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  CONFIRMATION,
  VALIDATE_SHIFT,
  INVITE_AGENCY,
  ONBOARDED,
  PAYMENT_SUCCESS,
} = PUBLIC_PATHS;
export const PUBLIC_ROUTES: AppRoute[] = [
  { path: LOGIN, element: <Login /> },
  { path: REGISTER, element: <SignUp /> },
  { path: RECOVER_PASSWORD, element: <RecoverPassword /> },
  { path: RESET_PASSWORD, element: <ResetPassword /> },
  { path: CONFIRMATION, element: <EmailConfirmation /> },
  { path: VALIDATE_SHIFT, element: <ValidateShift /> },
  { path: INVITE_AGENCY, element: <InviteAgency /> },
  { path: ONBOARDED, element: <OnboardingConfirmation /> },
  { path: PAYMENT_SUCCESS, element: <PaymentSuccess /> },
  { path: "/", element: <Login /> },
  ...Object.values(PROTECTED_PATHS).map((route) => {
    return {
      path: route,
      element: <Navigate to="/" />,
    };
  }), // this enables you not to access the public routes when logged in
  {
    path: "*",
    element: <NotFound />,
  },
];

const {
  HOME,
  STAFF,
  SHIFTS,
  VALIDATIONS,
  FINANCIALS,
  MESSAGES,
  SETTINGS,
  HARD_RESET,
  VALIDATE_SHIFT: VALIDATE,
  PAYMENT_SUCCESS: PAYMENT,
} = PROTECTED_PATHS;
export const PROTECTED_ROUTES: AppRoute[] = [
  // { path: HOME, element: <Welcome /> },
  { path: HARD_RESET, element: <ResetPassword /> },
  { path: HOME, element: <Home /> },
  { path: STAFF, element: <Staff /> },
  { path: SHIFTS, element: <Shift /> },
  { path: VALIDATIONS, element: <Validations /> },
  { path: FINANCIALS, element: <Financials /> },
  { path: MESSAGES, element: <Messages /> },
  { path: SETTINGS, element: <Settings /> },
  { path: VALIDATE, element: <ValidateShift /> },
  { path: PAYMENT, element: <PaymentSuccess /> },
  ...Object.values(PUBLIC_PATHS).map((route) => {
    return {
      path: route,
      element: <Navigate to="/" />,
    };
  }), // this enables you not to access the public routes when logged in
  {
    path: "*",
    element: <NotFound />,
  },
];
