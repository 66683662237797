import { forwardRef, useMemo, useState } from "react";
import { BackIcon } from "components/Svgs";
import { HideModalProp } from "types";
import {
  Loader,
  Select,
  TextInput,
  Text,
  Group,
  Center,
  Button,
} from "@mantine/core";
import { agencyRequest } from "services/request";
import { getRequest } from "utils/apiCalls";
import useSendToAPI from "hooks/useSendToAPI";

interface IRoles {
  roleID: number;
  createdAt: string;
  updatedAt: string;
  agencyID: string;
  roleName: string;
  deleted: boolean;
}
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  roleID: number;
  createdAt: string;
  updatedAt: string;
  agencyID: string;
  roleName: string;
  deleted: boolean;
}

interface IProps extends HideModalProp {
  agencyId?: string | number;
  roles?: Array<IRoles>;
  isLoading?: boolean;
}

const AddStaffModal = ({
  hideModal,
  agencyId,
  isLoading,
  roles = [],
}: IProps) => {
  const [showInvite, setShowInvite] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [rate, setRate] = useState("");

  const onSuccess = (res) => {
    setShowInvite(true);
  };
  const { mutate, isLoading: loading } = useSendToAPI(getRequest, onSuccess);

  const isEmailValid = (value) => /^\S+@\S+$/.test(value);
  const handleSubmit = () => {
    if (isEmailValid(email)) {
      mutate({
        url: agencyRequest.INVITE_STAFF({
          email,
          agencyID: agencyId,
          roleID: role,
          rate,
        }),
      });
    } else {
      setError("Invalid Email");
    }
  };

  const formatedRoles = useMemo(() => {
    return roles.map(({ roleID, roleName }: IRoles) => ({
      roleName,
      label: roleName,
      value: roleID,
    }));
  }, [roles]);

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ roleName, roleID, ...others }: ItemProps, ref) => (
      <div ref={ref} key={roleID} {...others}>
        <Group noWrap>
          <div className="w-full flex justify-between items-center">
            <Text size="sm">{roleName}</Text>
          </div>
        </Group>
      </div>
    )
  );
  const Loadertem = forwardRef<HTMLDivElement, ItemProps>(
    ({ roleID, ...others }: ItemProps, ref) => (
      <div ref={ref} key={roleID} {...others}>
        <Center>
          <Loader size="sm" color="#aaa" />
        </Center>
      </div>
    )
  );
  return (
    <div className="fixed-modal max-w-[95%] items-center h-fit w-fit ">
      {!showInvite && (
        <div className="bg-white max-w-full w-[450px] relative px-9 sm:px-12 rounded-lg pt-9 pb-12 z-20">
          <div
            className="absolute left-4 top-[43px] cursor-pointer"
            onClick={hideModal}
          >
            <BackIcon />
          </div>
          <div className="w-full">
            <TextInput
              size="lg"
              type="email"
              label="Email"
              placeholder="name@example.com"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
              error={error}
              classNames={{ error: "-mt-3 mb-4" }}
            />
            <label className="label">Role</label>
            <Select
              value={role}
              placeholder="Select role"
              data={
                isLoading
                  ? Array(1).fill({ label: "", value: "" })
                  : formatedRoles
              }
              itemComponent={isLoading ? Loadertem : SelectItem}
              onChange={(value: string) => setRole(value)}
            />
            <label className="label">Rate/hr</label>
            <TextInput
              size="lg"
              type="number"
              placeholder="Numbers only"
              value={rate}
              onChange={(evt) => setRate(evt.target.value)}
            />
            <div>
              <Button
                className="!m-0 !p-0"
                loading={loading}
                disabled={email.length === 0 || role.length === 0}
                onClick={handleSubmit}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      )}
      {showInvite && (
        <div className="bg-white max-w-[95%] py-5 px-12 rounded-md text-center">
          <p className="mb-3">Invite sent</p>
          <p
            className="rounded-md bg-primary text-white px-10 py-1 cursor-pointer"
            onClick={hideModal}
          >
            Ok
          </p>
        </div>
      )}
    </div>
  );
};

export default AddStaffModal;
