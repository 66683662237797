import { Fragment } from "react";
import { CancelIcon } from "components/Svgs";
import CustomTable from "components/CustomTable";
import { shiftColumn } from "./model";

const MultipleShiftModal = ({ hideModal, data, roles, onClick }) => {
  const getUserRole = (roleId: string) => {
    const userRole = roles.find(
      (role) => role.roleID.toString() === roleId.toString()
    );
    return userRole?.roleName ?? roleId;
  };

  const column = [
    ...shiftColumn,
    {
      accessorFn: (row) => getUserRole(row?.roleID) ?? "N/A",
      id: "role",
      header: "Role",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorFn: (row) => {
        return (
          <span
            className=" text-primary cursor-pointer text-[14px] capitalize"
            onClick={() => {
              onClick({
                ...data,
                shiftData: row,
              });
            }}
          >
            View
          </span>
        );
      },
      id: "action",
      header: "",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableSorting: false,
    },
  ];

  return (
    <Fragment>
      <div
        className="w-screen h-screen fixed top-0 left-0 bg-text opacity-40 z-10"
        onClick={hideModal}
      ></div>
      <div
        className={
          "fixed-modal w-[60vw] max-w-[95vw] h-fit px-10 overflow-auto py-6 relative"
        }
      >
        <div
          className="absolute top-4 cursor-pointer right-4"
          onClick={hideModal}
        >
          <CancelIcon />
        </div>
        <h1 className="mb-3">Multiple Rotas</h1>
        <div className="w-full">
          <CustomTable
            height="100%"
            data={data?.shiftData ?? []}
            columns={column}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MultipleShiftModal;
