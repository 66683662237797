import { Avatar } from "@mantine/core";

export const profileColumn = [
  {
    id: "shiftDate",
    header: "Date",
    accessorFn: (row) => row?.shiftDate ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableSorting: false,
  },
  {
    id: "time",
    header: "Time",
    accessorFn: (row) => {
      const period = `${row?.startTime} - ${row?.endTime}`;
      return period.trim().toString().length > 0 ? period : "N/A";
    },
    cell: (info) => <p className="text-[14px]">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    id: "site",
    header: "Site",
    accessorFn: (row) => row?.site?.siteName ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "shiftRate",
    header: "Rate",
    accessorFn: (row) => row?.shiftRate ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
];

export const tableColumn = [
  {
    id: "id",
    header: "ID",
    accessorFn: (row) => row?.user?.userID ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => {
      const name = `${row?.user?.userFirstname} ${row?.user?.userLastname}`;
      return name ?? "N/A";
    },
    id: "name",
    header: "Name",
    cell: (info) => {
      return (
        <div className="flex items-center w-fit">
          <Avatar
            src={info?.row?.original?.pictureLink}
            alt="user"
            radius="xl"
          />
          <p className="text-[14px] ml-3 w-fit capitalize">{info.getValue()}</p>
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.user?.userEmail ?? "N/A",
    id: "userEmail",
    header: "Email",
    cell: (info) => <p className="text-[14px] w-fit ">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.user?.userPhone ?? "N/A",
    id: "userPhone",
    header: "Phone No",
    cell: (info) => <p className="text-[14px] w-fit ">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    id: "createdAt",
    header: "Date Joined",
    accessorFn: (row) => {
      const date = row?.user?.createdAt
        ? new Date(row?.user?.createdAt).toLocaleDateString()
        : "N/A";
      return date;
    },
    cell: (info) => <p className="text-[14px]">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
];

export const inviteColumn = [
  {
    id: "userFirstname",
    header: "First Name",
    accessorFn: (row) => row?.userFirstname ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "userLastname",
    header: "Last Name",
    accessorFn: (row) => row?.userLastname ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "userEmail",
    header: "Email",
    accessorFn: (row) => row?.userEmail ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "userPhone",
    header: "Phone Number",
    accessorFn: (row) => row?.userPhone ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "userGender",
    header: "Gender",
    accessorFn: (row) => row?.userGender ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "dob",
    header: "DOB (DD/MM/YYYY)",
    accessorFn: (row) => {
      const dobYear = row?.dobYear ?? "";
      const dobMonth = row?.dobMonth ?? "";
      const dobDay = row?.dobDay ?? "";
      return `${dobDay}/${dobMonth}/${dobYear}`;
    },
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "userAddress",
    header: "Address",
    accessorFn: (row) => row?.userAddress ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "userNationality",
    header: "Nationality",
    accessorFn: (row) => row?.userNationality ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
];
