import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";
import { fetchifyRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";

const useFetchPostcode = (postcode = "") => {
  const debouncedPostcode = useDebounce(postcode, 200);
  const { isLoading, data } = useQuery(
    [queryKeys.listOfAddress, debouncedPostcode],
    async () => fetchifyRequest({ postcode: debouncedPostcode[0] }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(debouncedPostcode[0]),
    }
  );

  return { isLoading, data };
};

export default useFetchPostcode;
