import { Switch } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ConfirmationModal } from "components/ConfirmationModal";
import useSendToAPI from "hooks/useSendToAPI";
import { successToast } from "hooks/useToast";
import { useState } from "react";
import { agencyRequest } from "services/request";
import { IUser } from "types";
import { getRequest, postRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import ChangePassword from "./ChangePassword";

interface IProps {
  user: IUser;
}
const SecuritySettings = ({ user }: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [activityLogs, setActivityLogs] = useState({
    saveLog: false,
  });

  const { data, refetch } = useQuery(
    [queryKeys.activityLogConfig],
    async () =>
      getRequest({
        url: agencyRequest.GET_ACTIVITY_LOG(user.agency.agencyID),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setActivityLogs({
          saveLog: data?.saveLog ?? false,
        });
      },
    }
  );

  const handleChange = (value: boolean) => {
    setActivityLogs({
      ...activityLogs,
      saveLog: value,
    });
    setShowConfirmation(true);
  };

  const onSuccess = (res) => {
    refetch();
    successToast(`Activity log settings updated successfully`);
    setShowConfirmation(false);
  };
  const { mutate, isLoading } = useSendToAPI(postRequest, onSuccess);

  const yesHandler = () => {
    const payload = {
      agencyID: user.agency.agencyID,
      saveLogs: activityLogs.saveLog,
    };
    mutate({
      url: agencyRequest.UPDATE_ACTIVITY_LOG,
      data: payload,
    });
  };
  const noHandler = () => {
    setShowConfirmation(false);
    setActivityLogs({
      saveLog: data?.saveLog ?? false,
    });
  };
  return (
    <div>
      {showModal && (
        <>
          <div
            className="modal-background"
            onClick={() => {
              setShowModal(false);
            }}
          ></div>
          <ChangePassword hideModals={() => setShowModal(false)} user={user} />
        </>
      )}
      {showConfirmation && (
        <ConfirmationModal
          title={`Are you sure you want to ${
            activityLogs.saveLog ? "enable" : "disable"
          } your activity logs?`}
          yesHandler={yesHandler}
          noHandler={noHandler}
          loading={isLoading}
        />
      )}

      <div className="flex px-8 py-4 border-b">
        <div className="sm:w-1/2">
          <p className="text-2xl hidden sm:block text-bold font-semibold mb-4">
            Security Settings
          </p>
          <p>These settings help you keep your account secure</p>
        </div>
      </div>
      <div className="flex px-8 py-4 border-b justify-between items-end">
        <div className="sm:w-1/2">
          <p className="text-lg font-semibold mb-1">Save My Activity Logs</p>
          <p className="text-sm">
            You can save your all activity logs including unusual activity
            detected
          </p>
        </div>
        <Switch
          onLabel="ON"
          offLabel="OFF"
          checked={activityLogs.saveLog}
          onChange={(evt) => handleChange(evt.currentTarget.checked)}
        />
      </div>
      <div className="flex flex-col sm:flex-row justify-between px-8 py-4">
        <div className="mb-4 sm:w-1/2">
          <p className="text-lg font-semibold mb-1">Change Password</p>
          <p className="text-sm">
            Set a unique password to protect your account
          </p>
        </div>
        <span
          className="w-fit py-2 px-14 mt-auto rounded-xl h-fit border-2 border-primary text-primary font-semibold cursor-pointer"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Change Password
        </span>
      </div>
    </div>
  );
};

export default SecuritySettings;
