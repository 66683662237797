import { Link } from "react-router-dom";
import Logo from "images/logo.png";

const EmailConfirmation = () => {
  return (
    <div className="h-screen bg-slate-200">
      <div className="w-1/2 mx-auto pt-28 flex flex-col justify-center items-center">
        <img src={Logo} alt="zshift logo" className="logo" />
        <h1 className="font-medium text-lg my-5">Email Confirmed</h1>
        <div className="bg-white rounded my-2 p-4 w-1/2 min-w-[360px]">
          <p className="text-center leading-6">
            The email address attached to your zshift account has been
            confirmed.
          </p>
          <Link to="/login">
            <p className="bg-primary text-white text-center p-2 mt-3 rounded">
              Continue
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
