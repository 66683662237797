import { useState } from "react";
import DashboardLayout from "components/DashboardLayout";
import CreateShift from "./CreateShift";
import ShiftIndex from "./ShiftIndex";
import TimeSheet from "./TimeSheet";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";
import { getRequest } from "utils/apiCalls";
import { siteRequest } from "services/request";
import { IUser } from "types";
import GotoSettingsModal from "components/GoToSettingsModal";

const Shift = () => {
  const [modals, setModals] = useState({
    site: false,
    active: false,
    reports: false,
    shift: false,
    editSite: false,
    shiftActions: false,
  });
  const [view, setView] = useState("shifts");
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;

  const { data = [] } = useQuery(
    [queryKeys.allAgencySites],
    async () =>
      getRequest({ url: siteRequest.GET_AGENCY_SITES(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  function displayModal(name: string) {
    setModals((prev) => ({
      ...prev,
      active: true,
      [name]: true,
    }));
  }
  function hideModals() {
    Object.keys(modals).forEach((key) => {
      setModals((prev) => ({
        ...prev,
        [key]: false,
      }));
    });
  }
  return (
    <>
      <GotoSettingsModal visible={user.rulesSet} />
      <DashboardLayout>
        <>
          {view === "shifts" && (
            <ShiftIndex
              setView={setView}
              modals={modals}
              hideModals={hideModals}
              displayModal={displayModal}
              sites={data}
            />
          )}
          {view === "create-shift" && (
            <CreateShift
              user={user}
              sites={data}
              setView={() => setView("shifts")}
            />
          )}
          {view === "timesheet" && (
            <TimeSheet
              sites={data}
              agencyID={user.agency.agencyID}
              setView={() => setView("shifts")}
            />
          )}
        </>
      </DashboardLayout>
    </>
  );
};

export default Shift;
