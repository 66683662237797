import { Avatar } from "@mantine/core";
import { formatDistanceToNow, isAfter } from "date-fns";

export const SiteChat = ({ item, active, setActive, handleUnreadCount }) => {
  const isActive = item?.siteData?.siteID === active?.siteData?.siteID;
  const count = handleUnreadCount(item?.siteData?.siteID);

  return (
    <div
      className={`${
        isActive ? "bg-slate-200" : "bg-white"
      } flex items-center p-4 hover:bg-gray-100 cursor-pointer`}
      onClick={() => setActive(item)}
    >
      <Avatar size={50} color="#8353F7" radius={100}>
        {item?.siteData?.siteName.toUpperCase().substring(0, 1)}
      </Avatar>
      <div className="w-full ml-2">
        <p className="font-semibold text-sm mb-2 mt-2 capitalize">
          {item.siteData.siteName}
        </p>
        <div className="flex justify-between"></div>
      </div>
      {/* {isActive && count > 0 && <Avatar color="red">{count}</Avatar>} */}
      {count > 0 && <Avatar color="red">{count}</Avatar>}
    </div>
  );
};

export const SingleChat = ({ item, active, setActive, handleUnreadCount }) => {
  const isActive = item?.user?.userID === active?.user?.userID;
  const count = handleUnreadCount(item?.siteData?.siteID);

  return (
    <div
      className={`${
        isActive ? "bg-slate-200" : "bg-white"
      } flex items-center p-4 hover:bg-gray-100 cursor-pointer`}
      onClick={() => setActive(item)}
    >
      <Avatar src={item?.pictureLink} size={50} alt="user" radius={100} />

      <div className="w-full ml-2">
        <p className="font-semibold text-sm mb-2 mt-2 capitalize">
          {item?.user?.userFirstname} {item?.user?.userLastname}
        </p>
        <div className="flex justify-between"></div>
      </div>
      {/* {isActive && count > 0 && <Avatar color="red">{count}</Avatar>} */}
      {count > 0 && <Avatar color="red">{count}</Avatar>}
    </div>
  );
};

type MessageProp = {
  isSender: boolean;
  name: string;
  message: string;
  time: string;
  senderID: number;
  messageText: string;
  createdAt: Date;
  latRead: Date;
};
const MessageBox = ({ messages, users, currUserID, lastReadTimestamp }) => {
  // const timestamp = new Date("2022-12-08T10:43:05.000+00:00");
  let showTimestamp = false;
  let count = 0;

  return (
    <>
      {messages.map((message: MessageProp, index: number) => {
        let showName = true;
        const sender =
          users.find(
            (item) =>
              item?.user?.userID?.toString() === message.senderID.toString()
          ) ?? {};
        const isSender = message.senderID.toString() === currUserID.toString();
        if (index > 0) showName = isSender;
        const timestamp = lastReadTimestamp || message.latRead;
        const isUread = isAfter(
          new Date(message.createdAt),
          new Date(timestamp)
        );
        if (isUread) {
          showTimestamp = true;
          count++;
        }

        let hasPreMargin = false,
          hasPostMargin = false;

        if (isSender) {
          hasPreMargin =
            messages[index - 1]?.senderID?.toString() !== currUserID.toString();
          hasPostMargin =
            messages[index + 1]?.senderID?.toString() !== currUserID.toString();
        }

        return (
          <div
            key={index}
            className={`${hasPreMargin ? "mt-4" : ""} ${
              hasPostMargin ? "mb-4" : ""
            }`}
          >
            {!isSender && showTimestamp && count > 0 && (
              <p className="text-center font-semibold text-sm italic">{`~~~~~ ${count} Unread Messages ~~~~~`}</p>
            )}
            <div
              // className={`p-4 rounded-lg bg-[#F0F0FF] mt-1 max-w-[80%] sm:max-w-[60%]
              className={`px-4 py-3 rounded-lg bg-[#F0F0FF] mt-1 max-w-[80%] sm:max-w-[60%] 
             ${showName && "mt-4"} 
             ${isSender && "ml-auto  bg-[#FFF7DA]"}`}
              //  ${showName && "mt-4 pt-0"}
            >
              {!isSender && (
                <p className="font-semibold capitalize">
                  {sender?.user?.userFirstname} {sender?.user?.userLastname}
                </p>
              )}
              <p>{message.messageText}</p>
              <p className="text-[14px] italic text-right">
                {formatDistanceToNow(new Date(message?.createdAt))}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MessageBox;
