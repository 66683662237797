import { useQuery } from "@tanstack/react-query";
import CustomTable from "components/CustomTable";
import { agencyRequest } from "services/request";
import { IRole } from "types";
import { getRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import { usersColumn } from "./model";

type AddSubUserProps = {
  displayModal: (data?: Record<string, string>) => void;
  agencyID: string | number;
  roles: Array<IRole>;
  isLoadingRoles: boolean;
  permissions: Record<string, boolean>;
};
const AddSubUserModal = ({
  displayModal,
  agencyID,
  roles,
  isLoadingRoles,
  permissions,
}: AddSubUserProps) => {
  const { isLoading, data = [] } = useQuery(
    [queryKeys.agencySubUser],
    async () =>
      getRequest({ url: agencyRequest.GET_AGENCY_SUB_USERS(agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const column = [
    ...usersColumn,
    {
      id: "role",
      header: "Role",
      accessorFn: (row) => {
        const role = roles.find(
          (role) => role.roleID.toString() === row?.staffRoleID.toString()
        );
        return role ? role?.roleName : row?.staffRoleID;
      },
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorFn: (row) => {
        return (
          <span
            className=" text-primary cursor-pointer text-sm capitalize"
            onClick={() => {
              displayModal(row);
              // setModalData(row);
              // setShowModal(true);
            }}
          >
            Edit
          </span>
        );
      },
      id: "action",
      header: "",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableSorting: false,
    },
  ];

  return (
    <div className="h-full flex flex-col max-w-full sm:px-6 pt-6">
      {(permissions.all || permissions.createUser) && (
        <button
          onClick={() => displayModal()}
          className="w-fit ml-8 sm:ml-0 btn px-12 py-2 mb-6"
        >
          Add sub user
        </button>
      )}
      <div>
        <CustomTable
          height="fit-content"
          loading={isLoading || isLoadingRoles}
          data={data}
          columns={column}
        />
      </div>
    </div>
  );
};

export default AddSubUserModal;
