export const usersColumn = [
  {
    id: "date",
    header: "Date Joined",
    accessorFn: (row) =>
      row?.createdAt ? new Date(row.createdAt).toLocaleDateString() : "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableSorting: false,
  },
  {
    id: "name",
    header: "Name",
    accessorFn: (row) => row?.staffName ?? "N/A",
    cell: (info) => <p className="text-[14px] capitalize">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  // {
  //   id: "phoneNo",
  //   header: "Phone No",
  //   accessorFn: (row) => row?.staffPhone ?? "N/A",
  //   cell: (info) => info.getValue(),
  //   footer: (props) => props.column.id,
  // },
  {
    id: "email",
    header: "Email",
    accessorFn: (row) => row?.staffEmail ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
];

export const rolesColumn = [
  {
    id: "date",
    header: "Date Joined",
    accessorFn: (row) =>
      row?.createdAt ? new Date(row.createdAt).toLocaleDateString() : "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableSorting: false,
  },
  {
    id: "roleID",
    header: "Role ID",
    accessorFn: (row) => row?.roleID ?? "N/A",
    // cell: (info) => <p className="text-[14px] capitalize">{info.getValue()}</p>,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "roleName",
    header: "Role Name",
    accessorFn: (row) => row?.roleName ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
];
