import { useState } from "react";
import { BackIcon } from "components/Svgs";
import { HideModalProp } from "types";
import { TextInput, Button, Divider } from "@mantine/core";
import { agencyRequest } from "services/request";
import { getRequest, postRequest } from "utils/apiCalls";
import useSendToAPI from "hooks/useSendToAPI";
import { errorToast } from "hooks/useToast";

interface IRoles {
  roleID: number;
  createdAt: string;
  updatedAt: string;
  agencyID: string;
  roleName: string;
  deleted: boolean;
}

interface IProps extends HideModalProp {
  agencyId?: string | number;
  roles?: Array<IRoles>;
  isLoading?: boolean;
}

const DirectOnboardModal = ({ hideModal, agencyId }: IProps) => {
  const [userDetails, setUserDetails] = useState<Record<string, string> | null>(
    null
  );
  const [showInvite, setShowInvite] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [rate, setRate] = useState<Record<string, string>>({});

  const onSuccess = (res) => {
    if (res.userID) {
      setUserDetails(res);
    } else {
      errorToast("User not found");
    }
  };
  const onBoardSuccess = () => {
    setShowInvite(true);
  };

  const { mutate: onboardUser, isLoading: loadingOnboarding } = useSendToAPI(
    postRequest,
    onBoardSuccess
  );

  const { mutate, isLoading: loading } = useSendToAPI(getRequest, onSuccess);

  const isEmailValid = (value) => /^\S+@\S+$/.test(value);
  const handleSubmit = () => {
    onboardUser({
      url: agencyRequest.DIRECT_ONBOARD_STAFF,
      data: {
        userID: userDetails?.userID,
        agencyID: agencyId,
        dailyRate: rate?.dailyRate || "0",
        bankHolidayRate: rate?.bankHolidayRate || "0",
        nightlyRate: rate?.nightlyRate || "0",
        weekendDailyRate: rate?.weekendDailyRate || "0",
        weekendNightlyRate: rate?.weekendNightlyRate || "0",
      },
    });
  };

  const handleSearch = () => {
    if (isEmailValid(email)) {
      mutate({ url: agencyRequest.SEARCH_STAFF(email) });
      setError("");
    } else {
      setError("Invalid Email");
    }
  };

  return (
    <div className="fixed-modal max-w-[95%] items-center w-fit h-fit max-h-[90vh] overflow-auto relative ">
      {!showInvite && (
        <div className="bg-white max-w-full w-[550px] relative px-9 sm:px-12 rounded-lg pt-9 pb-12 z-20">
          <div
            className="absolute left-4 top-[43px] cursor-pointer"
            onClick={hideModal}
          >
            <BackIcon />
          </div>
          <div className="w-full">
            {!userDetails && (
              <TextInput
                size="lg"
                type="email"
                label="Email"
                placeholder="name@example.com"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
                error={error}
                classNames={{ error: "-mt-3 mb-4" }}
              />
            )}

            {userDetails && (
              <div className="mb-4">
                <div className="flex justify-between">
                  <p>Name:</p>{" "}
                  <p className="capitalize">
                    {userDetails?.userFirstname} {userDetails?.userLastname}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Address:</p>
                  <p className="w-[70%] capitalize text-right">
                    {userDetails?.userAddress}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Phone Number:</p>
                  <p className="capitalize text-right">
                    {userDetails?.userPhone}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Email:</p>
                  <p className="">{userDetails?.userEmail}</p>
                </div>
                <Divider className="my-3" />
                <div>
                  <TextInput
                    classNames={{ input: "!mb-4" }}
                    label="Daily Rate"
                    placeholder="Enter rate"
                    type="number"
                    value={rate?.dailyRate || ""}
                    onChange={(evt) =>
                      setRate({ ...rate, dailyRate: evt.target.value })
                    }
                  />
                  <TextInput
                    classNames={{ input: "!mb-4" }}
                    label="Nightly Rate"
                    placeholder="Enter rate"
                    type="number"
                    value={rate?.nightlyRate || ""}
                    onChange={(evt) =>
                      setRate({ ...rate, nightlyRate: evt.target.value })
                    }
                  />
                  <TextInput
                    classNames={{ input: "!mb-4" }}
                    label="Weekend Daily Rate"
                    placeholder="Enter rate"
                    type="number"
                    value={rate?.weekendDailyRate || ""}
                    onChange={(evt) =>
                      setRate({ ...rate, weekendDailyRate: evt.target.value })
                    }
                  />
                  <TextInput
                    classNames={{ input: "!mb-4" }}
                    label="Weekend Nightly Rate"
                    placeholder="Enter rate"
                    type="number"
                    value={rate?.weekendNightlyRate || ""}
                    onChange={(evt) =>
                      setRate({ ...rate, weekendNightlyRate: evt.target.value })
                    }
                  />
                  <TextInput
                    classNames={{ input: "!mb-4" }}
                    label="Bank Holiday Rate"
                    placeholder="Enter rate"
                    type="number"
                    value={rate?.bankHolidayRate || ""}
                    onChange={(evt) =>
                      setRate({ ...rate, bankHolidayRate: evt.target.value })
                    }
                  />
                </div>
              </div>
            )}

            <div>
              {userDetails ? (
                <div className=" flex flex-row gap-3">
                  <Button
                    className="!bg-white !border !border-primary  !px-6 !py-2 !rounded-md"
                    classNames={{ label: "!text-primary" }}
                    onClick={() => {
                      setUserDetails(null);
                      setEmail("");
                    }}
                  >
                    Clear Search
                  </Button>
                  <Button
                    className="!bg-primary !border !border-primary !px-6 !py-2 !rounded-md "
                    loading={loadingOnboarding}
                    disabled={
                      userDetails === null || Object.keys(rate).length !== 5
                    }
                    onClick={handleSubmit}
                  >
                    Onboard User
                  </Button>
                </div>
              ) : (
                <Button
                  className="!m-0 !p-0"
                  loading={loading}
                  disabled={email.length === 0}
                  onClick={handleSearch}
                >
                  Search User
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {showInvite && (
        <div className="bg-white max-w-[95%] py-5 px-12 rounded-md text-center">
          <p className="mb-3 whitespace-nowrap">User Onboarded</p>
          <p
            className="rounded-md bg-primary text-white px-10 py-1 cursor-pointer"
            onClick={hideModal}
          >
            Ok
          </p>
        </div>
      )}
    </div>
  );
};

export default DirectOnboardModal;
