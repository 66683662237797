import { Loader } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { BackIcon, DownloadIcon, EyeIcon, MailIcon } from "components/Svgs";
import {
  addDays,
  endOfWeek,
  format,
  getWeek,
  startOfWeek,
  subDays,
} from "date-fns";
import useSendToAPI from "hooks/useSendToAPI";
import { useRef, useState } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { siteRequest } from "services/request";
import { fetchPDFStream, getRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import TimeSheetModal from "./TimeSheetModal";

type TimeSheetProp = {
  sites: Array<Record<string, any>>;
  agencyID: string | number;
  setView: () => void;
};

const options: any = {
  weekStartsOn: 1,
};
const TimeSheet = ({ sites, agencyID, setView }: TimeSheetProp) => {
  const [modal, setModal] = useState(false);
  const [currSite, setCurrSite] = useState(sites[0]);
  const [start, setStart] = useState(startOfWeek(new Date(), options));
  const [end, setEnd] = useState(endOfWeek(new Date(), options));
  const shiftRange = useRef<{ start: Date; end: Date } | undefined>();
  const selectedUser = useRef<string | null>(null);
  const [modalData, setModalData] = useState({});

  const { isLoading, data: records = [] } = useQuery(
    [queryKeys.weeklyTimesheet, agencyID, currSite.siteData.siteID, start, end],
    async () => {
      const startDate = shiftRange.current?.start
        ? format(shiftRange.current?.start, "yyyy-MM-dd")
        : format(start, "yyyy-MM-dd");
      const endDate = shiftRange.current?.end
        ? format(shiftRange.current?.end, "yyyy-MM-dd")
        : format(end, "yyyy-MM-dd");
      return getRequest({
        url: siteRequest.GET_WEEKLY_TIMESHEET({
          agencyId: agencyID,
          siteId: currSite.siteData.siteID,
          startDate,
          endDate,
        }),
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const getDateRange = () => {
    const startDate = shiftRange.current?.start
      ? format(shiftRange.current?.start, "yyyy-MM-dd")
      : format(start, "yyyy-MM-dd");
    const endDate = shiftRange.current?.end
      ? format(shiftRange.current?.end, "yyyy-MM-dd")
      : format(end, "yyyy-MM-dd");
    return { startDate, endDate };
  };

  const onSuccess = (blob) => {
    const { startDate, endDate } = getDateRange();
    const fileURL = window.URL.createObjectURL(blob);
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = `timesheet-${startDate}_${endDate}_${selectedUser.current}.pdf`;
    alink.click();
  };

  const { mutate, isLoading: isDownload } = useSendToAPI(
    fetchPDFStream,
    onSuccess
  );

  const handleDownload = (userID) => {
    const { startDate, endDate } = getDateRange();
    mutate({
      url: siteRequest.DOWNLOAD_WEEKLY_TIMESHEET({
        agencyId: agencyID,
        siteId: currSite.siteData.siteID,
        startDate,
        endDate,
        userID: userID,
      }),
    });
  };

  const handleChange = (args: "inc" | "dec") => {
    if (args === "inc") {
      const newStart = addDays(end, 1);
      const newEnd = addDays(end, 7);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      // refetch();
    }
    if (args === "dec") {
      const newStart = subDays(start, 7);
      const newEnd = subDays(start, 1);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      // refetch();
    }
  };

  return (
    <div className="">
      {isLoading && (
        <>
          <div className="w-screen h-screen fixed top-0 left-0 bg-text opacity-20 z-10"></div>
          <div className="fixed left-[50%] flex justify-center mt-5 z-50">
            <Loader size="lg" color="blue" />
          </div>
        </>
      )}
      <div className="flex items-center">
        <div className="flex items-center">
          <div className="cursor-pointer" onClick={setView}>
            <BackIcon />
          </div>
          <p className="ml-2">Timesheet</p>
        </div>
        <div className="ml-auto flex cursor-pointer">
          <p className="mx-3">{`Week ${getWeek(start, {
            weekStartsOn: 1,
            firstWeekContainsDate: 4,
          })}`}</p>
          <div className="flex items-center mx-3">
            <span
              className="cursor-pointer"
              onClick={() => handleChange("dec")}
            >
              <AiFillCaretLeft size={25} color="black" />
            </span>
            <p>{`${start.getDate()} - ${end.getDate()} ${format(
              end,
              "MMM"
            )}`}</p>
            <span
              className="cursor-pointer"
              onClick={() => handleChange("inc")}
            >
              <AiFillCaretRight size={25} color="black" />
            </span>
          </div>
        </div>
      </div>
      <div className="flex"></div>
      <div className="border rounded-lg grid grid-cols-5">
        <div className="px-6 h-full border-r pt-4">
          <p className="text-xl font-semibold mb-6">Sites</p>
          {sites.map((site, index) => (
            <div
              key={index}
              className={`flex items-center px-3 mb-4 cursor-pointer ${
                currSite.siteData.siteID === site.siteData.siteID
                  ? "bg-[#CFE5FC] py-3"
                  : ""
              }`}
              onClick={() => {
                setCurrSite(site);
              }}
            >
              <div
                className="w-3 h-3 rounded-full"
                style={{
                  backgroundColor: site?.siteData?.colorTag,
                }}
              ></div>
              <p className="text-sm ml-2 capitalize">
                {site?.siteData?.siteName}
              </p>
            </div>
          ))}
        </div>
        <div className="col-span-4">
          <div className="grid grid-cols-7 pb-6 pt-2 border-b px-6">
            <p>Name</p>
            <p>ID</p>
            <p>Total Hours</p>
            <p>Total Pay</p>
            <p>Total Shift</p>
            <p>Status</p>
            <p>Action</p>
          </div>
          {records.map((record, index) => (
            <div
              className="grid grid-cols-7 px-6 py-1 items-center"
              key={index}
            >
              <p className="text-sm capitalize">
                {record?.worker?.userFirstname} {record?.worker?.userLastname}
              </p>
              <p className="text-sm">{record?.worker?.userID}</p>
              <p className="text-sm">{record?.totalHours} hrs</p>
              <p className="text-sm">£{record?.totalPay}</p>
              <p className="text-sm">{record?.totalShiftCount}</p>
              <p className="text-sm">
                {record?.totalShiftCount ? record?.timeSheetStatus : "--"}
              </p>
              <div className="flex justify-between items-center">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (record?.shiftData?.length > 0) {
                      selectedUser.current = record?.worker?.userID;
                      handleDownload(record?.worker?.userID);
                    }
                  }}
                >
                  {isDownload &&
                  record?.worker?.userID === selectedUser.current ? (
                    <Loader size="sm" />
                  ) : (
                    <DownloadIcon />
                  )}
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setModal(true);
                    setModalData(record);
                  }}
                >
                  <EyeIcon />
                </div>
                <MailIcon />
              </div>
            </div>
          ))}
        </div>
        {modal && (
          <>
            <div
              className="modal-background"
              onClick={() => {
                setModal(false);
                setModalData({});
              }}
            ></div>
            <TimeSheetModal site={currSite} data={modalData} />
          </>
        )}
      </div>
    </div>
  );
};

export default TimeSheet;
