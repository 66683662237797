import { Button, createStyles, Loader, Textarea } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { ConfirmationModal } from "components/ConfirmationModal";
import { format } from "date-fns";
import useSendToAPI from "hooks/useSendToAPI";
import { errorToast, successToast } from "hooks/useToast";
import { Fragment, useState } from "react";
import { siteRequest } from "services/request";
import { IUser } from "types";
import { deleteRequest, getRequest, postRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";

const useStyles = createStyles((theme) => ({
  outline: {
    backgroundColor: "#fff !important",
    color: "red !important",
    border: "1.5px solid red",
  },
  label: {
    color: "red !important",
  },
  icon: {
    color: "red !important",
  },
}));

interface IProps {
  data: Record<string, any>;
  hideModal: () => void;
  user: IUser;
}

const ShiftActionsModal = ({ data, hideModal, user }: IProps) => {
  const queryClient = useQueryClient();
  const [shiftNote, setShiftNote] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [actionType, setActionType] = useState<
    "delete" | "start" | "end" | "draft" | null
  >(null);
  const { classes } = useStyles();

  const onSuccess = (res) => {
    queryClient.invalidateQueries([queryKeys.shiftHistory]);
    successToast(`Shift deleted successfully`);
    hideModal();
  };
  const onError = () => {
    errorToast("Error occurred!, Pls try again");
  };

  const { mutate: deleteShift, isLoading: isDeleting } = useSendToAPI(
    deleteRequest,
    onSuccess,
    onError
  );
  const handleDelete = () => {
    const shiftDayID = data?.shiftDayID;
    deleteShift({
      url: siteRequest.DELETE_SHIFT(shiftDayID),
    });
  };

  const onSuccessNotif = (res) => {
    if (res) {
      queryClient.invalidateQueries([queryKeys.shiftHistory]);
      successToast(`Successfully sent draft`);
      hideModal();
    } else {
      errorToast("Error occurred!, Pls try again");
    }
  };

  const { mutate: sendNotification, isLoading: isNotif } = useSendToAPI(
    postRequest,
    onSuccessNotif,
    onError
  );

  const modalHandler = () => {
    if (actionType === "delete") {
      return {
        title: "Are you sure you want to delete this shift?",
        action: handleDelete,
      };
    }
    if (actionType === "start") {
      return {
        title: "Are you sure you want to start this shift?",
        action: handleStartShift,
      };
    }
    if (actionType === "end") {
      return {
        title: "Are you sure you want to end this shift?",
        action: handleEndShift,
      };
    }
    if (actionType === "draft") {
      return {
        title: "Are you sure you want to send notification?",
        action: handleSendDraft,
      };
    }
    return {
      title: "",
      action: () => {},
    };
  };

  const handleSendDraft = () => {
    const shiftDayID = data?.shiftDayID;
    sendNotification({
      url: siteRequest.SEND_SHIFT_DRAFT,
      data: {
        shiftList: [shiftDayID],
      },
    });
  };

  const onStartEndSuccess = (res) => {
    if (res?.statusMessage) {
      errorToast(res?.statusMessage || "Error occurred!, Pls try again");
    } else {
      queryClient.invalidateQueries([queryKeys.shiftHistory]);
      successToast(`Shift status updated successfully`);
      hideModal();
    }
  };

  const { mutate: startShift, isLoading: isStartingShift } = useSendToAPI(
    getRequest,
    onStartEndSuccess,
    onError
  );

  const handleStartShift = () => {
    const shiftDayID = data?.shiftDayID;
    const payload = {
      shiftID: shiftDayID,
      lat: user.agency.agencyLatitude,
      lng: user.agency.agencyLongitude,
    };
    startShift({
      url: siteRequest.START_SHIFT(payload),
    });
  };

  const { mutate: endShift, isLoading: isEndingShift } = useSendToAPI(
    getRequest,
    onStartEndSuccess,
    onError
  );

  const handleEndShift = () => {
    const shiftDayID = data?.shiftDayID;
    const payload = {
      shiftID: shiftDayID,
      lat: user.agency.agencyLatitude,
      lng: user.agency.agencyLongitude,
      note: shiftNote,
    };
    endShift({
      url: siteRequest.END_SHIFT(payload),
    });
  };

  const disableBtn = () => {
    return isDeleting || isNotif || isStartingShift || isEndingShift;
  };

  const renderShiftDetails = () => {
    const shiftData = data;
    return (
      <Fragment>
        <h1 className="text-bold text-center font-semibold my-5">
          SHIFT SUMMARY
        </h1>
        <div className="flex justify-between my-3">
          <p>Actual start date & time</p>
          <p>
            {shiftData?.actualStartDateTime
              ? format(
                  new Date(shiftData?.actualStartDateTime),
                  "dd-MM-yyyy, h:m:s"
                )
              : "--"}
          </p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Actual end date & time</p>
          <p>
            {shiftData?.actualEndDateTime
              ? format(
                  new Date(shiftData?.actualEndDateTime),
                  "dd-MM-yyyy, h:m:s"
                )
              : "--"}
          </p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Actual hours worked</p>
          <p>{shiftData?.actualHoursWorked} hour(s)</p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Break Allowed</p>
          <p>{shiftData?.breakAllowed} min(s)</p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Shift Status</p>
          <p className="capitalize">{shiftData?.shiftStatus?.toLowerCase()}</p>
        </div>
        <hr />
        {shiftData?.shiftStatus === "COMPLETED" && (
          <div className="my-3">
            <p>Shift Note</p>
            <p>{shiftData?.incidenceReport?.report}</p>
          </div>
        )}
        {shiftData?.shiftStatus === "IN_PROGRESS" && (
          <div className="my-3">
            <Textarea
              withAsterisk
              label="Incidence Report"
              placeholder="Provide shift note."
              minRows={5}
              value={shiftNote}
              onChange={(evt) => setShiftNote(evt.target.value)}
            />
          </div>
        )}
      </Fragment>
    );
  };

  const showDeleteBtn =
    data?.shiftStatus === "PENDING" ||
    data?.shiftStatus === "ACCEPTED" ||
    data?.shiftStatus === "REJECTED";

  const { title, action } = modalHandler();

  return (
    // <div className="fixed-modal min-w-[500px] max-w-[90vw] h-[95vh] py-8 px-5">
    showConfirmation ? (
      <ConfirmationModal
        title={title}
        yesHandler={action}
        noHandler={() => {
          setActionType(null);
          setShowConfirmation(false);
          hideModal();
        }}
        loading={disableBtn()}
      />
    ) : (
      <div className="fixed-modal min-w-[500px] max-w-[600px] h-fit max-h-[95vh] py-8 px-5">
        <div>{renderShiftDetails()}</div>
        <div className="flex justify-between gap-3">
          {data?.shiftStatus === "DRAFT" && (
            <Button
              loading={isNotif}
              disabled={disableBtn()}
              // onClick={handleSendDraft}
              onClick={() => {
                setShowConfirmation(true);
                setActionType("draft");
              }}
            >
              Send Shift
            </Button>
          )}
          {data?.shiftStatus === "ACCEPTED" && (
            <Button
              loading={isStartingShift}
              disabled={disableBtn()}
              // onClick={handleStartShift}
              onClick={() => {
                setShowConfirmation(true);
                setActionType("start");
              }}
            >
              Start Shift
            </Button>
          )}
          {data?.shiftStatus === "IN_PROGRESS" && (
            <Button
              loading={isEndingShift}
              disabled={shiftNote.length === 0 || disableBtn()}
              // onClick={handleEndShift}
              onClick={() => {
                setShowConfirmation(true);
                setActionType("end");
              }}
            >
              End Shift
            </Button>
          )}
          {showDeleteBtn && (
            <Button
              classNames={{
                root: classes.outline,
                label: classes.label,
              }}
              sx={{
                ":disabled": {
                  border: "1.5px solid red",
                },
              }}
              onClick={() => {
                setShowConfirmation(true);
                setActionType("delete");
              }}
              disabled={disableBtn()}
            >
              {isDeleting && <Loader size="sm" color="red" />}
              <span className="ml-2 text-[red]">Withdraw Shift</span>
            </Button>
          )}
        </div>
      </div>
    )
  );
};

export default ShiftActionsModal;
