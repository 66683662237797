import { useEffect, useState } from "react";
import DashboardLayout from "components/DashboardLayout";
import {
  // CardIcon,
  // LogoutIcon,
  MenuIcon,
  NotificationIcon2,
  PlusIcon,
  ProfileIcon,
  // SearchIcon,
  SecurityIcon,
  ShiftIcon,
} from "components/Svgs";
// import { DeleteModal } from "components/DeleteModal";
// import SubscriptionTable from "./SubscriptionTable";
import CompanyProfile from "./CompanyProfile";
import NotificationSettings from "./NotificationSettings";
// import ShiftOptions from "./ShiftOptions";
import SecuritySettings from "./SecuritySettings";
import AddSubUserModal from "./AddSubUserModal";
import EditCompanyProfile from "./EditCompanyProfile";
import SubUserModal from "./SubUserModal";
import styles from "styles/Settings.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";
import { IUser } from "types";
import { getRequest } from "utils/apiCalls";
import { agencyRequest } from "services/request";
import RoleSettings from "./RoleSettings";
import useRolePermissions from "hooks/useRolePermissions";
import ShiftConfiguration from "./ShiftConfiguration";
import { ImNotification } from "react-icons/im";
import { RiFileSettingsLine } from "react-icons/ri";

const Settings = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;
  const { permissions } = useRolePermissions(user);
  // const [view, setView] = useState("subscription");
  const [userDetails, setUserDetails] = useState<Record<string, string> | null>(
    null
  );
  // const [viewName, setViewName] = useState("Activate paid plan");
  const [view, setView] = useState("profile");
  const [viewName, setViewName] = useState("Company profile");
  const [modals, setModals] = useState({
    active: false,
    editProfile: false,
    addUser: false,
    editUser: false,
    editShift: false,
    logOut: false,
  });

  useEffect(() => {
    let newViewName = "";
    if (view === "subscription") newViewName = "Activate paid plan";
    else if (view === "profile") newViewName = "Company profile";
    else if (view === "security") newViewName = "Security Settings";
    else if (view === "notification") newViewName = "Notification Settings";
    else if (view === "shift") newViewName = "Shift Options";
    else if (view === "add-user") newViewName = "Add sub user";

    setViewName(newViewName);
  }, [view]);

  const { isLoading: isLoadingRoles, data: agencyRoles = [] } = useQuery(
    [queryKeys.agencyRoles],
    async () =>
      getRequest({ url: agencyRequest.GET_AGENCY_ROLES(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  function hideModals() {
    setModals({
      active: false,
      editProfile: false,
      addUser: false,
      editUser: false,
      editShift: false,
      logOut: false,
    });
    setUserDetails(null);
  }
  function displayModal(name: string) {
    setModals((prev) => ({
      ...prev,
      active: true,
      [name]: true,
    }));
  }
  function checkActiveView(name: string) {
    return view === name;
  }

  function showSettingsNav() {
    const settingsNav = document.getElementById("settingsNav");
    settingsNav?.classList.add("slideIn");
  }
  function hideSettingsNav() {
    const settingsNav = document.getElementById("settingsNav");
    settingsNav?.classList.remove("slideIn");
  }
  function changeView(name: string) {
    setView(name);
    hideSettingsNav();
  }

  function getNonServiceWorkerRoles() {
    const userRoles = agencyRoles?.filter(
      (role) => role?.serviceWorkerRole === false
    );
    return userRoles;
  }

  return (
    <DashboardLayout>
      <div className="h-full overflow-hidden flex flex-col">
        <p className="text-3xl font-semibold">Settings</p>
        <div className="flex-1 relative overflow-y-auto overflow-x-hidden mb-4 h-full border-2 flex rounded-lg border-border mt-3 ">
          <div
            id="settingsNav"
            className="absolute z-10 transition-all -translate-x-[150%] sm:translate-x-0 sm:relative sm:w-1/4 bg-white h-full border-r"
          >
            {/* <div className="px-3 relative mb-0">
              <div className="absolute left-6 top-5">
                <SearchIcon />
              </div>
              <input type="text" placeholder="Search" className="pl-10 pt-2" />
            </div> */}
            <ul className={styles.settingsNav}>
              {/* <li
                className={`cursor-pointer ${
                  checkActiveView("subscription") && "bg-primary-50 "
                }`}
                onClick={() => changeView("subscription")}
              >
                <div>
                  <CardIcon />
                </div>
                <p>Activate paid plan</p>
              </li> */}
              <li
                className={`cursor-pointer ${
                  checkActiveView("profile") && "bg-primary-50 "
                }`}
                onClick={() => changeView("profile")}
              >
                <div>
                  <ProfileIcon />
                </div>
                <p>Company profile</p>
              </li>

              <li
                className={` cursor-pointer ${
                  checkActiveView("shift") && "bg-primary-50 "
                }`}
                onClick={() => changeView("shift")}
              >
                <div>
                  <ShiftIcon />
                </div>
                {/* <p>Shift options</p> */}
                <p>Role settings</p>
              </li>
              <li
                className={`cursor-pointer ${
                  checkActiveView("add-user") && "bg-primary-50"
                }`}
                onClick={() => changeView("add-user")}
              >
                <div>
                  <PlusIcon />
                </div>
                <p>Add sub user</p>
              </li>
              <li
                className={`cursor-pointer ${
                  checkActiveView("notification") && "bg-primary-50 "
                }`}
                onClick={() => changeView("notification")}
              >
                <div>
                  <NotificationIcon2 />
                </div>
                <p>Notification</p>
              </li>

              {(permissions.all || permissions.activityLog) && (
                <li
                  className={`cursor-pointer ${
                    checkActiveView("security") && "bg-primary-50 "
                  }`}
                  onClick={() => changeView("security")}
                >
                  <div>
                    <SecurityIcon />
                  </div>
                  <p>Security settings</p>
                </li>
              )}
              <li
                className={`cursor-pointer ${
                  checkActiveView("shiftConfig") && "bg-primary-50 "
                }`}
                onClick={() => changeView("shiftConfig")}
              >
                <div>
                  <RiFileSettingsLine
                    color="#666666"
                    fill="#666666"
                    size={25}
                  />
                </div>
                <p className="mr-5">Rota Settings</p>
                {!user.rulesSet && <ImNotification color="red" />}
              </li>
              {/* <li
                className={`cursor-pointer ${
                  checkActiveView("logout") && "bg-primary-50 "
                }`}
                onClick={() => {
                  displayModal("logOut");
                  setView("logout");
                }}
              >
                <div>
                  <LogoutIcon />
                </div>
                <p>Logout</p>
              </li> */}
            </ul>
          </div>
          <div className="flex-1 h-full relative overflow-x-hidden overflow-y-auto">
            <div className="absolute sm:hidden left-8 flex items-center top-2">
              <div onClick={showSettingsNav}>
                <MenuIcon />
              </div>
              <p className="ml-3 text-xl">{viewName}</p>
            </div>
            <div onClick={hideSettingsNav} className="pt-12 sm:pt-0">
              {/* {view === "subscription" && <SubscriptionTable />} */}
              {view === "profile" && (
                <CompanyProfile
                  hideModals={hideModals}
                  showModal={() => displayModal("editProfile")}
                  user={user}
                />
              )}
              {view === "notification" && <NotificationSettings user={user} />}
              {/* {view === "shift" && <ShiftOptions />} */}
              {view === "shift" && (
                <RoleSettings
                  // agencyID={user.agency.agencyID}
                  user={user}
                  // displayModal={() => displayModal("addUser")}
                  roles={agencyRoles}
                  isLoading={isLoadingRoles}
                />
              )}
              {view === "security" && <SecuritySettings user={user} />}
              {view === "add-user" && (
                <AddSubUserModal
                  agencyID={user.agency.agencyID}
                  displayModal={(userToEdit?: Record<string, string>) => {
                    displayModal("addUser");
                    setUserDetails(userToEdit ?? null);
                  }}
                  roles={agencyRoles}
                  isLoadingRoles={isLoadingRoles}
                  permissions={permissions}
                />
              )}
              {view === "shiftConfig" && <ShiftConfiguration user={user} />}
            </div>
          </div>
          {modals.active && (
            <>
              <div className="modal-background" onClick={hideModals}></div>
              {modals.editProfile && (
                <EditCompanyProfile user={user} hideModals={hideModals} />
              )}
              {/* {modals.logOut && (
                <DeleteModal
                  title="Are you sure you want to logout"
                  yesHandler={handleLogout}
                  noHandler={hideModals}
                />
              )} */}
              {modals.addUser && (
                <SubUserModal
                  user={user}
                  hideModals={hideModals}
                  roles={getNonServiceWorkerRoles()}
                  isLoadingRoles={isLoadingRoles}
                  data={userDetails}
                />
              )}
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
