import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import StaffProfileModal from "components/StaffProfileModal";
import DashboardLayout from "components/DashboardLayout";
import { MobileMenu } from "components/ProgressCard";
import ProgressCard from "components/ProgressCard";
import AddStaffModal from "./AddStaffModal";
import { getRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import { agencyRequest } from "services/request";
import { IUser } from "types";
import { tableColumn } from "./model";
import CustomTable from "components/CustomTable";
import BulkInviteModal from "./BulkInviteModal";
import DirectOnboardModal from "./DirectOnboardModal";
import GotoSettingsModal from "components/GoToSettingsModal";

const Staff = () => {
  const queryClient = useQueryClient();
  const [selectedProfile, setSelectedProfile] = useState<Record<string, any>>(
    {}
  );
  const [modals, setModals] = useState({
    profile: false,
    invite: false,
    active: false,
    bulkInvite: false,
    directOnboard: false,
  });
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;

  const { isLoading, data = [] } = useQuery(
    [queryKeys.allStaffs],
    async () =>
      getRequest({ url: agencyRequest.ALL_STAFFS(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: staffSummary } = useQuery(
    [queryKeys.staffSummary],
    async () =>
      getRequest({ url: agencyRequest.STAFF_SUMMARY(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isLoadingRoles, data: agencyRoles = [] } = useQuery(
    [queryKeys.agencyRoles],
    async () =>
      getRequest({ url: agencyRequest.GET_AGENCY_ROLES(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const displayModal = (name: string) => {
    setModals((prev) => ({
      ...prev,
      active: true,
      [name]: true,
    }));
  };
  const hideModal = () => {
    setModals({
      profile: false,
      invite: false,
      active: false,
      bulkInvite: false,
      directOnboard: false,
    });
  };

  const getUserRole = (data) => {
    const idx =
      typeof data?.role?.length === "number" ? data?.role?.length - 1 : 0;
    const roleId = data?.role?.[idx]?.roleID?.toString();
    const userRole = agencyRoles?.find(
      (role) => role?.roleID?.toString() === roleId
    );
    return userRole?.roleName;
  };

  const getServiceWorkerRoles = () => {
    const userRoles = agencyRoles?.filter(
      (role) => role?.serviceWorkerRole === true
    );
    return userRoles;
  };

  const column = [
    ...tableColumn,
    {
      accessorFn: (row) => getUserRole(row) ?? "N/A",
      id: "role",
      header: "Role",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorFn: (row) => {
        return (
          <span
            className=" text-primary cursor-pointer text-[14px] capitalize"
            onClick={() => {
              setSelectedProfile(row);
              displayModal("profile");
            }}
          >
            View
          </span>
        );
      },
      id: "action",
      header: "",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableSorting: false,
    },
  ];

  const formatSummary = (val: string | null) => {
    let temp = val;
    if (temp === "NaN") temp = "";
    return temp ? Number(Number(val).toFixed()) : 0;
  };

  return (
    <>
      <GotoSettingsModal visible={user.rulesSet} />
      <DashboardLayout>
        <div className="flex justify-between">
          <div className="flex items-center">
            <MobileMenu />
            <p className="text-3xl font-semibold">Staff</p>
          </div>
          <div className="flex gap-4">
            <div className="">
              <button
                className="bg-white border border-primary text-primary px-6 py-2 rounded-md"
                onClick={() => {
                  //  hideModal();
                  displayModal("directOnboard");
                }}
              >
                Direct Onboard
              </button>
            </div>
            {/* <div className="">
            <button
              className={style.btn}
              onClick={() => {
                //  hideModal();
                displayModal("bulkInvite");
              }}
            >
              Bulk Staff Invite
            </button>
          </div> */}
            {/* <div className="">
            <button
              className={style.btn}
              onClick={() => displayModal("invite")}
            >
              Single Staff Invite
            </button>
          </div> */}
          </div>
        </div>
        {/* <div className="grid grid-cols-2 md:grid-cols-5 gap-x-8  gap-y-3 mt-6 overflow-scroll"> */}
        {/* <div className="flex lg:justify-between  md:justify-start flex-wrap   mt-6"> */}
        <div className="flex flex-wrap mt-6">
          <ProgressCard
            name="Total Staff"
            value={formatSummary(
              (
                Number(staffSummary?.activeStaff) +
                Number(staffSummary?.inactiveStaff)
              ).toString()
            )}
            color="#CFE5FC"
            // percentage={homeSummary.percentageStaffFromLastMonth}
          />
          <ProgressCard
            name="Active Staff"
            value={formatSummary(staffSummary?.activeStaff)}
            color="#CFE5FC"
            // percentage={homeSummary.percentageStaffFromLastMonth}
          />
          <ProgressCard
            name="Inactive Staff"
            value={formatSummary(staffSummary?.inactiveStaff)}
            color="#FFE4F1"
            // percentage={homeSummary.percentageStaffFromLastMonth}
          />
          <ProgressCard
            name="Hours Worked"
            value={formatSummary(staffSummary?.totalHoursWorked)}
            color="#AE90E8"
            // percentage={homeSummary.percentageStaffFromLastMonth}
          />
          {/* <ProgressCard
          name="Hours Booked"
          value={formatSummary(staffSummary?.totalHoursBooked)}
          percentage={homeSummary.percentageStaffFromLastMonth}
        /> */}
          <ProgressCard
            name="Average Ratings"
            value={formatSummary(staffSummary?.agencyRating)}
            color="#D3D5FE"
            // percentage={homeSummary.percentageStaffFromLastMonth}
          />
        </div>
        <div className="flex flex-col sm:flex-row items-center mt-7">
          {modals.active && (
            <>
              <div
                className="fixed top-0 left-0  z-10 h-screen w-screen bg-[#666666] opacity-40"
                onClick={hideModal}
              ></div>
              {modals.profile && (
                <StaffProfileModal
                  data={selectedProfile}
                  agencyId={user.agency.agencyID}
                  hideModal={hideModal}
                  roles={agencyRoles}
                />
              )}
              {modals.invite && (
                <AddStaffModal
                  hideModal={hideModal}
                  agencyId={user.agency.agencyID}
                  roles={getServiceWorkerRoles()}
                  isLoading={isLoadingRoles}
                />
              )}
              {modals.bulkInvite && (
                <BulkInviteModal
                  hideModal={hideModal}
                  agencyId={user.agency.agencyID}
                  // roles={agencyRoles}
                  // isLoading={isLoadingRoles}
                  // onSelectBulkInvite={() => displayModal("bulkInvite")}
                />
              )}
              {modals.directOnboard && (
                <DirectOnboardModal
                  hideModal={hideModal}
                  agencyId={user.agency.agencyID}
                />
              )}
            </>
          )}
        </div>
        <CustomTable
          showSearch
          searchPlaceholder="Search staff by Staff ID / Name / Role / Date joined"
          loading={isLoading}
          data={data}
          columns={column}
        />
      </DashboardLayout>
    </>
  );
};

export default Staff;
