// import { Avatar } from "@mantine/core";

export const staffTableColumn = [
  //   {
  //     id: "id",
  //     header: "Staff ID",
  //     accessorFn: (row) => row?.worker?.userID ?? "N/A",
  //     cell: (info) => info.getValue(),
  //     footer: (props) => props.column.id,
  //   },
  {
    accessorFn: (row) => {
      const name = `${row?.worker?.userFirstname} ${row?.worker?.userLastname}`;
      return name ?? "N/A";
    },
    id: "name",
    header: "Name",
    cell: (info) => {
      return (
        <div className="flex items-center w-fit">
          {/* <Avatar
            src={info?.row?.original?.profilePicture}
            alt="user"
            radius="xl"
          /> */}
          {/* <p className="text-[14px] ml-3 w-fit capitalize">{info.getValue()}</p> */}
          <p className="text-[14px] w-fit capitalize">{info.getValue()}</p>
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.worker?.userEmail ?? "N/A",
    id: "userEmail",
    header: "Email",
    cell: (info) => <p className="text-[14px] w-fit ">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.worker?.userPhone ?? "N/A",
    id: "userPhone",
    header: "Phone No",
    cell: (info) => <p className="text-[14px] w-fit ">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  // {
  //   id: "createdAt",
  //   header: "Date Joined",
  //   accessorFn: (row) => {
  //     const date = row?.user?.createdAt
  //       ? new Date(row?.user?.createdAt).toLocaleDateString()
  //       : "N/A";
  //     return date;
  //   },
  //   cell: (info) => <p className="text-[14px]">{info.getValue()}</p>,
  //   footer: (props) => props.column.id,
  // },
];
