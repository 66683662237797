import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { Chart, registerables } from "chart.js";
import { PROTECTED_ROUTES } from "routes";
import { IUser } from "types";
import { SubscriberProvider } from "context/SubscriberContext";

const AppWrapper = () => {
  const routes = useRoutes(PROTECTED_ROUTES);

  return routes;
};

const Authenticated = ({ user }: { user: IUser }) => {
  Chart.register(...registerables);

  return (
    <Router>
      <SubscriberProvider user={user}>
        <AppWrapper />
      </SubscriberProvider>
    </Router>
  );
};
export default Authenticated;
