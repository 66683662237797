import { Button, TextInput } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import RoleSelect from "components/RoleSelect";
import { BackIcon } from "components/Svgs";
import useSendToAPI from "hooks/useSendToAPI";
import { successToast } from "hooks/useToast";
import { useSubUserValidation } from "hooks/useValidation";
import { useState } from "react";
import { agencyRequest } from "services/request";
import { IRole, IUser } from "types";
import { postRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";

type SubUserProps = {
  hideModals: () => void;
  user: IUser;
  edit?: boolean;
  roles?: Array<IRole>;
  isLoadingRoles?: boolean;
  data: Record<string, string> | null;
};
const SubUserModal = ({
  hideModals,
  user,
  roles,
  isLoadingRoles,
  data,
}: SubUserProps) => {
  const queryClient = useQueryClient();
  const [role, setRole] = useState(data?.staffRoleID ?? "");

  const form = useSubUserValidation(user.agency.agencyID, data);
  const onSuccess = (res) => {
    queryClient.invalidateQueries([queryKeys.agencySubUser]);
    successToast(`Sub user ${data ? "updated" : "added"} successfully`);
    hideModals();
  };

  const { mutate, isLoading: loading } = useSendToAPI(postRequest, onSuccess);

  const handleSubmit = (values) => {
    let payload = { ...values };
    if (data?.staffID) payload.staffID = data?.staffID;

    mutate({
      url: agencyRequest.ADD_AGENCY_SUB_USERS,
      data: payload,
    });
  };
  return (
    <div className="fixed-modal max-w-[95%] px-8 sm:px-12 pt-8 w-[450px]">
      <div
        className="fixed left-3 sm:left-6 cursor-pointer top-10"
        onClick={hideModals}
      >
        <BackIcon />
      </div>
      <form className="w-full p-0" onSubmit={form.onSubmit(handleSubmit)}>
        <div className="mb-6">
          <TextInput
            size="lg"
            type="text"
            label="Name"
            placeholder="Sub user name"
            {...form.getInputProps("staffName")}
          />
        </div>
        <div className="mb-6">
          <TextInput
            size="lg"
            type="email"
            label="Email"
            placeholder="name@example.com"
            {...form.getInputProps("staffEmail")}
          />
        </div>
        {/* <div className="mb-6">
          <TextInput
            size="lg"
            type="number"
            label="Phone Number"
            placeholder="Sub user phone number"
            {...form.getInputProps("staffPhone")}
          />
        </div> */}
        <div className="mb-6">
          <p>Role</p>
          <RoleSelect
            role={role}
            setRole={(value) => {
              setRole(value);
              form.setFieldValue("staffRoleID", value);
            }}
            isLoading={isLoadingRoles}
            roles={roles}
          />
          {form.errors.staffRoleID && (
            <p className="text-[13px] text-red-600 my-2">
              {form.errors.staffRoleID}
            </p>
          )}
        </div>
        <Button type="submit" loading={loading}>
          Done
        </Button>
      </form>
    </div>
  );
};

export default SubUserModal;
