import { useForm } from "@mantine/form";
import { nanoid } from "nanoid";
import { ISiteData } from "types";

export const useRegisterValidation = () => {
  const form = useForm({
    initialValues: {
      agencyName: "",
      agencyEmail: "",
      agencyPhone: "",
      agencyRoleName: "",
      agencyStaffName: "",
      agencySecret: "",
      agencyAddress: "",
      agencyPostCode: "",
      agencyLocation: "",
      agencyAbout: "",
      agencyStaff: "",
      confirmPassword: "",
      logoUploaded: false,
      confirmed: false,
    },
    validate: {
      agencyName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      agencyEmail: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
      agencyPhone: (value) =>
        value.length < 2 ? "Phone Number must have at least 2 letters" : null,
      agencyRoleName: (value) =>
        value.length < 2 ? "Role must have at least 2 letters" : null,
      agencyAddress: (value) =>
        value.length < 2 ? "Provide a valid post code" : null,
      agencyStaffName: (value) =>
        value.length < 2 ? "Company name must have at least 2 letters" : null,
      agencyAbout: (value) =>
        value.length < 2
          ? "About the company must have at least 2 letters"
          : null,
      agencySecret: (value) =>
        value.length < 2 ? "Password must have at least 2 letters" : null,
    },
  });

  return form;
};

export const useSignInValidation = () => {
  const form = useForm({
    initialValues: {
      email: "",
      secret: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      secret: (value) =>
        value.length < 2 ? "Password must have at least 2 letters" : null,
    },
  });

  return form;
};

export const useSiteValidation = (defaultValues = {}) => {
  const initialValues = {
    siteName: "",
    siteAddress: "",
    sitePostCode: "",
    colorTag: "",
    contactPersons: [
      {
        key: nanoid(),
        contactPersonName: "",
        contactPersonEmail: "",
        contactPersonPhone: "",
      },
    ],
  };

  const formatValues = () => {
    if (Object.keys(defaultValues).length === 0) {
      return initialValues;
    }
    const { siteData, contactPersons = [] } = defaultValues as ISiteData;
    const { siteName, siteAddress, sitePostCode, colorTag } = siteData;
    return {
      siteName: siteName ?? "",
      siteAddress: siteAddress ?? "",
      sitePostCode: sitePostCode ?? "",
      colorTag: colorTag ?? "",
      contactPersons:
        contactPersons.length > 0
          ? contactPersons.map((item) => ({
              key: item.siteContactPersonID ?? nanoid(),
              siteContactPersonID: item.siteContactPersonID,
              contactPersonName: item.contactPerson,
              contactPersonEmail: item.contactEmail,
              contactPersonPhone: item.contactPhone,
            }))
          : [
              {
                key: nanoid(),
                contactPersonName: "",
                contactPersonEmail: "",
                contactPersonPhone: "",
              },
            ],
    };
  };

  const form = useForm({
    initialValues: formatValues(),
    validate: {
      siteName: (value) =>
        value.length < 2 ? "Site name must have at least 2 letters" : null,
      siteAddress: (value) =>
        value.length < 2 ? "Address must have at least 2 letters" : null,
      sitePostCode: (value) =>
        value.length < 2 ? "Post Code must have at least 2 letters" : null,
      colorTag: (value) =>
        value.length < 2 ? "Color must have at least 2 letters" : null,
    },
  });

  return form;
};

export const useUpdateAgencyValidation = (user) => {
  const form = useForm({
    initialValues: {
      agencyID: user.agency.agencyID || "",
      agencyName: user.agency.agencyName || "",
      agencyAddress: user.agency.agencyAddress || "",
      agencyPostCode: user.agency.agencyPostCode || "",
      agencyLatitude: user.agency.agencyLatitude || "",
      agencyLongitude: user.agency.agencyLongitude || "",
      agencyEmail: user.agency.agencyEmail || "",
      agencyPhone: user.agency.agencyPhone || "",
      agencyAbout: user.agency.agencyAbout || "",
    },
    validate: {
      agencyName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      agencyEmail: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
      agencyPhone: (value) =>
        value.length < 2 ? "Phone Number must have at least 2 letters" : null,
      agencyAddress: (value) =>
        value.length < 2 ? "Provide a valid post code" : null,
      agencyAbout: (value) =>
        value.length < 2
          ? "About the company must have at least 2 letters"
          : null,
    },
  });

  return form;
};

export const useSubUserValidation = (agencyID, staff?) => {
  const form = useForm({
    initialValues: {
      agencyID: agencyID,
      staffName: staff?.staffName ?? "",
      staffEmail: staff?.staffEmail ?? "",
      staffRoleID: staff?.staffRoleID ?? "",
      // "staffPhone": "",
    },
    validate: {
      staffName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      staffRoleID: (value) =>
        value.length < 2 ? "User role cannot be empty" : null,
      staffEmail: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      // staffPhone: (value) =>
      // value.length < 2 ? "Phone Number must have at least 2 letters" : null,
    },
  });

  return form;
};
