import { useState } from "react";
import { IUser } from "types";
import { DownTriangleIcon, UpTriangleIcon } from "components/Svgs";
import { Button, Center, Group, Loader, Radio, TextInput } from "@mantine/core";
import useSendToAPI from "hooks/useSendToAPI";
import { getRequest, postRequest } from "utils/apiCalls";
import { successToast } from "hooks/useToast";
import { agencyRequest } from "services/request";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";
import {
  getBankHolidayRules,
  getBankHolidayWeekendRules,
} from "utils/constants";

interface IProps {
  user: IUser;
}

const ShiftConfiguration = ({ user }: IProps) => {
  const [config, setConfig] = useState<Record<string, string>>({});
  const [bankHolidayRate, setBankHolidayRate] = useState<string>("");
  const [bankHolidayWeekendRate, setBankHolidayWeekendRate] =
    useState<string>("");
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);

  const {
    refetch,
    isLoading: loading,
    isFetching,
  } = useQuery(
    [queryKeys.rotaConfig],
    async () =>
      getRequest({
        url: agencyRequest.GET_ROTA_CONFIG(user.agency.agencyID),
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const start = data?.nightShiftStartTime || "00:00:00";
        const end = data?.nightShiftEndTime || "00:00:00";
        handleShiftTimeRule(start, end);

        handleBankHolidayRules({
          rule: data?.bankHolidayRule || "",
          weekendRule: data?.bankHolidayWeekendRule || "",
          customAmount: data?.customAmount || "",
          customPercentage: data?.customPercentage || "",
          customWeekendAmount: data?.customWeekendAmount || "",
          customWeekendPercentage: data?.customWeekendPercentage || "",
        });
      },
    }
  );

  const handleShiftTimeRule = (start, end) => {
    const [nightStartHour, nightStartMin] = start.split(":");
    setStartHour(nightStartHour);
    setStartMinute(nightStartMin);

    let nightShiftEndTime = end || "00:00:00";
    const [nightEndHour, nightEndMin] = nightShiftEndTime.split(":");
    setEndHour(nightEndHour);
    setEndMinute(nightEndMin);
  };

  const handleBankHolidayRules = ({
    rule,
    weekendRule,
    customAmount,
    customPercentage,
    customWeekendAmount,
    customWeekendPercentage,
  }) => {
    let holidayRule = getBankHolidayRules(rule);
    let customRule = holidayRule;
    if (holidayRule === "4") {
      holidayRule = "customRule";
      customRule = "amount";
      setBankHolidayRate(customAmount);
    }
    if (holidayRule === "5") {
      holidayRule = "customRule";
      customRule = "percentage";
      setBankHolidayRate(customPercentage);
    }

    let weekendHolidayRule = getBankHolidayWeekendRules(weekendRule);
    let customWeekendRule = weekendHolidayRule;
    if (weekendHolidayRule === "4") {
      weekendHolidayRule = "customWeekendRule";
      customWeekendRule = "amount";
      setBankHolidayWeekendRate(customWeekendAmount);
    }
    if (weekendHolidayRule === "5") {
      weekendHolidayRule = "customWeekendRule";
      customWeekendRule = "percentage";
      setBankHolidayWeekendRate(customWeekendPercentage);
    }
    setConfig({
      bankHolidayRule: holidayRule,
      bankHolidayWeekendRule: weekendHolidayRule,
      customRule,
      customWeekendRule,
    });
  };

  const onSuccess = () => {
    // successToast("Rota Configuration updated Successfully!");
    successToast(
      "Successfully! Logout and login again to sync your agency settings."
    );
    refetch();
  };

  const { mutate, isLoading } = useSendToAPI(postRequest, onSuccess);

  function startHourController(increase: Boolean) {
    if (increase) {
      setStartHour((prev) => (Number(prev) < 23 ? Number(prev) + 1 : 0));
    } else {
      setStartHour((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 23));
    }
  }

  function startMinuteController(increase: Boolean) {
    if (increase) {
      setStartMinute((prev) => (Number(prev) < 59 ? Number(prev) + 1 : 0));
    } else {
      setStartMinute((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 59));
    }
  }
  function endHourController(increase: Boolean) {
    if (increase) {
      setEndHour((prev) => (Number(prev) < 23 ? Number(prev) + 1 : 0));
    } else {
      setEndHour((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 23));
    }
  }
  function endMinuteController(increase: Boolean) {
    if (increase) {
      setEndMinute((prev) => (Number(prev) < 59 ? Number(prev) + 1 : 0));
    } else {
      setEndMinute((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 59));
    }
  }

  const handleHolidayRule = () => {
    let bankHolidayRule = config?.bankHolidayRule;
    let bankHolidayWeekendRule = config?.bankHolidayWeekendRule;
    if (bankHolidayRule === "customRule" && config?.customRule === "amount") {
      bankHolidayRule = "4";
    }
    if (
      bankHolidayRule === "customRule" &&
      config?.customRule === "percentage"
    ) {
      bankHolidayRule = "5";
    }

    if (
      bankHolidayWeekendRule === "customWeekendRule" &&
      config?.customWeekendRule === "amount"
    ) {
      bankHolidayWeekendRule = "4";
    }
    if (
      bankHolidayWeekendRule === "customWeekendRule" &&
      config?.customWeekendRule === "percentage"
    ) {
      bankHolidayWeekendRule = "5";
    }

    return {
      bankHolidayRule,
      bankHolidayWeekendRule,
    };
  };

  const handleNightShiftsRule = () => {
    const startTimeHour =
      startHour.toString().length === 1
        ? "0" + startHour.toString()
        : startHour.toString();
    const startTimeMinute =
      startMinute.toString().length === 1
        ? "0" + startMinute.toString()
        : startMinute.toString();
    const endTimeHour =
      endHour.toString().length === 1
        ? "0" + endHour.toString()
        : endHour.toString();
    const endTimeMinute =
      endMinute.toString().length === 1
        ? "0" + endMinute.toString()
        : endMinute.toString();

    return {
      startTimeHour,
      startTimeMinute,
      endTimeHour,
      endTimeMinute,
    };
  };

  const handleSubmit = () => {
    const { bankHolidayRule, bankHolidayWeekendRule } = handleHolidayRule();
    const { startTimeHour, startTimeMinute, endTimeHour, endTimeMinute } =
      handleNightShiftsRule();

    const payload = {
      agencyID: user.agency.agencyID,
      bankHolidayRule: bankHolidayRule,
      bankHolidayWeekendRule: bankHolidayWeekendRule,
      customAmount: config?.customRule === "amount" ? bankHolidayRate : "0",
      customPercentage:
        config?.customRule === "percentage" ? bankHolidayRate : "0",
      customWeekendAmount:
        config?.customWeekendRule === "amount" ? bankHolidayWeekendRate : "0",
      customWeekendPercentage:
        config?.customWeekendRule === "percentage"
          ? bankHolidayWeekendRate
          : "0",
      nightStartTime: {
        hour: startTimeHour,
        minute: startTimeMinute,
      },
      nightEndTime: {
        hour: endTimeHour,
        minute: endTimeMinute,
      },
    };
    mutate({
      url: agencyRequest.UPDATE_ROTA_SETTINGS,
      data: payload,
    });
  };

  return (
    <>
      {loading || isFetching ? (
        <Center className="mt-10">
          <Loader />
        </Center>
      ) : (
        <div>
          <div className="border-b py-4 pl-6 mb-5">
            <p className="text-2xl hidden sm:block mb-1 font-semibold">
              Shift Configuration
            </p>
          </div>
          <div className="px-6">
            <div className="flex gap-5 w-full">
              <h3>Night Shift</h3>
              <div className="w-[500px]">
                <div className="flex justify-between items-center border-2 py-3 px-3 rounded-2xl">
                  <p>Start</p>
                  <div className="flex items-center">
                    <div className="flex flex-col justify-center items-center">
                      <div
                        className="cursor-pointer"
                        onClick={() => startHourController(true)}
                      >
                        <UpTriangleIcon />
                      </div>
                      <div className="my-2">
                        <TextInput
                          classNames={{
                            root: "border-0 m-0 w-[60px]",
                            input: "!m-0",
                          }}
                          type="number"
                          value={("00" + startHour).slice(-2)}
                          onChange={(e) => {
                            const val = Number(e.target.value);
                            if (val >= 0 && val <= 23) {
                              setStartHour(val);
                            } else setStartHour(0);
                          }}
                        />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => startHourController(false)}
                      >
                        <DownTriangleIcon />
                      </div>
                    </div>
                    <p className="mx-2">:</p>
                    <div className="flex flex-col justify-center items-center">
                      <div
                        className="cursor-pointer"
                        onClick={() => startMinuteController(true)}
                      >
                        <UpTriangleIcon />
                      </div>
                      <div className="my-2">
                        <TextInput
                          classNames={{
                            root: "border-0 m-0 w-[60px]",
                            input: "!m-0",
                          }}
                          type="number"
                          value={("00" + startMinute).slice(-2)}
                          onChange={(e) => {
                            const val = Number(e.target.value);
                            if (val >= 0 && val <= 59) {
                              setStartMinute(val);
                            } else setStartMinute(0);
                          }}
                        />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => startMinuteController(false)}
                      >
                        <DownTriangleIcon />
                      </div>
                    </div>
                  </div>
                  <div className="h-1 w-4 bg-text"></div>
                  <div className="flex items-center">
                    <div className="flex flex-col justify-center items-center">
                      <div
                        className="cursor-pointer"
                        onClick={() => endHourController(true)}
                      >
                        <UpTriangleIcon />
                      </div>
                      <div className="my-2">
                        <TextInput
                          classNames={{
                            root: "border-0 m-0 w-[60px]",
                            input: "!m-0",
                          }}
                          type="number"
                          value={("00" + endHour).slice(-2)}
                          onChange={(e) => {
                            const val = Number(e.target.value);
                            if (val >= 0 && val <= 23) {
                              setEndHour(val);
                            } else setEndHour(0);
                          }}
                        />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => endHourController(false)}
                      >
                        <DownTriangleIcon />
                      </div>
                    </div>
                    <p className="mx-2">:</p>
                    <div className="flex flex-col justify-center items-center">
                      <div
                        className="cursor-pointer"
                        onClick={() => endMinuteController(true)}
                      >
                        <UpTriangleIcon />
                      </div>
                      <div className="my-2">
                        <TextInput
                          classNames={{
                            root: "border-0 m-0 w-[60px]",
                            input: "!m-0",
                          }}
                          type="number"
                          value={("00" + endMinute).slice(-2)}
                          onChange={(e) => {
                            const val = Number(e.target.value);
                            if (val >= 0 && val <= 59) {
                              setEndMinute(val);
                            } else setEndMinute(0);
                          }}
                        />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => endMinuteController(false)}
                      >
                        <DownTriangleIcon />
                      </div>
                    </div>
                  </div>
                  <p>End</p>
                </div>
              </div>
            </div>
            <p className="text-[red] text-sm mt-2">
              <b>Caution: </b>
              Note that for a shift that starts before the night time
              configuration, the hour difference between start time and
              configured start time for nightshifts will be calculated and
              appropriate rates applied. This also applies for shift end time.
            </p>
          </div>
          <div className="px-6 mt-4">
            <div className="flex gap-5">
              <h3 className="whitespace-nowrap mt-2">Bank Holiday Settings</h3>
              <div className=" flex-grow">
                <Radio.Group
                  value={config?.bankHolidayRule || ""}
                  onChange={(e) =>
                    setConfig({ ...config, bankHolidayRule: e, customRule: "" })
                  }
                >
                  <Group>
                    <Radio
                      value="0"
                      label="Standard (Weekend and Weekday Rates)"
                    />
                    <Radio value="1" label="Bank Holiday Rate" />
                    <Radio value="2" label="Bank Holiday + Standard Rate" />
                    <Radio value="3" label="A Term and a Half" />
                    <Radio value="6" label="2X Standard Rate" />
                    <Radio value="customRule" label="Standard Rates + Custom" />
                  </Group>
                </Radio.Group>
                {config?.bankHolidayRule === "customRule" && (
                  <div className="mt-5 ">
                    <Radio.Group
                      value={config?.customRule || ""}
                      onChange={(e) => setConfig({ ...config, customRule: e })}
                    >
                      <Group>
                        <Radio value="amount" label="Custom amount" />
                        <Radio value="percentage" label="Custom percentage" />
                      </Group>
                    </Radio.Group>
                    {config?.customRule && (
                      <TextInput
                        className="w-fit mt-5"
                        label={`Custom ${config?.customRule}`}
                        placeholder={`Enter ${config?.customRule}`}
                        type="number"
                        value={bankHolidayRate}
                        onChange={(evt) => setBankHolidayRate(evt.target.value)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="px-6 mt-8">
            <div className="flex gap-5">
              <h3 className="whitespace-nowrap mt-2">
                Bank Holiday Weekend Settings
              </h3>
              <div className=" flex-grow">
                <Radio.Group
                  value={config?.bankHolidayWeekendRule || ""}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      bankHolidayWeekendRule: e,
                      customWeekendRule: "",
                    })
                  }
                >
                  <Group>
                    <Radio value="0" label="Bank Holiday Rates" />
                    <Radio value="1" label="Bank Holiday + Weekend Rates" />
                    <Radio value="2" label="Weekend Rates" />
                    <Radio
                      value="customWeekendRule"
                      label="Standard Rates + Custom"
                    />
                    <Radio
                      value="3"
                      label="A Term and a Half [Standard Rates + 1/2 of Standard Rates] per hour"
                    />
                  </Group>
                </Radio.Group>
                {config?.bankHolidayWeekendRule === "customWeekendRule" && (
                  <div className="mt-5 ">
                    <Radio.Group
                      value={config?.customWeekendRule || ""}
                      onChange={(e) =>
                        setConfig({ ...config, customWeekendRule: e })
                      }
                    >
                      <Group>
                        <Radio value="amount" label="Custom amount" />
                        <Radio value="percentage" label="Custom percentage" />
                      </Group>
                    </Radio.Group>
                    {config?.customWeekendRule && (
                      <TextInput
                        className="w-fit mt-5"
                        label={`Custom ${config?.customWeekendRule}`}
                        placeholder={`Enter ${config?.customWeekendRule}`}
                        type="number"
                        value={bankHolidayWeekendRate}
                        onChange={(evt) =>
                          setBankHolidayWeekendRate(evt.target.value)
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end my-6 px-6">
            <Button
              className="!w-[200px]"
              loading={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ShiftConfiguration;
