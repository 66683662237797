import { showNotification } from "@mantine/notifications";

export const successToast = (message: string) => {
  showNotification({
    title: "Successful",
    message,
    autoClose: 5000,
    color: "indigo",
  });
};
export const errorToast = (message: string) => {
  showNotification({
    title: "An error occurred.",
    message,
    autoClose: 5000,
    color: "red",
  });
};
