import axios from "axios";
import { sessionInfo } from "utils/constants";
import config from "config";
// export * from "./request";

// export const baseURL = process.env.REACT_APP_API_BASE_URL;
export const baseURL = config.REACT_APP_API_BASE_URL;
const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
  // used for cookie
  // withCredentials: true, u
});

//  This adds a token before all the requests.
// https://stackoverflow.com/questions/57251719/acquiring-a-new-token-with-axios-interceptors
const addTokenToRequest = (request: any) => {
  const session = JSON.parse(
    (typeof window === "object" && window.localStorage.getItem(sessionInfo)) ||
      "{}"
  );
  request.headers.Authorization = `Bearer ${session.token}`;
  request.headers.source = "web";
  return request;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      window.localStorage.clear();
      window.location.href = "/login";
      return;
    } else return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.localStorage.clear();
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
