import { useState } from "react";
import { createStyles, Loader } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { format } from "date-fns";
import { queryKeys } from "utils/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "utils/apiCalls";
import { siteRequest } from "services/request";
import { errorToast } from "hooks/useToast";

const useStyles = createStyles((theme) => ({
  weekend: {
    color: `#666666 !important`,
  },
  selected: {
    color: "purple !important",
  },
  input: {
    border: "1px solid #D5D6D7",
    borderRadius: "15px",
    width: "300px",
    padding: "15px",
  },
}));

const TimeSheetModal = ({ data, site }) => {
  const { classes } = useStyles();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const agencyID = site.siteData.agencyID;
  const siteID = site.siteData.siteID;

  const { isLoading, data: records = [] } = useQuery(
    [queryKeys.userTimesheet, agencyID, siteID, data.worker.userID, dateRange],
    async () => {
      const startDate = dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "";
      const endDate = dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "";
      return getRequest({
        url: siteRequest.GET_USER_TIMESHIFT({
          agencyId: agencyID,
          siteId: siteID,
          startDate,
          endDate,
          userID: data.worker.userID,
        }),
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!(dateRange[0] && dateRange[1]),
      onSuccess(data) {
        const hasData =
          Array.isArray(data?.[0]?.shiftData) && data[0].shiftData.length > 0;
        if (!hasData) {
          errorToast("No record found!");
        }
      },
      onError(data) {
        errorToast("No record found!");
      },
    }
  );

  const shiftRecord = records.length > 0 ? records[0] : data;

  const renderShifts = () => {
    const shiftData =
      shiftRecord?.shiftData?.length > 0
        ? shiftRecord?.shiftData
        : Array(1).fill({});

    return shiftData.map((shift, index) => (
      <div className="grid grid-cols-8" key={index}>
        <span className="px-5 text-center py-2 border-y border-r">
          {shift?.shiftDate ? format(new Date(shift?.shiftDate), "d EE") : "--"}
        </span>
        <span className="px-5 text-center py-2 border-y border-r">
          {shift?.actualStartDateTime
            ? format(new Date(shift?.actualStartDateTime), "h:maaa")
            : " --"}
        </span>
        <span className="px-5 text-center py-2 border-y border-r">
          {shift?.actualEndDateTime
            ? format(new Date(shift?.actualEndDateTime), "h:maaa")
            : "--"}
        </span>
        <span className="px-5 text-center py-2 border-y border-r">
          {Number.isInteger(shift?.breakAllowed)
            ? `${shift?.breakAllowed}mins`
            : "--"}
        </span>
        <span className="px-5 text-center py-2 border-y border-r">
          {Number.isInteger(shift?.actualHoursWorked)
            ? `${shift?.actualHoursWorked}hrs`
            : "--"}
        </span>
        <span className="px-5 text-center py-2 border-y border-r">
          {shift?.shiftRate ? `£${shift?.shiftRate}` : "--"}
        </span>
        <span className="px-5 text-center py-2 border-y border-r">
          {Number.isInteger(shift?.actualHoursWorked) &&
          Number.isInteger(shift?.shiftRate)
            ? `£${
                parseFloat(shift?.actualHoursWorked) *
                parseFloat(shift?.shiftRate)
              }`
            : "--"}
        </span>
        <span className="px-5 text-center py-2 border-y border-r capitalize">
          {shift?.shiftStatus
            ? shift?.shiftStatus?.toString().toLowerCase()
            : "--"}
        </span>
      </div>
    ));
  };

  return (
    <div className="fixed-modal w-[80vw] pb-8">
      {!!(dateRange[0] && dateRange[1]) && isLoading && (
        <>
          <div className="bg-text opacity-20 z-10"></div>
          <div className="fixed left-[50%] flex justify-center mt-5 z-50">
            <Loader size="lg" color="blue" />
          </div>
        </>
      )}
      <div className="flex items-center justify-between px-8 pt-6">
        <div className="flex">
          <div className="w-9 h-9 rounded-full bg-primary"></div>
          <div className="ml-3">
            <p className="text-sm capitalize">
              {data?.worker?.userFirstname} {data?.worker?.userLastname}
            </p>
            <span className="text-sm"> {data?.worker?.userID}</span>
          </div>
        </div>
        <div className="flex items-center">
          <div
            className={`w-3 h-3 rounded-full`}
            style={{
              backgroundColor: site?.siteData?.colorTag,
            }}
          ></div>
          <p className="ml-2">{site?.siteData?.siteName}</p>
        </div>
        <div className="flex justify-center items-center">
          <div className="mr-3 mb-2">
            <p>Sort By:</p>
          </div>
          <DateRangePicker
            placeholder="Date range"
            value={dateRange}
            onChange={setDateRange}
            classNames={{
              input: classes.input,
            }}
            disabled={!!(dateRange[0] && dateRange[1]) && isLoading}
          />
        </div>
      </div>
      <div className="grid grid-cols-8 mt-6">
        <p className="px-5 text-center py-2 border-y border-r"> Date</p>
        <p className="px-5 text-center py-2 border-y border-r"> Time In</p>
        <p className="px-5 text-center py-2 border-y border-r"> Time Out</p>
        <p className="px-5 text-center py-2 border-y border-r"> Break</p>
        <p className="px-5 text-center py-2 border-y border-r"> Total Hours</p>
        <p className="px-5 text-center py-2 border-y border-r"> Rate</p>
        <p className="px-5 text-center py-2 border-y border-r"> Total Rate</p>
        <p className="px-5 text-center py-2 border-y border-r"> Status</p>
      </div>
      {renderShifts()}
      <div className="px-8 flex mt-5">
        <p className="text-lg">Total Hours = {shiftRecord?.totalHours}hrs</p>
        <p className="ml-4 text-lg">Total Pay = £{shiftRecord?.totalPay}</p>
      </div>
    </div>
  );
};

export default TimeSheetModal;
