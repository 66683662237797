import React, { useMemo, useState } from "react";
import { Tabs } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import {
  // AddContactIcon,
  SearchIcon,
} from "components/Svgs";
import { siteRequest } from "services/request";
import style from "styles/Messages.module.css";
import { getRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import { SingleChat, SiteChat } from "./MessageBox";
import { isAfter } from "date-fns";

const ChannelListSection = ({
  activeTab,
  setActiveTab,
  activeGroupChatInfo,
  setActiveGroupChatInfo,
  activeSingleChatInfo,
  setActiveSingleChatInfo,
  groupChannelID,
  refetch,
  user,
  agencyStaff,
  allMessages,
  lastReadTimestamp,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: agencySites = [] } = useQuery(
    [queryKeys.allAgencySites],
    async () =>
      getRequest({ url: siteRequest.GET_AGENCY_SITES(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleUnreadCount = (channelID) => {
    // const timestamp = new Date("2022-12-08T10:43:05.000+00:00");
    const timestamp = lastReadTimestamp?.[channelID];
    const messages = allMessages?.[channelID] ?? [];
    const filterData = messages.filter((item) => {
      return (
        item.senderID.toString() !== user.staff.staffID.toString() &&
        isAfter(new Date(item.createdAt), new Date(timestamp || item.latRead))
      );
    });
    return filterData.length;
  };

  const channels = useMemo(() => {
    const searchBySiteName = agencySites.filter(
      ({ siteData: { siteName } }) => {
        return searchValue
          ? RegExp(`^${searchValue}`, "ig").test(siteName.trim().toLowerCase())
          : true;
      }
    );
    return searchBySiteName.length ? searchBySiteName : agencySites;
  }, [searchValue, agencySites]);

  return (
    <div className={style.chats} id="chats">
      <div className="px-4">
        {/* <div className="flex items-center">
          <span className="font-semibold">New Chats</span>
          <span className="text-primary block ml-auto mr-3">Start chat</span>
          <div className="cursor-pointer" onClick={() => showModal("staff")}>
            <AddContactIcon />
          </div>
        </div> */}
        <div className="relative mt-2">
          <div className="absolute top-5 left-3">
            <SearchIcon />
          </div>
          <input
            type="text"
            name=""
            placeholder="Search chat"
            className="py-2 pl-10"
            id=""
            value={searchValue}
            onChange={(evt) => setSearchValue(evt.target.value)}
          />
        </div>
      </div>
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="public">
            <div className="flex items-center">
              <p>Group</p>
              {/* {count > 0 && (
                <span className="ml-1">
                  <Avatar size="sm" color="red">
                    {count}
                  </Avatar>
                </span>
              )} */}
            </div>
          </Tabs.Tab>
          <Tabs.Tab value="private">Chat</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="public">
          <div className="border  overflow-auto">
            <SingleChat
              item={{
                siteData: {
                  agencyID: user.agency.agencyID,
                  siteID: user.agency.agencyID,
                  siteName: "Public Room",
                },
                user: {
                  userID: user.agency.agencyID,
                  userFirstname: "Public Room",
                  userLastname: "",
                },
                pictureLink: user.agencyLogo,
              }}
              active={activeGroupChatInfo}
              setActive={(item) => {
                setActiveGroupChatInfo(item);
                groupChannelID.current = item.siteData.siteID;
                refetch();
              }}
              handleUnreadCount={handleUnreadCount}
            />
            {channels.map((chat, index) => (
              <SiteChat
                item={chat}
                active={activeGroupChatInfo}
                setActive={(item) => {
                  setActiveGroupChatInfo(item);
                  groupChannelID.current = item.siteData.siteID;
                  refetch();
                }}
                key={index}
                handleUnreadCount={handleUnreadCount}
              />
            ))}
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="private">
          <div className="border flex-1 overflow-auto">
            {Array.isArray(agencyStaff) &&
              agencyStaff.map((staff, index) => (
                <SingleChat
                  key={index}
                  item={staff}
                  active={activeSingleChatInfo}
                  setActive={setActiveSingleChatInfo}
                  handleUnreadCount={handleUnreadCount}
                />
              ))}
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default React.memo(ChannelListSection);
