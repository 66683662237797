export const agencyRequest = {
  SIGNUP: "/agency/signup",
  ADD_ROLE: "roles/agency/add",
  UPDATE_ROLE: "roles/agency/update",
  INVITE_STAFF: ({ agencyID, email, roleID, rate }) =>
    `/agency/invite/send/?agencyID=${agencyID}&email=${email}&roleID=${roleID}&rate=${rate}`,
  SEARCH_STAFF: (email) => `user/search/email/${email}`,
  BULK_INVITE_STAFF: ({ agencyID }) => `/agency/onboard/bulk/${agencyID}`,
  DIRECT_ONBOARD_STAFF: "/agency/direct/onboard",
  SHIFT_HISTORY: (agencyId) => `/requests/agency/get/${agencyId}`,
  FINANCE_HISTORY: (agencyId) => `agency/finances/history/${agencyId}`,
  HOME_SUMMARY: (agencyId) => `/agency/summary/get/n/${agencyId}`,
  FINANCE_SUMMARY: (agencyId) => `finance/get/agency/summary/${agencyId}`,
  GET_INVOICE_BY_MONTH: ({ agencyId, month, year }) =>
    `invoices/agency/get/${agencyId}/${month}/${year}`,
  GET_AGENCY_ROLES: (agencyId) => `/roles/agency/all/${agencyId}`,
  GET_LIVE_SHIFT: (agencyId) => `requests/agency/live/${agencyId}`,
  TODAY_SHIFTS: (agencyId) => `requests/agency/today/${agencyId}`,
  ALL_STAFFS: (agencyId) => `/agency/user/get/${agencyId}`,
  STAFF_SUMMARY: (staffId) => `/agency/summary/staff/get/${staffId}`,
  STAFF_DETAILS: (staffId) => `/user/info/${staffId}`,
  STAFF_RATE: ({ agencyID, staffId }) =>
    `/agency/rate/get/user?userID=${staffId}&agencyID=${agencyID}`,
  SET_RATE: `/agency/rate/save/user`,
  WORK_HISTORY: (userID, agencyId) =>
    `/requests/user/agency/get?userID=${userID}&agencyID=${agencyId}`,
  ADD_USER_ROLE: `user/roles/add`,
  REMOVE_USER_FROM_AGENCY: (agencyId, userID) =>
    `/agency/user/remove?userID=${userID}&agencyID=${agencyId}`,
  GET_MONTH_SHIFT: (agencyId) => `requests/agency/month/${agencyId}`,
  GET_YEAR_MONTH_SHIFT: ({ agencyId, month, year }) =>
    `requests/agency/shifts/any/month/${agencyId}/${month}/${year}`,
  EDIT_AGENCY_DATA: `agency/update`,
  GET_AGENCY_SUB_USERS: (agencyId) => `staff/all/${agencyId}`,
  ADD_AGENCY_SUB_USERS: `staff/add`,
  CHANGE_AGENCY_STAFF_PASSWORD: ({ staffId, password, oldPass }) =>
    `staff/secret/${staffId}/${password}/${oldPass}`,
  UPLOAD_AGENCY_LOGO: (agencyId) => `agency/uploadLogo/${agencyId}`,
  GET_NOTIFICATION_CONFIG: (agencyId) => `/permissions/get/${agencyId}`,
  GET_ROTA_CONFIG: (agencyId) => `/agency/rules/get?agencyID=${agencyId}`,
  UPDATE_NOTIFICATION_CONFIG: `permissions/save`,
  GET_ACTIVITY_LOG: (agencyId) => `/permissions/log/get/${agencyId}`,
  UPDATE_ACTIVITY_LOG: `permissions/log/save`,
  SHIFT_SUMMARY: (shiftID) => `shifts/validate/get/${shiftID}`,
  VALIDATE_SHIFT: "shifts/validate/do",
  PAYMENT_INTENT: "payment/gateway/checkout/createCheckout",
  PAYMENT_SUCCESS: (sessionID) => `payment/afterCharge?sessionID=${sessionID}`,
  UPDATE_ROTA_SETTINGS: "agency/rota/rules",
};

export const authRequest = {
  LOGIN: "/auth/agency2",
  RESET_PASSWORD: "/auth/send/reset",
  SET_PASSWORD: "/auth/do/agency/reset",
  SUBSCRIBE: "/subscriptions/subscribe",
};
interface IArgs {
  shiftID: number | string;
  lat: number | string;
  lng: number | string;
  note?: string;
}
export const siteRequest = {
  START_SHIFT: ({ shiftID, lat, lng }: IArgs) =>
    `requests/shifts/start/${shiftID}?coord=${lat},${lng}`,
  END_SHIFT: ({ note, shiftID, lat, lng }: IArgs) =>
    `requests/shifts/end/${shiftID}/user?shiftNotes=${note}&endedAt=${lat},${lng}`,
  CREATE_SITE: "/sites/add",
  EDIT_SITE: "/sites/update",
  SEND_SHIFT_DRAFT: "requests/agency/shifts/drafts/send",
  GET_AGENCY_SITES: (agencyId) => `sites/all/${agencyId}`,
  DELETE_SITE: (siteId) => `sites/delete/${siteId}`,
  GET_SITE_WORKER: (siteId) => `sites/workers/get/${siteId}`,
  GET_WEEK_SHIFT: (siteId) => `requests/agency/shifts/week/${siteId}`,
  ADD_SITE_WORKER: `sites/workers/add`,
  CREATE_SHIFT: `requests/create`,
  GET_SHIFT_BY_DATE_RANGE: `/requests/agency/shifts/between`,
  SHIFT_VALIDATION: `/shifts/validate/internal/do`,
  DELETE_SHIFT: (shiftID) => `requests/delete/${shiftID}`,
  VALIDATED_SHIFT: (siteID) => `timesheet/validated/${siteID}`,
  GET_WEEKLY_TIMESHEET: ({ agencyId, siteId, startDate, endDate }) =>
    `timesheet/site/${agencyId}/${siteId}/${startDate}/${endDate}`,
  INTERNAL_VALIDATION: ({ agencyId, siteId, startDate, endDate }) =>
    `timesheet/site/internal/${agencyId}/${siteId}/${startDate}/${endDate}`,
  GET_USER_TIMESHIFT: ({ userID, agencyId, siteId, startDate, endDate }) =>
    `timesheet/site/${agencyId}/${siteId}/${startDate}/${endDate}/${userID}`,
  GET_REPORT_BY_DATE_RANGE: ({ agencyId, startDate, endDate }) =>
    `requests/agency/shifts/report/between/${agencyId}/${startDate}/${endDate}`,
  DOWNLOAD_WEEKLY_TIMESHEET: ({
    userID,
    agencyId,
    siteId,
    startDate,
    endDate,
  }) =>
    `timesheet/download/${agencyId}/${siteId}/${startDate}/${endDate}/${userID}`,
  DOWNLOAD_WEEKLY_ROTA: ({ email, agencyId, siteId, startDate, endDate }) =>
    `rota/download/${agencyId}/${siteId}/${startDate}/${endDate}/${email}`,
};

export const messagingRequest = {
  GROUP_CHAT_HISTORY: ({ channelPath, channelID, agencyID, userID, source }) =>
    `/channel/chat/history?channelPath=${channelPath}&channelID=${channelID}&source=${source}&agencyID=${agencyID}&userID=${userID}`,
};
