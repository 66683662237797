import { useMemo, useState } from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { Text } from "@mantine/core";
import {
  //   Column,
  //   Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  //   sortingFns,
  getSortedRowModel,
  FilterFn,
  //   SortingFn,
  ColumnDef,
  flexRender,
  //   FilterFns,
} from "@tanstack/react-table";
import {
  createStyles,
  Table as MantineTable,
  Pagination,
  Skeleton,
  TextInput,
  ScrollArea,
} from "@mantine/core";
import {
  //   RankingInfo,
  rankItem,
  //   compareItems,
} from "@tanstack/match-sorter-utils";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  td: {
    border: "none !important",
  },

  tr: {
    border: "none !important",
  },

  control: {
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
  inputIcon: {
    margin: "10px",
  },
  input: {
    padding: "20px !important",
    paddingLeft: "3rem !important",
  },
  header: {
    zIndex: 1,
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

interface ITable {
  data: Record<string, any>[];
  columns: ColumnDef<any>[];
  showSearch?: boolean;
  loading?: boolean;
  searchPlaceholder?: string;
  height?: string;
}

const CustomTable = ({
  data,
  columns,
  showSearch,
  loading,
  searchPlaceholder,
  height,
}: ITable) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const { classes, cx } = useStyles();

  const tableColumns = useMemo(() => columns, [columns]);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns: tableColumns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: true,
    debugColumns: false,
  });

  const EmptyState = () => (
    <tr>
      <td colSpan={columns.length}>
        <Text weight={500} align="center">
          No record found
        </Text>
      </td>
    </tr>
  );

  const loaders = Array(5)
    .fill("")
    .map((_, i) => (
      <tr key={i} className={classes.tr}>
        {columns.map((col) => (
          <td key={col.id} className={classes.tr}>
            <Skeleton height={8} mt={6} width="100%" radius="xl" />
          </td>
        ))}
      </tr>
    ));

  const renderRows = () => {
    if (!table.getRowModel().rows.length) {
      return <EmptyState />;
    }

    return table.getRowModel().rows.map((row) => {
      return (
        <tr className={classes.tr} key={row.id}>
          {row.getVisibleCells().map((cell) => {
            return (
              <td
                className={`${classes.tr} py-[10px] px-4 leading-8 font-normal text-sm text-[#666666]`}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className="relative">
      <div className=" w-[100%] relative overflow-scroll">
        <div className="w-full flex justify-between items-start">
          {showSearch && (
            <div className="w-full">
              <TextInput
                placeholder={searchPlaceholder || "Search by any field"}
                mb="md"
                classNames={{
                  input: classes.input,
                  icon: classes.inputIcon,
                }}
                icon={<AiOutlineSearch size={24} />}
                value={globalFilter ?? ""}
                onChange={(evt) => setGlobalFilter(String(evt.target.value))}
              />
            </div>
          )}
        </div>
        <ScrollArea
          sx={{ height: height || 600 }}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <MantineTable
            horizontalSpacing="md"
            verticalSpacing="xs"
            sx={{
              tableLayout: "auto",
              // tableLayout: "fixed",
              // minWidth: 700,
              borderCollapse: "separate",
              border: "1px solid #DEE2E6",
              borderRadius: "10px",
              overflow: "scroll",
            }}
          >
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className="w-fit" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        className={classes.control}
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            className={
                              header.column.getCanSort()
                                ? "flex flex-row gap-4 items-center cursor-pointer select-none"
                                : ""
                            }
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <BiChevronUp />,
                              desc: <BiChevronDown />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody>{loading ? loaders : renderRows()}</tbody>
          </MantineTable>
        </ScrollArea>
        <div className="my-5">
          <Pagination
            withControls
            withEdges
            total={Math.floor(
              table.getFilteredRowModel().rows.length /
                table.getState().pagination.pageSize
            )}
            page={table.getState().pagination.pageIndex + 1}
            onChange={(val) => {
              const page = val ? Number(val) - 1 : 0;
              table.setPageIndex(page);
            }}
            position="right"
            styles={(theme) => ({
              item: {
                "&[data-active]": {
                  backgroundColor: "#8353F7",
                },
              },
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
