import CustomTable from "components/CustomTable";
import { BackIcon } from "components/Svgs";
import { inviteColumn } from "./model";
import { Button } from "@mantine/core";
import * as xlsx from "xlsx";
import { useRef, useState } from "react";
import useSendToAPI from "hooks/useSendToAPI";
import { postRequest } from "utils/apiCalls";
import { agencyRequest } from "services/request";
import { successToast } from "hooks/useToast";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";

const BulkInviteModal = ({ hideModal, agencyId }) => {
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const onSuccess = (res) => {
    // const failedUsers = res?.failedUsers
    // const onboardedUsers = res?.onboardedUsers
    const statusMessage = res?.statusMessage;

    successToast(statusMessage || "Users Onboarded Successfully!");
    queryClient.invalidateQueries([queryKeys.allStaffs]);
    hideModal();
  };
  const { mutate, isLoading: loading } = useSendToAPI(postRequest, onSuccess);

  const header = [
    "First Name",
    "Last Name",
    "Email Address",
    "Phone Number",
    "Gender",
    "DOB (DD/MM/YYYY)",
    "Address",
    "Nationality",
  ];

  const formatData = (data: any[]) => {
    const format = data.map((i) => {
      const dob = i?.[header[5]] || "";
      const [day = "", month = "", year = ""] = dob.split("/");
      return {
        userFirstname: i?.[header[0]] || "",
        userLastname: i?.[header[1]] || "",
        userEmail: i?.[header[2]] || "",
        userPhone: i?.[header[3]] || "",
        userGender: i?.[header[4]] || "",
        dobYear: year,
        userAddress: i?.[header[6]] || "",
        userNationality: i?.[header[7]] || "",
        dobMonth: month,
        dobDay: day,
      };
    });
    return format;
  };

  const handleDownload = () => {
    const data = Array(1).fill({
      firstName: "John",
      lastName: "Doe",
      emailAddress: "johndoe@example.com",
      phoneNumber: "+1234567890",
      gender: "Male",
      dob: "23/10/1990",
      address: "10 San Francisco, CA",
      nationality: "Nigeria",
    });
    let alink = document.createElement("a");
    const ws = xlsx.utils.book_new();
    const sheetNames = ["data"];
    xlsx.utils.sheet_add_aoa(ws, [header]);
    xlsx.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    const wb = {
      Sheets: { [sheetNames[0]]: ws },
      SheetNames: sheetNames,
    };
    const excelBuffer = xlsx.write(wb, {
      bookType: "xlsx",
      type: "array",
      // type: "binary",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    const fileURL = window.URL.createObjectURL(finalData);
    alink.href = fileURL;
    alink.download = `bulk_invite-sample.xlsx`;
    alink.click();
  };

  const handleSubmit = () => {
    if (uploadedData.length > 0) {
      mutate({
        url: agencyRequest.BULK_INVITE_STAFF({ agencyID: agencyId }),
        data: { workers: uploadedData },
      });
    } else {
      inputRef.current?.click();
    }
  };

  return (
    <div className="fixed-modal  max-w-[95%] items-center h-fit w-[80%] ">
      <div className="bg-white max-w-full w-[450px] relative  sm:px-12 rounded-lg p-8 z-20">
        <div
          className="absolute left-4 top-[30px] cursor-pointer"
          onClick={hideModal}
        >
          <BackIcon />
        </div>
      </div>
      <div className="px-5">
        <input
          ref={inputRef}
          className="hidden"
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              const reader = new FileReader();
              reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);

                const formatJSON = formatData(json);
                setUploadedData(formatJSON);
              };
              reader.readAsArrayBuffer(e.target.files[0]);
            }
          }}
        />
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-black text-lg">
            Invite multiple staff in one click
          </h1>
          <div className="min-w-[35%] flex gap-3">
            <button
              className="outline-btn text-xs w-full px-2 py-2"
              onClick={handleDownload}
              disabled={loading}
            >
              Download Sample
            </button>
            <Button
              className="!m-0 !p-0  w-fit"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            >
              {uploadedData.length > 0 ? "Submit" : "Upload Data"}
            </Button>
          </div>
        </div>
        <CustomTable data={uploadedData} columns={inviteColumn} />
      </div>
    </div>
  );
};

export default BulkInviteModal;
