import { useMemo } from "react";
/**
 * format graph data based on selected year and month
 * @param data contains { weekInMonthForPreviousYear = [], weekInMonthForCurrentYear = [] }
 * @param year string with value currYear || prevYear
 * @param month string with value all || each month
 * @returns returns formatted data
 */
const useGraphSummary = (data, year = "currYear", month = "All") => {
  const formatData = useMemo(() => {
    const initialState = {
      all: {
        booked: [],
        worked: [],
      },
    };
    const isPreviousYear = year === "prevYear";
    const isCurrYear = year === "currYear";
    const prevYear: any = initialState,
      currYear: any = initialState;

    const months = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };

    let currData = {
      booked: [],
      worked: [],
    };
    const key = month === "All" ? month.toLowerCase() : month;
    const { weekInMonthForPreviousYear = [], weekInMonthForCurrentYear = [] } =
      data;
    let iterator: any = [];
    if (isPreviousYear) iterator = weekInMonthForPreviousYear;
    if (isCurrYear) iterator = weekInMonthForCurrentYear;

    for (let i = 0; i < iterator.length; i++) {
      const [monthIndex, ...others] = iterator[i];
      const currMonth = months[monthIndex.month as number];
      // Add up all booked for the month
      const monthBooked = others.reduce(
        (total, i) => total + parseFloat(i.booked ?? "0"),
        0
      );
      // Add up all worked for the month
      const monthWorked = others.reduce(
        (total, i) => total + parseFloat(i.worked ?? "0"),
        0
      );

      if (isPreviousYear) {
        const { booked, worked } = prevYear["all"];
        // Add book and worked for each month in previous year
        prevYear[currMonth] = {
          booked: others.map((i) => parseFloat(i.booked ?? "0")),
          worked: others.map((i) => parseFloat(i.worked ?? "0")),
        };
        // Add book and worked in previous year
        prevYear["all"] = {
          booked: [...booked, monthBooked],
          worked: [...worked, monthWorked],
        };
      }
      if (isCurrYear) {
        const { booked, worked } = currYear["all"];
        // Add book and worked for each month in current year
        currYear[currMonth] = {
          booked: others.map((i) => parseFloat(i.booked ?? "0")),
          worked: others.map((i) => parseFloat(i.worked ?? "0")),
        };
        // Add book and worked in current year
        currYear["all"] = {
          booked: [...booked, monthBooked],
          worked: [...worked, monthWorked],
        };
      }
    }

    if (isPreviousYear) {
      currData = prevYear[key] ?? currData;
    }
    if (isCurrYear) {
      currData = currYear[key] ?? currData;
    }
    return currData;
  }, [data, year, month]);

  return formatData;
};

export default useGraphSummary;
