import { Avatar, Button, FileInput } from "@mantine/core";
import { Fragment, useState } from "react";
import { IUser } from "types";
import { GrUploadOption } from "react-icons/gr";
import { agencyRequest } from "services/request";
import useSendToAPI from "hooks/useSendToAPI";
import { postRequest } from "utils/apiCalls";
import { successToast } from "hooks/useToast";
import useRolePermissions from "hooks/useRolePermissions";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";

type CompanyProfileProps = {
  showModal: () => void;
  hideModals: () => void;
  user: IUser;
};

const UploadFileModal = ({
  hideModals,
  value,
  setValue,
  onSubmit,
  loading,
}) => {
  return (
    <Fragment>
      <div className="modal-background" onClick={hideModals}></div>
      <div className="fixed-modal max-w-[95%] px-8 sm:px-12 pt-7 pb-4 w-[450px]">
        <p className="mb-2">Upload a file</p>
        <FileInput
          label="Agency Logo"
          // placeholder="Upload a file"
          accept="image/png,image/jpeg,image/jpg"
          radius="xl"
          icon={<GrUploadOption />}
          value={value}
          onChange={setValue}
        />
        <Button disabled={!value} onClick={onSubmit} loading={loading}>
          Upload
        </Button>
      </div>
    </Fragment>
  );
};

const CompanyProfile = ({
  showModal,
  hideModals,
  user,
}: CompanyProfileProps) => {
  const queryCache = useQueryClient();
  const { permissions } = useRolePermissions(user);
  const [showUpload, setShowUpload] = useState(false);
  const [file, setFile] = useState(null);

  const handleModal = () => {
    setShowUpload(false);
    hideModals();
    setFile(null);
  };

  const onSuccess = (res) => {
    if (typeof res === "string" && res.includes("https")) {
      const updateUser = { ...user, agencyLogo: res };
      queryCache.setQueryData([queryKeys.user], updateUser);
      window.location.reload();
    }
    successToast(`Agency logo uploaded successfully.`);
    handleModal();
  };
  const { mutate, isLoading } = useSendToAPI(postRequest, onSuccess);

  const handleSubmit = () => {
    const payload = new FormData();
    if (file) {
      payload.append("file", file);
    }
    mutate({
      url: agencyRequest.UPLOAD_AGENCY_LOGO(user.agency.agencyID),
      data: payload,
    });
  };

  return (
    <div>
      {showUpload && (
        <UploadFileModal
          hideModals={handleModal}
          value={file}
          setValue={setFile}
          onSubmit={handleSubmit}
          loading={isLoading}
        />
      )}
      <div className="flex justify-between border-b px-10 py-4">
        <div className="w-fit text-center">
          {/* <div className="h-20 w-20 rounded-full mx-auto bg-primary"> */}
          <div className="w-fit text-center">
            <Avatar
              src={user.agencyLogo || ""}
              size={100}
              alt="user"
              radius={350}
            />
            {(permissions.all || permissions.editAgencyInfo) && (
              <span
                className="text-primary my-2 block cursor-pointer"
                onClick={() => {
                  setShowUpload(true);
                }}
              >
                Upload logo
              </span>
            )}
          </div>
          <p className="font-semibold">{user.agency.agencyName}</p>
        </div>
        {(permissions.all || permissions.editAgencyInfo) && (
          <span
            onClick={showModal}
            className="border-2 px-14 py-2 block mt-auto mb-1 border-primary text-primary font-semibold rounded-lg h-fit cursor-pointer"
          >
            Edit
          </span>
        )}
      </div>
      <div className="px-10 mt-6">
        <p className="font-semibold mb-4">About</p>
        <span className="font-semibold block mb-7">
          {user.agency.agencyAbout}
        </span>
        <div className="flex items-center mb-2">
          <p className="font-semibold mr-2">Email:</p>
          <span className="font-semibold">{user.agency.agencyEmail}</span>
        </div>
        <div className="flex items-center mb-2">
          <p className="font-semibold mr-2">Phone No:</p>
          <span className="font-semibold">{user.agency.agencyPhone}</span>
        </div>
        <div className="flex items-center mb-2">
          <p className="font-semibold mr-2">Date Joined:</p>
          <span className="font-semibold">
            {new Date(user.agency.createdAt).toDateString()}
          </span>
        </div>
        <div className="flex items-center mb-2">
          <p className="font-semibold mr-2">Address:</p>
          <span className="font-semibold">{user.agency.agencyAddress}</span>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
