export const queryKeys = {
  chat: "chat",
  user: "user",
  listOfAddress: "listOfAddress",
  homeSummary: "homeSummary",
  financeSummary: "financeSummary",
  financeHistory: "financeHistory",
  invoices: "invoices",
  allStaffs: "allStaffs",
  workHistory: "workHistory",
  shiftSummary: "shiftSummary",
  staffDetails: "staffDetails",
  staffRate: "staffRate",
  liveShift: "liveShift",
  todayShifts: "todayShifts",
  staffSummary: "staffSummary",
  agencyRoles: "agencyRoles",
  shiftHistory: "shiftHistory",
  allAgencySites: "allAgencySites",
  validatedShifts: "validatedShifts",
  siteWorkers: "siteWorkers",
  currWeekShift: "currWeekShift",
  currMonthShift: "currMonthShift",
  yearMonthShift: "yearMonthShift",
  weeklyTimesheet: "weeklyTimesheet",
  internalValidation: "internalValidation",
  downloadWeeklyTimesheet: "downloadWeeklyTimesheet",
  userTimesheet: "userTimesheet",
  weeklyShiftReport: "weeklyShiftReport",
  agencySubUser: "agencySubUser",
  notificationConfig: "notificationConfig",
  rotaConfig: "rotaConfig",
  activityLogConfig: "activityLogConfig",
  groupChatHistory: "groupChatHistory",
  notification: "notification",
  paymentSummary: "paymentSummary",
};
