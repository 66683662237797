import { useQueryClient } from "@tanstack/react-query";
import { isBefore, isValid } from "date-fns";
import { useEffect } from "react";
import { sessionInfo } from "utils/constants";
import { queryKeys } from "utils/queryKeys";
import Authenticated from "./Authenticated";
import UnAuthenticated from "./UnAuthenticated";
import { usePostHog } from "posthog-js/react";

const Pages = () => {
  const posthog = usePostHog();
  const queryCache = useQueryClient();
  const user = JSON.parse(window.localStorage.getItem(sessionInfo) || "{}");

  useEffect(() => {
    // CHECK IF TOKEN HAS NOT EXPIRED
    const isExpired =
      !isValid(new Date(user.expiresIn)) &&
      isBefore(new Date(user.expiresIn), new Date());

    if (isExpired) {
      window.localStorage.clear();
    }
    if (user && Object.keys(user).length > 0) {
      posthog?.identify(user?.staff.staffID, {
        email: user?.staff.staffEmail,
        name: user?.staff.staffName,
      });
      posthog?.group("company", user?.agency.agencyName);
    }
    // eslint-disable-next-line
  }, [user]);

  if (user && Object.keys(user).length !== 0) {
    // SET USER OBJECT TO BE AVAILABLE GLOBALY
    queryCache.setQueryData([queryKeys.user], user);

    return <Authenticated user={user} />;
  }

  return <UnAuthenticated />;
};
export default Pages;
