import { useLocation, NavLink } from "react-router-dom";
import Logo from "images/logo.png";
import {
  CalendarIcon,
  FinancialIcon,
  FinancialsIconFilled,
  HomeFilledIcon,
  HomeIcon,
  LogoutIcon,
  MessageFilledIcon,
  MessageIcon,
  SettingsFilledIcon,
  SettingsIcon,
  ShiftFilledIcon,
  StaffIcon,
  StaffIconFilled,
  ValidationIcon,
} from "./Svgs";
import { ChildrenType, IUser } from "types";
import style from "styles/Dashboard.module.css";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";
import useRolePermissions from "hooks/useRolePermissions";

const DashboardLayout = ({ children }: ChildrenType) => {
  // const [permissions, setPermissions] = useState({});
  // const [siteIDs, setSiteIDs] = useState([]);
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;
  const { permissions } = useRolePermissions(user);

  // useEffect(() => {
  //   const temp = {};
  //   // const siteIDs: string[] = [];
  //   const format = user.role.rolePermissions?.split(",") ?? [];
  //   format.forEach((i: string) => {
  //     if (i.includes("all")) {
  //       temp["all"] = true;
  //     } else {
  //       if (i.includes("validations")) {
  //         const curr = i.replace("[", "").split(":");
  //         const ids = JSON.parse(curr[1]);
  //         // setSiteIDs(ids);
  //         setSiteIDs(ids);
  //       }
  //       if (i.includes("shifts")) {
  //         temp["shifts"] = true;
  //       }
  //       if (i.includes("staffs")) {
  //         temp["staffs"] = true;
  //       }
  //       if (i.includes("financials")) {
  //         temp["financials"] = true;
  //       }
  //       if (i.includes("editAgencyInfo")) {
  //         temp["editAgencyInfo"] = true;
  //       }
  //       if (i.includes("createUser")) {
  //         temp["createUser"] = true;
  //       }
  //       if (i.includes("createRoles")) {
  //         temp["createRoles"] = true;
  //       }
  //       if (i.includes("activityLog")) {
  //         temp["activityLog"] = true;
  //       }
  //       if (i.includes("timesheet")) {
  //         temp["timesheet"] = true;
  //       }
  //     }
  //   });
  //   setPermissions(temp);
  //   // console.log(format, siteIDs);
  // }, []);

  const { pathname } = useLocation();
  function checkPath(page: string) {
    if (pathname === page) return true;
    return false;
  }
  function hideSidebar() {
    const sideBarElement = document.getElementById("sidebar");
    sideBarElement?.classList.remove("slideIn");
  }
  function handleLogout() {
    window.localStorage.clear();
    window.location.href = "/";
  }
  return (
    <div className="flex w-full bg-white">
      <div className={style.sidebar} id="sidebar">
        <div className="w-[230px] h-full flex flex-col justify-between pt-8 pl-10 sm:w-full bg-white border-r ">
          <div>
            <div className="flex justify-between pr-2 h-fit mb-6">
              <img src={Logo} className={style.logo} alt="" />
              <p
                className="text-3xl font-semibold sm:hidden mt-1 cursor-pointer"
                onClick={hideSidebar}
              >
                X
              </p>
            </div>
            <ul className={style.sidebarLinks}>
              <NavLink to="/">
                <li
                  style={{
                    background: `${checkPath("/") ? "#8353F7" : "none"}`,
                  }}
                >
                  {checkPath("/") ? <HomeFilledIcon /> : <HomeIcon />}
                  <p
                    style={{ color: `${checkPath("/") ? "white" : "#666666"}` }}
                  >
                    Home
                  </p>
                </li>
              </NavLink>
              {(permissions.all || permissions.staff) && (
                <NavLink to="/staff">
                  <li>
                    {checkPath("/staff") ? <StaffIconFilled /> : <StaffIcon />}
                    <p>Staff</p>
                  </li>
                </NavLink>
              )}

              {(permissions.all || permissions.shifts) && (
                <NavLink to="/shifts">
                  <li>
                    {checkPath("/shifts") ? (
                      <ShiftFilledIcon />
                    ) : (
                      <CalendarIcon />
                    )}
                    <p>Rotas</p>
                  </li>
                </NavLink>
              )}
              {(permissions.all || permissions.validations) && (
                <NavLink to="/validations">
                  <li>
                    <ValidationIcon
                      color={checkPath("/validations") ? "#fff" : "#666666"}
                    />
                    <p>Validations</p>
                  </li>
                </NavLink>
              )}
              {(permissions.all || permissions.financials) && (
                <NavLink to="/financials">
                  <li>
                    {checkPath("/financials") ? (
                      <FinancialsIconFilled />
                    ) : (
                      <FinancialIcon />
                    )}
                    <p>Financials</p>
                  </li>
                </NavLink>
              )}
              <NavLink to="/messages">
                <li>
                  {checkPath("/messages") ? (
                    <MessageFilledIcon />
                  ) : (
                    <MessageIcon />
                  )}
                  <p>Messages</p>
                </li>
              </NavLink>
              <NavLink to="/settings">
                <li>
                  {checkPath("/settings") ? (
                    <SettingsFilledIcon />
                  ) : (
                    <SettingsIcon />
                  )}
                  <p>Account</p>
                </li>
              </NavLink>
            </ul>
          </div>
          <div className={style.logout} onClick={handleLogout}>
            <LogoutIcon />
            <p>Log Out</p>
          </div>
        </div>
        <div
          className="flex-1 sm:hidden h-full opacity-0"
          onClick={hideSidebar}
        ></div>
      </div>
      <div className={style.contents}>{children}</div>
    </div>
  );
};

export default DashboardLayout;
