import { Center, Group, Loader, Select, Text } from "@mantine/core";
import { forwardRef, useMemo } from "react";

interface IRoles {
  roleID: number;
  createdAt: string;
  updatedAt: string;
  agencyID: string;
  roleName: string;
  deleted: boolean;
}
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  roleID: number;
  createdAt: string;
  updatedAt: string;
  agencyID: string;
  roleName: string;
  deleted: boolean;
}

const RoleSelect = ({ roles, isLoading, role, setRole }) => {
  const formatedRoles = useMemo(() => {
    return roles.map(({ roleID, roleName }: IRoles) => ({
      roleName,
      label: roleName,
      value: roleID?.toString(),
    }));
  }, [roles]);

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ roleName, roleID, ...others }: ItemProps, ref) => (
      <div ref={ref} key={roleID} {...others}>
        <Group noWrap>
          <div className="w-full flex justify-between items-center">
            <Text size="sm">{roleName}</Text>
          </div>
        </Group>
      </div>
    )
  );
  const Loadertem = forwardRef<HTMLDivElement, ItemProps>(
    ({ roleID, ...others }: ItemProps, ref) => (
      <div ref={ref} key={roleID} {...others}>
        <Center>
          <Loader size="sm" color="#aaa" />
        </Center>
      </div>
    )
  );

  return (
    <Select
      value={role}
      placeholder="Select role"
      data={isLoading ? Array(1).fill({ label: "", value: "" }) : formatedRoles}
      itemComponent={isLoading ? Loadertem : SelectItem}
      onChange={(value: string) => setRole(value)}
    />
  );
};

export default RoleSelect;
