import React, { useState, useEffect } from "react";
import { Avatar, Center, createStyles, Loader, TextInput } from "@mantine/core";
import { AiOutlineSearch } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { nanoid } from "nanoid";

interface IProps {
  siteID: string;
  loading: boolean;
  agencyStaff: Array<Record<string, any>>;
  siteStaff: Array<Record<string, any>>;
  agencyRoles: Array<Record<string, any>>;
  onSelect: (args: number[]) => void;
  currStaff: number[];
  allowSelectAll?: boolean;
  showCloseBtn?: boolean;
  onClose?: () => void;
}

const useStyles = createStyles(() => ({
  inputIcon: {
    marginLeft: "3px",
  },
  input: {
    paddingLeft: "2.2rem !important",
  },
}));

const AgencyStaff = ({
  loading,
  agencyStaff,
  onSelect,
  currStaff,
  allowSelectAll = false,
  showCloseBtn = false,
  onClose,
}: IProps) => {
  const [value, setValue] = useState("");
  const [store, setStore] = useState(agencyStaff);
  const { classes } = useStyles();
  useEffect(() => {
    setStore(agencyStaff);
  }, [agencyStaff]);

  const renderData = () =>
    store.map((item) => {
      const name = `${item?.user?.userFirstname} ${item?.user?.userLastname}`;
      const userId = item?.user?.userID;
      const roleName =
        item?.role?.[0]?.roleName || item?.role?.[0]?.roleID || "";

      return (
        <div
          key={nanoid()}
          className="my-2 grid grid-cols-2 gap-4 cursor-pointer transition duration-500 hover:p-2 hover:rounded-md hover:bg-[#eeecec]"
          onClick={() => {
            const position = currStaff.indexOf(userId);
            if (position === -1) {
              onSelect([...currStaff, userId]);
            } else {
              let data = [...currStaff];
              data.splice(position, 1);
              onSelect(data);
            }
          }}
        >
          <div className="flex items-center">
            <Avatar src={item?.pictureLink} alt="user" radius="xl" />
            <p className="text-sm capitalize ml-5 whitespace-nowrap">{name}</p>
          </div>
          <div className="flex items-center">
            <p className="text-sm capitalize ">{userId}</p>
            <p className="text-sm capitalize mx-8">{roleName}</p>
            {currStaff.includes(userId as any) && (
              <FaCheck size={13} color="green" />
            )}
          </div>
        </div>
      );
    });

  const handleSearch = (search) => {
    setValue(search);
    const searchByName = agencyStaff.filter(
      ({ user: { userFirstname, userLastname } }) => {
        const firstLast = RegExp(`^${search}`, "ig").test(
          `${userFirstname} ${userLastname}`.trim().toLowerCase()
        );
        const lastFirst = RegExp(`^${search}`, "ig").test(
          `${userLastname} ${userFirstname}`.trim().toLowerCase()
        );
        return search ? firstLast || lastFirst : true;
      }
    );
    const result = searchByName.length ? searchByName : agencyStaff;
    setStore(result);
  };

  const handleSelectAll = () => {
    const ids = agencyStaff.map((item) => item?.user?.userID || "");
    onSelect(ids);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex-grow">
            <TextInput
              icon={<AiOutlineSearch size={18} />}
              placeholder="Search by role/name"
              classNames={{
                input: classes.input,
                icon: classes.inputIcon,
              }}
              value={value}
              onChange={(evt) => handleSearch(evt.target.value)}
            />
          </div>

          <div className="w-fit mb-4 flex justify-between items-center">
            {allowSelectAll && (
              <p
                className="text-primary text-sm mx-5 cursor-pointer"
                onClick={handleSelectAll}
              >
                Select all
              </p>
            )}
            {showCloseBtn && (
              <span className="mx-2 cursor-pointer" onClick={onClose}>
                <GrFormClose size={22} />
              </span>
            )}
          </div>
        </div>
        <div className="h-[400px] m-0 mb-5 overflow-auto">
          {loading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <div>{renderData()}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(AgencyStaff);
