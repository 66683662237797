import { useCallback, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Menu } from "@mantine/core";
import { DeleteModal } from "components/DeleteModal";
import ProgressCard from "components/ProgressCard";
import DashboardLayout from "components/DashboardLayout";
import PayRollModal from "./PayRollModal";
import { queryKeys } from "utils/queryKeys";
import { getRequest } from "utils/apiCalls";
import { agencyRequest, siteRequest } from "services/request";
import { IUser } from "types";
import CustomTable from "components/CustomTable";
import { tableColumn } from "./model";
import InvoiceModal from "./InvoiceModal";
import { FaChevronDown } from "react-icons/fa";
import { FiDownloadCloud } from "react-icons/fi";
import { endOfWeek, format, startOfWeek, subDays } from "date-fns";
import AgencySites from "./Sites";
import GotoSettingsModal from "components/GoToSettingsModal";

const options: any = {
  weekStartsOn: 1,
};

const Financials = () => {
  const [paymentSchedule, setPaymentSchedule] = useState("");
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;

  const { data = {} } = useQuery(
    [queryKeys.financeSummary],
    async () =>
      getRequest({ url: agencyRequest.FINANCE_SUMMARY(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading, data: financeHistory = [] } = useQuery(
    [queryKeys.financeHistory],
    async () =>
      getRequest({ url: agencyRequest.FINANCE_HISTORY(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: sites = [] } = useQuery(
    [queryKeys.allAgencySites],
    async () =>
      getRequest({ url: siteRequest.GET_AGENCY_SITES(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const column = [...tableColumn];

  const [modals, setModals] = useState({
    invoice: false,
    payRoll: false,
    active: false,
    pay: false,
    sites: false,
  });

  function displayModal(name: string) {
    setModals((prev) => ({
      ...prev,
      active: true,
      [name]: true,
    }));
  }
  function hideModals() {
    setModals({
      invoice: false,
      active: false,
      payRoll: false,
      pay: false,
      sites: false,
    });
  }

  const handleWeeks = useCallback((args: "cur" | "prev") => {
    const start = startOfWeek(new Date(), options);
    const end = endOfWeek(new Date(), options);
    if (args === "prev") {
      const newStart = subDays(start, 7);
      const newEnd = subDays(start, 1);
      return `${newStart.getDate()} - ${newEnd.getDate()} ${format(
        newEnd,
        "MMM, yyyy"
      )}`;
    } else {
      return `${start.getDate()} - ${end.getDate()} ${format(
        end,
        "MMM, yyyy"
      )}`;
    }
  }, []);

  return (
    <DashboardLayout>
      <GotoSettingsModal visible={user.rulesSet} />
      <div className="h-full flex flex-col">
        <div className="flex items-center justify-between mb-4">
          <p className="text-bold text-3xl">Financials</p>
          {/* <div className="w-[150px]">
            <Button onClick={() => displayModal("invoice")}>Invoices</Button>
          </div> */}
          <Menu width={280} shadow="md" offset={-5}>
            <Menu.Target>
              <div>
                <Button
                  className="!py-2 !px-4"
                  rightIcon={<FaChevronDown color="#fff" size={15} />}
                >
                  Payment Schedule
                </Button>
              </div>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                // component="a"
                // href="/financials"
                onClick={() => {
                  setPaymentSchedule(`Previous week (${handleWeeks("cur")})`);
                  displayModal("sites");
                }}
                icon={<FiDownloadCloud />}
              >
                Previous week ({handleWeeks("cur")})
              </Menu.Item>
              <Menu.Item
                // component="a"
                // href="/financials"
                icon={<FiDownloadCloud />}
                onClick={() => {
                  setPaymentSchedule(`Current week (${handleWeeks("prev")})`);
                  displayModal("sites");
                }}
              >
                Current week ({handleWeeks("prev")})
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
        <div className="flex flex-wrap mt-6">
          <ProgressCard
            name="Payout This Week"
            value={data?.weeklyPayout}
            percentage={data.percentageWeeklyPayout}
          />
          <ProgressCard
            name="Payout This Month"
            value={data?.monthlyPayOut}
            percentage={data.percentageMonthlyPayOut}
          />
        </div>

        <p className="text-xl font-bold mb-2 mt-5">Financial History</p>
        <CustomTable
          showSearch
          searchPlaceholder="Search history by Staff ID / Name / Payout / Site"
          loading={isLoading}
          data={financeHistory}
          columns={column}
        />
        {modals.active && (
          <>
            <div className="modal-background" onClick={hideModals}></div>
            {modals.invoice && (
              <InvoiceModal
                agencyID={user.agency.agencyID}
                showPayModal={() => {
                  hideModals();
                  // displayModal("pay");
                }}
              />
            )}
            {modals.payRoll && (
              <PayRollModal
                showPayModal={() => {
                  hideModals();
                  displayModal("pay");
                }}
              />
            )}
            {modals.pay && (
              <DeleteModal
                title="You are about to pay £20 to John James"
                yesHandler={() => {}}
                errorType={false}
                noHandler={hideModals}
              />
            )}
          </>
        )}
        {modals.sites && (
          <div className="fixed-modal bg-white rounded-md p-6 w-1/3">
            <AgencySites
              sites={sites}
              paymentSchedule={paymentSchedule}
              onSelect={(data) => {
                // displayModal("sites");
                // setSiteData(data);
                hideModals();
              }}
            />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Financials;
