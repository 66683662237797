import { Avatar } from "@mantine/core";
import { BackIcon } from "components/Svgs";

type HideModalProp = {
  hideModal: () => void;
  imageUrl: string;
};
const ProfileCard = ({ hideModal, imageUrl }: HideModalProp) => (
  <>
    <div
      className="fixed top-0 left-0 h-screen w-screen"
      onClick={hideModal}
    ></div>
    <div className="border-2 mx-auto my-auto sm:my-0 sm:mx-0 rounded-[15px] w-full sm:w-[400px] border-border pt-12 px-10 bg-white relative z-10">
      <div
        className="absolute top-12 left-9 cursor-pointer"
        onClick={hideModal}
      >
        <BackIcon />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="bg-text rounded-full w-[150px] h-[150px] mr-0">
          <Avatar size={150} src={imageUrl} alt="logo" radius={100} />
        </div>
        <p>Upload picture</p>
      </div>
      <form action="" className="w-full">
        <p>Name</p>
        <input type="text" name="" id="" required />
        <p>Role</p>
        <input type="text" required />
        <input className="bg-primary text-white" type="button" value="Done" />
      </form>
    </div>
  </>
);
export default ProfileCard;
