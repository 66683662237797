import { useState } from "react";
import { SearchIcon, MarkIcon } from "components/Svgs";

type AddModalProp = {
  hideModal?: () => void;
};
const AddStaffModal = ({ hideModal }: AddModalProp) => {
  const [showContacts, setShowContacts] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);
  const contacts = [1, 2, 3, 4, 5, 6, 7, 9, 8, 10, 11, 12, 13, 14, 15, 16];
  function addContact(num: number) {
    const numberExists = selected.find((contact) => contact === num);
    if (numberExists !== undefined) {
      const newNumbers = selected.filter((contact) => contact !== num);
      setSelected([...newNumbers]);
    } else {
      setSelected((prev) => [...prev, num]);
    }
  }
  function isSelected(index: number) {
    const contactExists = selected.find((contact) => contact === index);
    if (contactExists !== undefined) return true;
    return false;
  }
  return (
    <div className="fixed-modal flex flex-col bg-white h-[90vh] w-[700px] max-w-[95%] py-6 px-3 sm:px-10 rounded-xl">
      <div className="flex justify-between">
        <p className="font-semibold text-2xl mb-4">Staff</p>
        {selected.length > 0 && (
          <p className="text-white bg-primary h-8 flex items-center justify-center w-8 rounded-full">
            {selected.length}
          </p>
        )}
      </div>
      <div className="relative">
        <div className="absolute top-5 left-3">
          <SearchIcon />
        </div>
        <input
          type="text"
          name=""
          className="py-2 pl-10 w-full"
          placeholder="Search"
          id=""
        />
      </div>
      {!showContacts && (
        <p
          className="text-center font-semibold text-2xl mt-10"
          onClick={() => setShowContacts(true)}
        >
          {" "}
          Add staff to start chat
        </p>
      )}
      {showContacts && (
        <>
          <div className="grid grid-cols-2 sm:grid-cols-3 mb-4">
            <p className="font-semibold">Name</p>
            <div className="flex">
              <p className="font-semibold">Phone No.</p>
              <p className="font-semibold sm:hidden ml-1"> / Role</p>
            </div>
            <p className="font-semibold hidden sm:block">Role</p>
          </div>
          <div className="flex-1 overflow-y-auto">
            {contacts.map((contact, index) => {
              return (
                <div
                  className="grid grid-cols-2 gap-x-1 sm:grid-cols-3 items-center mb-4"
                  onClick={() => addContact(index)}
                >
                  <div className="flex items-center">
                    <div className="h-10 w-10 shrink-0 rounded-full bg-primary"></div>
                    <p className="block ml-2 text-sm">Andrew Garfield</p>
                  </div>
                  <div>
                    <p className="text-sm">+23498764</p>
                    <div className="flex sm:hidden items-center">
                      <p className="mr-auto text-sm">Accounts Manager</p>
                      <div className="-mt-2">
                        {isSelected(index) && <MarkIcon />}
                      </div>
                    </div>
                  </div>
                  <div className="hidden sm:flex">
                    <p className="mr-auto text-sm">Accounts Manager</p>
                    {isSelected(index) && <MarkIcon />}
                  </div>
                </div>
              );
            })}
          </div>
          <button
            onClick={hideModal}
            className="mt-3 px-28 py-3 rounded-lg text-white w-fit mx-auto"
          >
            Done
          </button>
        </>
      )}
    </div>
  );
};
export default AddStaffModal;
