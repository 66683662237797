import { useQuery, useQueryClient } from "@tanstack/react-query";
import DashboardLayout from "components/DashboardLayout";
import {
  addDays,
  endOfWeek,
  format,
  getWeek,
  startOfWeek,
  subDays,
} from "date-fns";
import { SelectSitePopover } from "pages/shift/ShiftComponents";
import { useRef, useState } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { siteRequest } from "services/request";
import { IUser } from "types";
import { getRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import CompletedShift from "./CompletedShift";
import ValidateShift from "./ValidatedShift";
import useRolePermissions from "hooks/useRolePermissions";
import GotoSettingsModal from "components/GoToSettingsModal";

const options: any = {
  weekStartsOn: 1,
};

const Validations = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;
  const { siteIDs, permissions } = useRolePermissions(user);
  const [currSite, setCurrSite] = useState<Record<string, any> | null>(null);
  const [sites, setSites] = useState<Record<string, any>>([]);
  const [start, setStart] = useState(startOfWeek(new Date(), options));
  const [end, setEnd] = useState(endOfWeek(new Date(), options));
  const shiftRange = useRef<{ start: Date; end: Date } | undefined>();
  const [activeTab, setActiveTab] = useState<string>("completed");

  const { isLoading } = useQuery(
    [queryKeys.allAgencySites],
    async () =>
      getRequest({ url: siteRequest.GET_AGENCY_SITES(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data: Array<Record<string, any>>) => {
        if (permissions.all) {
          setSites(data);
          setCurrSite(data[0]);
        } else {
          const filterSiteByPemission = data.filter((i) =>
            siteIDs.includes(i?.siteData?.siteID?.toString())
          );
          setSites(filterSiteByPemission);
          setCurrSite(filterSiteByPemission[0]);
        }
      },
    }
  );

  const handleChange = (args: "inc" | "dec") => {
    if (args === "inc") {
      const newStart = addDays(end, 1);
      const newEnd = addDays(end, 7);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      // refetch();
    }
    if (args === "dec") {
      const newStart = subDays(start, 7);
      const newEnd = subDays(start, 1);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      // refetch();
    }
  };

  return (
    <>
      <GotoSettingsModal visible={user.rulesSet} />
      <DashboardLayout>
        <div>
          <div className="flex items-center">
            <div className="flex items-center">
              <p className="ml-2">Shift Validations</p>
            </div>
            <div className="ml-auto flex cursor-pointer">
              <p className="mx-3">{`Week ${getWeek(start, {
                weekStartsOn: 1,
                firstWeekContainsDate: 4,
              })}`}</p>
              <div className="flex items-center mx-3">
                <span
                  className="cursor-pointer"
                  onClick={() => handleChange("dec")}
                >
                  <AiFillCaretLeft size={25} color="black" />
                </span>
                <p>{`${start.getDate()} - ${end.getDate()} ${format(
                  end,
                  "MMM"
                )}`}</p>
                <span
                  className="cursor-pointer"
                  onClick={() => handleChange("inc")}
                >
                  <AiFillCaretRight size={25} color="black" />
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center bg-green-100 px-4 my-3">
            <div className="flex items-center">
              <p className="text-[15px] mr-5 mt-1">
                You are currently viewing -{" "}
              </p>
              <div className="w-fit mb-2">
                {currSite && (
                  <SelectSitePopover
                    currSite={currSite}
                    allSites={sites}
                    setCurrSite={setCurrSite}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <p
                className={`cursor-pointer text-sm py-2 px-4 rounded ${
                  activeTab === "completed"
                    ? "bg-primary text-white"
                    : "bg-transparent text-black"
                }`}
                onClick={() => setActiveTab("completed")}
              >
                Completed Shift
              </p>
              <p
                className={`cursor-pointer text-sm py-2 px-4 rounded ${
                  activeTab === "validated"
                    ? "bg-primary text-white"
                    : "bg-transparent text-black"
                }`}
                onClick={() => setActiveTab("validated")}
              >
                Validated Shift
              </p>
            </div>
          </div>

          {activeTab === "completed" && (
            <CompletedShift
              {...{ shiftRange, start, end, currSite, user, isLoading }}
            />
          )}
          {activeTab === "validated" && (
            <ValidateShift {...{ isLoading, currSite }} />
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default Validations;
