import { useQuery } from "@tanstack/react-query";
import CustomTable from "components/CustomTable";
import { siteRequest } from "services/request";
import { getRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import { tableColumn } from "./model";

const ValidatedShift = ({ isLoading, currSite }) => {
  const { data = [], isLoading: isLoadingItem } = useQuery(
    [queryKeys.validatedShifts, currSite.siteData.siteID],
    async () =>
      getRequest({
        url: siteRequest.VALIDATED_SHIFT(currSite.siteData.siteID),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!currSite.siteData.siteID,
    }
  );

  return (
    <>
      {/* {isLoading ||
        (isLoadingItem && (
          <>
            <div className="w-screen h-screen fixed top-0 left-0 bg-text opacity-20 z-10"></div>
            <div className="fixed left-[50%] flex justify-center mt-5 z-50">
              <Loader size="lg" color="blue" />
            </div>
          </>
        ))} */}
      <div>
        <CustomTable
          loading={isLoading || isLoadingItem}
          data={data}
          columns={tableColumn}
        />
      </div>
    </>
  );
};

export default ValidatedShift;
