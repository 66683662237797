import { Button, createStyles } from "@mantine/core";

type DeleteModalProps = {
  title: string;
  subtitle?: string;
  errorType?: boolean;
  loading?: boolean;
  yesHandler: () => void;
  noHandler: () => void;
};

const useStyles = createStyles((theme) => ({
  outline: {
    backgroundColor: "#fff !important",
    color: "purple !important",
    border: "2px solid #8353F7",
    borderRadius: "0.375rem",
    padding: "5px 25px",
  },
  label: {
    color: "#8353F7 !important",
  },
}));

export const ConfirmationModal = ({
  title,
  subtitle,
  yesHandler,
  noHandler,
  loading,
  errorType = true,
}: DeleteModalProps) => {
  const { classes } = useStyles();
  return (
    <>
      <div
        className="w-screen h-screen fixed top-0 left-0 bg-text opacity-40 z-10"
        onClick={noHandler}
      ></div>
      <div className="fixed-modal bg-white rounded-md p-6">
        <p className={`${errorType && "text-red-500"}`}>{title}</p>
        {subtitle && <p className="text-sm mt-4 mb-5">{subtitle}</p>}
        <div className="flex justify-between mt-8 px-8 w-full mx-auto">
          <div className="mr-6 w-full">
            <Button onClick={yesHandler} loading={loading}>
              Yes
            </Button>
          </div>
          <div className="w-full">
            <Button
              onClick={noHandler}
              classNames={{
                root: classes.outline,
                label: classes.label,
              }}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
