import Logo from "images/logo.png";
import QRLogo from "images/qr_code_for_stores.png";

const InviteAgency = () => {
  return (
    <div className="h-screen bg-slate-200">
      <div className="w-3/4 mx-auto pt-20 flex flex-col justify-center items-center">
        <img src={Logo} alt="zshift logo" className="logo" />
        <h1 className="font-medium text-lg my-5">Invite to Agency</h1>
        <div className="bg-white rounded-xl my-2 py-6 px-7 w-1/2">
          <p className="text-center leading-7">
            To complete your onboarding process to the agency, please download
            and install the ZShift mobile application from your appropriate
            store below. <br /> Create an account using your email address,
            after which the invitation can be successfully accepted.
          </p>
          <div className="flex justify-center mt-3">
            <img src={QRLogo} alt="qr logo" height={350} width={350} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteAgency;
