import CustomTable from "components/CustomTable";

const ShiftDetails = ({ loading, data, column }) => {
  return (
    <div className="fixed-modal w-[90vw] h-[95vh] py-8 px-3">
      <CustomTable
        height="fit-content"
        loading={loading}
        data={data}
        columns={column}
      />
    </div>
  );
};

export default ShiftDetails;
