import { useState } from "react";
import { Button, Select, Switch } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ConfirmationModal } from "components/ConfirmationModal";
import useSendToAPI from "hooks/useSendToAPI";
import { successToast } from "hooks/useToast";
import { agencyRequest } from "services/request";
import { IUser } from "types";
import { getRequest, postRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";

interface IProps {
  user: IUser;
}

type TKey =
  | "pushAllowed"
  | "smsAllowed"
  | "enableEmail"
  | "shiftStart"
  | "shiftEnd"
  | "shiftAccepted"
  | "shiftRejected"
  | "shiftValidated"
  | "agencyInviteAccepted"
  | "agencyInviteRejected"
  | "agencyDigestDay";

const initialConfig = {
  pushAllowed: false,
  smsAllowed: false,
  // enableEmail: false,
  shiftStart: false,
  shiftEnd: false,
  shiftAccepted: false,
  shiftRejected: false,
  shiftValidated: false,
  agencyInviteAccepted: false,
  agencyInviteRejected: false,
};

const NotificationSettings = ({ user }: IProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [config, setConfig] = useState(initialConfig);
  const [agencyDigestDay, setAgencyDigestDay] = useState("Not Set");
  const [active, setActive] = useState("");

  const { isLoading, data, refetch } = useQuery(
    [queryKeys.notificationConfig],
    async () =>
      getRequest({
        url: agencyRequest.GET_NOTIFICATION_CONFIG(user.agency.agencyID),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        resetValues(data);
      },
    }
  );

  const resetValues = (data: any) => {
    setConfig({
      pushAllowed: data?.pushMessagesAllowed ?? false,
      smsAllowed: data?.smsallowed ?? false,
      // enableEmail: data?.emailsAllowed ?? false,
      shiftStart: data?.shiftStart ?? false,
      shiftEnd: data?.shiftEnd ?? false,
      shiftAccepted: data?.shiftAccepted ?? false,
      shiftRejected: data?.shiftRejected ?? false,
      shiftValidated: data?.shiftValidated ?? false,
      agencyInviteAccepted: data?.agencyInviteAccepted ?? false,
      agencyInviteRejected: data?.agencyInviteRejected ?? false,
    });
    setAgencyDigestDay(data?.agencyDigestDay || "Not Set");
  };

  const handleChange = (key: TKey, value: boolean | string) => {
    let title = value ? "enable" : "disable";
    if (key === "enableEmail") title = `${title} email`;
    if (key === "smsAllowed") title = `${title} sms`;
    if (key === "pushAllowed") title = `${title} push`;
    setActive(title);
    setConfig({
      ...config,
      [key]: value,
    });
    setShowConfirmation(true);
  };
  const onSuccess = (res) => {
    refetch();
    successToast(`Notification settings updated successfully`);
    setShowConfirmation(false);
  };

  const { mutate, isLoading: isSaving } = useSendToAPI(postRequest, onSuccess);

  const yesHandler = () => {
    const payload = {
      agencyID: user.agency.agencyID,
      // emailAllowed: config.enableEmail,
      // pushAllowed: config.pushAllowed,
      // smsAllowed: config.smsAllowed,
      ...config,
    };

    mutate({
      url: agencyRequest.UPDATE_NOTIFICATION_CONFIG,
      data: payload,
    });
  };

  const noHandler = () => {
    setShowConfirmation(false);
    resetValues(data);
  };

  return (
    <div>
      {showConfirmation && (
        <ConfirmationModal
          title={`Are you sure you want to ${active} notification?`}
          yesHandler={yesHandler}
          noHandler={noHandler}
          loading={isSaving}
        />
      )}
      <div className="border-b py-4 pl-6 mb-5">
        <p className="text-2xl hidden sm:block mb-4 font-semibold">
          Notification Settings
        </p>
        <span className="font-semibold">
          {/* Let us know how you want us to contact you */}
          Send me an email when...
        </span>
      </div>
      <div className="pl-6">
        <div className="w-full grid grid-cols-2 mb-4">
          <p className="-mt-[7px] mr-20 sm:mr-40">A shift is started</p>
          <Switch
            onLabel="YES"
            offLabel="NO"
            checked={config.shiftStart}
            onChange={(evt) =>
              handleChange("shiftStart", evt.currentTarget.checked)
            }
          />
        </div>
        <div className="w-full grid grid-cols-2 mb-4">
          <p className="-mt-[7px] mr-20 sm:mr-40">A shift is ended</p>
          <Switch
            onLabel="YES"
            offLabel="NO"
            checked={config.shiftEnd}
            onChange={(evt) =>
              handleChange("shiftEnd", evt.currentTarget.checked)
            }
          />
        </div>
        <div className="w-full grid grid-cols-2 mb-4">
          <p className="-mt-[7px] mr-20 sm:mr-40">A shift is accepted</p>
          <Switch
            onLabel="YES"
            offLabel="NO"
            checked={config.shiftAccepted}
            onChange={(evt) =>
              handleChange("shiftAccepted", evt.currentTarget.checked)
            }
          />
        </div>
        <div className="w-full grid grid-cols-2 mb-4">
          <p className="-mt-[7px] mr-20 sm:mr-40">A shift is rejected</p>
          <Switch
            onLabel="YES"
            offLabel="NO"
            checked={config.shiftRejected}
            onChange={(evt) =>
              handleChange("shiftRejected", evt.currentTarget.checked)
            }
          />
        </div>
        <div className="w-full grid grid-cols-2 mb-4">
          <p className="-mt-[7px] mr-20 sm:mr-40">A shift is validated</p>
          <Switch
            onLabel="YES"
            offLabel="NO"
            checked={config.shiftValidated}
            onChange={(evt) =>
              handleChange("shiftValidated", evt.currentTarget.checked)
            }
          />
        </div>
        <hr className="my-5" />
        <div className="grid grid-cols-2  mb-4">
          <p className=" -mt-[7px] mr-20 sm:mr-40 whitespace-nowrap">
            An agency invite is accepted
          </p>
          <div className="">
            <Switch
              onLabel="YES"
              offLabel="NO"
              checked={config.agencyInviteAccepted}
              onChange={(evt) =>
                handleChange("agencyInviteAccepted", evt.currentTarget.checked)
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-2 mb-4">
          <p className="-mt-[7px] mr-20 sm:mr-40 whitespace-nowrap">
            An agency invite is rejected
          </p>
          <Switch
            onLabel="YES"
            offLabel="NO"
            checked={config.agencyInviteRejected}
            onChange={(evt) =>
              handleChange("agencyInviteRejected", evt.currentTarget.checked)
            }
          />
        </div>
      </div>
      <div className="border-t py-4 pl-6 my-5">
        <span className="font-semibold">
          Override other settings with daily digest
        </span>
        <div className="flex items-center gap-3 mt-5">
          <Select
            className="w-fit"
            label="Select daily Digest"
            value={agencyDigestDay}
            data={[
              { label: "Not Set", value: "Not Set" },
              { label: "Monday", value: "1" },
              { label: "Tuesday", value: "2" },
              { label: "Wednesday", value: "3" },
              { label: "Thursday", value: "4" },
              { label: "Friday", value: "5" },
              { label: "Saturday", value: "6" },
              { label: "Sunday", value: "0" },
            ]}
            onChange={(value) => setAgencyDigestDay(value as string)}
          />
          <div className="mt-3">
            <Button
              className="!w-[160px]"
              loading={isLoading}
              onClick={() => handleChange("agencyDigestDay", agencyDigestDay)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
