import { Avatar, Button, Popover } from "@mantine/core";
import { IoIosArrowDown } from "react-icons/io";
import { nanoid } from "nanoid";
import AgencySites from "./AgencySites";
import AgencyStaff from "./AgencyStaff";
import { useClickOutside } from "@mantine/hooks";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { successToast } from "hooks/useToast";
import { queryKeys } from "utils/queryKeys";
import useSendToAPI from "hooks/useSendToAPI";
import { postRequest } from "utils/apiCalls";
import { siteRequest } from "services/request";
import { addDays, subDays, format } from "date-fns";
import { ConfirmationModal } from "components/ConfirmationModal";

export const AddShiftStaffPopover = ({
  allStaff,
  loading,
  siteStaffs,
  siteID,
  agencyRoles,
  setSiteStaffs,
}) => {
  const [opened, setOpened] = useState(false);
  const [currStaffIds, setCurrStaffIds] = useState<any>(siteStaffs);
  const ref = useClickOutside(() => setOpened(false));

  const handleSubmit = () => {
    setSiteStaffs(currStaffIds);
    onChange();
  };

  const onChange = () => {
    setOpened(!opened);
  };

  const staffNames = useMemo(() => {
    const staffs = siteStaffs.map((staffId) => {
      const currStaff = allStaff.find(
        (item) => item.user.userID?.toString() === staffId.toString()
      );
      const name = `${currStaff?.user?.userFirstname ?? ""} ${
        currStaff?.user?.userLastname ?? ""
      }`;
      return name.trim();
    });
    return staffs;
  }, [siteStaffs, allStaff]);

  return (
    <Popover
      trapFocus
      width={450}
      position="bottom-start"
      transition="pop"
      shadow="md"
      opened={opened}
    >
      <Popover.Target>
        <div className="w-full   mt-3 cursor-pointer">
          <div className=" mt-6">
            <p>Add Multiple Staff</p>
            <div
              className="w-full h-[50px] overflow-auto px-3 flex justify-between items-center rounded border-2 "
              onClick={onChange}
            >
              <p className="text-xs whitespace-nowrap">
                {staffNames.join(", ")}
              </p>
            </div>
          </div>
        </div>
      </Popover.Target>
      <Popover.Dropdown
        sx={(theme) => ({
          background:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          border: "1px solid #9FA0A1",
          borderRadius: "10px",
        })}
      >
        <div ref={ref}>
          <AgencyStaff
            allowSelectAll
            showCloseBtn
            onClose={onChange}
            siteID={siteID}
            loading={loading}
            agencyStaff={allStaff}
            siteStaff={siteStaffs}
            onSelect={setCurrStaffIds}
            currStaff={currStaffIds}
            agencyRoles={agencyRoles}
          />
          <Button onClick={handleSubmit}>Done</Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export const SelectSitePopover = ({ currSite, allSites, setCurrSite }) => {
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));

  const {
    siteData: { siteName, colorTag },
  } = currSite;

  const onChange = () => {
    setOpened(!opened);
  };

  return (
    <Popover
      trapFocus
      width={300}
      position="bottom-start"
      transition="pop"
      shadow="md"
      opened={opened}
    >
      <Popover.Target>
        <div
          className="w-full flex justify-between items-center mt-3 cursor-pointer"
          onClick={onChange}
        >
          <div className="flex items-center">
            <span
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: colorTag }}
            ></span>{" "}
            <p className="ml-2"> {siteName}</p>
          </div>
          <div className="ml-2">
            <IoIosArrowDown />
          </div>
        </div>
      </Popover.Target>
      <Popover.Dropdown
        sx={(theme) => ({
          background:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          border: "1px solid #9FA0A1",
          borderRadius: "10px",
        })}
      >
        <div ref={ref}>
          <AgencySites
            data={allSites}
            onSelect={(data) => {
              onChange();
              setCurrSite(data);
            }}
          />
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export const SelectStaffPopover = ({
  allStaff,
  loading,
  siteStaffs,
  siteID,
  agencyRoles,
}) => {
  const queryClient = useQueryClient();
  const [opened, setOpened] = useState(false);
  const [currStaffIds, setCurrStaffIds] = useState<any>([]);
  const ref = useClickOutside(() => setOpened(false));

  useEffect(() => {
    const ids = siteStaffs.map((i) => i.worker.userID);
    setCurrStaffIds(ids);
  }, [siteStaffs, opened]);

  const onSuccess = () => {
    successToast("Staff Added Successfully!");
    queryClient.invalidateQueries([queryKeys.siteWorkers]);
    setOpened(false);
  };

  const { mutate, isLoading: isSaving } = useSendToAPI(postRequest, onSuccess);

  const handleSubmit = () => {
    const payload = {
      siteWorkers: currStaffIds.map((id) => ({
        siteID: siteID.toString(),
        userID: id.toString(),
      })),
    };
    mutate({
      url: siteRequest.ADD_SITE_WORKER,
      data: payload,
    });
  };

  const onChange = () => {
    setOpened(!opened);
  };

  return (
    <Popover
      trapFocus
      width={450}
      position="bottom-start"
      transition="pop"
      shadow="md"
      opened={opened}
    >
      <Popover.Target>
        <div
          className="w-full flex justify-between items-center mt-3 cursor-pointer"
          onClick={onChange}
        >
          <div className="flex mt-6 pb-2 px-6">
            <p>Staff</p>
            <button className="p-0 outline-btn w-fit ml-3 text-xs px-6 py-1">
              Add Staff
            </button>
          </div>
        </div>
      </Popover.Target>
      <Popover.Dropdown
        sx={(theme) => ({
          background:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          border: "1px solid #9FA0A1",
          borderRadius: "10px",
        })}
      >
        <div ref={ref}>
          <AgencyStaff
            siteID={siteID}
            loading={loading}
            agencyStaff={allStaff}
            siteStaff={siteStaffs}
            onSelect={setCurrStaffIds}
            currStaff={currStaffIds}
            agencyRoles={agencyRoles}
          />
          <Button loading={isSaving} onClick={handleSubmit}>
            Done
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export const UserShiftsByWeek = ({
  onClick,
  users,
  currWeekShift = [],
  setCurrWeekShift,
  shiftStore,
  agencyId,
  siteId,
  roles,
}: any) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const queryClient = useQueryClient();
  const [tempShift, setTempShift] = useState({});
  const days = {
    0: "Mon",
    1: "Tue",
    2: "Wed",
    3: "Thu",
    4: "Fri",
    5: "Sat",
    6: "Sun",
  };
  // const days = {
  //   0: "Sun",
  //   1: "Mon",
  //   2: "Tue",
  //   3: "Wed",
  //   4: "Thu",
  //   5: "Fri",
  //   6: "Sat",
  // };
  // const tdays = {
  //   0: "Sun",
  //   1: "Mon",
  //   2: "Tue",
  //   3: "Wed",
  //   4: "Thu",
  //   5: "Fri",
  //   6: "Sat",
  // };

  const onSuccess = () => {
    successToast("Shift Created Successfully!");
    queryClient.invalidateQueries([queryKeys.currWeekShift, agencyId, siteId]);

    setShowConfirmation(false);
    setTempShift({});
  };
  const onError = () => {
    successToast("Error occured while creating shift!");
    setShowConfirmation(false);
    setTempShift({});
    setCurrWeekShift(shiftStore);
  };

  const { mutate, isLoading: isSubmitting } = useSendToAPI(
    postRequest,
    onSuccess,
    onError
  );

  const handleCreateShift = () => {
    const {
      startTime = "",
      endTime = "",
      breakAllowed,
      shiftRate,
      roleID,
      shiftNotes,
      shiftDate,
      userID,
    }: any = tempShift;
    const [hour, min] = startTime.split(":");
    const [endHour, endMin] = endTime.split(":");

    const payload = {
      shiftDaysAndUsers: [
        {
          shiftDates: [shiftDate],
          shiftDescription: shiftNotes,
          breakAllowed: breakAllowed,
          roleID,
          startTime: {
            hour,
            minute: min,
            second: "0",
            nano: "0",
          },
          endTime: {
            hour: endHour,
            minute: endMin,
            second: "0",
            nano: "0",
          },
          shiftRate,
          transferred: false,
          transferredFromID: "0",
          userID,
          siteID: siteId,
          agencyID: agencyId,
          sendNow: false,
        },
      ],
    };
    mutate({
      url: siteRequest.CREATE_SHIFT,
      data: payload,
    });
  };
  const getColorCode = (status) => {
    switch (status) {
      case "PENDING":
        return {
          border: "2px solid #F3B844",
        };
      case "ACCEPTED":
        return {
          border: "2px solid #71D342",
        };
      case "COMPLETED":
        return {
          backgroundColor: "#F0F0FF",
        };
      case "TRANSFERED":
        return {
          backgroundColor: "#FFE4F1",
        };
      case "IN_PROGRESS":
        return {
          backgroundColor: "#FFF7DA",
        };
      case "REJECTED":
        return {
          backgroundColor: "2px solid #FFE4F1",
        };
      case "CREATED":
      case "DRAFT":
        return {
          border: "2px solid #FF5C5C",
        };
      default:
        return {};
    }
  };

  const pasteCopiedCell = ({ shiftData = {}, shiftKey, userID }) => {
    // shiftKey - drag to position
    // key - drag from position
    const { key, data }: any = shiftData;
    let shiftDate: any = "";
    const diff = Number(shiftKey) - Number(key);
    if (diff >= 0) {
      shiftDate = addDays(new Date(data.shiftDate), diff);
      shiftDate = format(shiftDate, "yyyy-MM-dd");
    }
    if (diff < 0) {
      shiftDate = subDays(new Date(data.shiftDate), Math.abs(diff));
      shiftDate = format(shiftDate, "yyyy-MM-dd");
    }
    const newShift = {
      ...data,
      userID,
      shiftDate,
    };

    setTempShift(newShift);
    setCurrWeekShift([...currWeekShift, newShift]);
  };

  const renderShift = (index, user) => {
    // const availabilities: Array<any> = user.availabilities ?? [];
    return Object.entries(days).map(([key, value]) => {
      // let shiftData = currWeekShift.find(
      let shiftData = currWeekShift.filter(
        (i) =>
          user.worker.userID.toString() === i?.userID?.toString() &&
          new Date(i.shiftDate).toDateString().includes(value)
      );
      // shiftData = shiftData ?? null;
      const isArray = Array.isArray(shiftData);
      const noOfShifts = isArray ? shiftData.length : 0;
      const store = shiftData;
      shiftData = isArray ? shiftData[0] : null;
      const period = shiftData
        ? `${shiftData.startTime} - ${shiftData.endTime}`
        : "";
      const shiftStatus = shiftData?.shiftStatus ?? "";
      const roleId = shiftData ? shiftData.roleID : "";
      const userRole = roles.find(
        (role) => role.roleID.toString() === roleId.toString()
      );

      const styles = getColorCode(shiftStatus);

      // const isAvailable = availabilities.find(
      //   (item) => item.availability !== shiftData?.shiftDate
      // );
      // if (!isAvailable) {
      //   return (
      //     <div key={nanoid()} className="py-3 border-l border-r text-center">
      //       <p>Unavailable</p>
      //     </div>
      //   );
      // }
      return (
        <div
          key={nanoid()}
          className="py-3 border-l border-r  cursor-pointer hover:bg-purple-100 flex flex-col justify-center items-center"
          id={index}
          onClick={() => {
            // if (isArray && noOfShifts > 1) {
            //   console.log("ddd");
            // } else {
            // onClick({ shiftData, user, day: { key, value } });
            const modalData = isArray && noOfShifts > 1 ? store : shiftData;

            onClick({ shiftData: modalData, user, day: { key, value } });
            // }
          }}
          draggable="true"
          onDragStart={(event) => {
            event.dataTransfer.setData(
              "text",
              JSON.stringify({ data: shiftData, key })
            );
          }}
          onDragOver={(event) => {
            event.preventDefault();
          }}
          onDrop={(event) => {
            const data = event.dataTransfer.getData("text");
            const shift = JSON.parse(data);
            event.currentTarget.style.backgroundColor = "white";

            // if 'FROM' cell is not empty
            // if 'DESTINATION' cell is empty
            if (shift.data && !shiftData) {
              setShowConfirmation(true);
              pasteCopiedCell({
                shiftData: shift,
                userID: user.worker.userID,
                shiftKey: key,
              });
            }
          }}
          style={{ ...styles }}
        >
          <p className="text-xs text-bold">{period}</p>
          <p className="text-xs text-text my-1">
            {userRole?.roleName ?? roleId}
          </p>

          {isArray && noOfShifts > 1 ? (
            <p className="mt-1 text-xs text-green-500 text-right capitalize">
              View more
            </p>
          ) : (
            <p className="text-xs text-text capitalize">
              {shiftStatus.toLowerCase()}
            </p>
          )}
        </div>
      );
    });
  };

  // const renderShift = (index, user) => {
  //   let userShift = currWeekShift.filter(
  //     (i) => user.worker.userID.toString() === i?.userID?.toString()
  //   );
  //   // console.log({ userShift });

  //   // userShift = userShift;
  //   return userShift.map((shiftData) => {
  //     // return Object.entries(days).map(([key, value]) => {
  //     // return currWeekShift.map(() => {
  //     // let shiftData = currWeekShift.find(
  //     //   (i) =>
  //     //     user.worker.userID.toString() === i?.userID?.toString() &&
  //     //     new Date(i.shiftDate).toDateString().includes(value)
  //     // );
  //     // shiftData = shiftData ?? null;
  //     const period = shiftData
  //       ? `${shiftData.startTime} - ${shiftData.endTime}`
  //       : "";
  //     const roleId = shiftData ? shiftData.roleID : "";
  //     const shiftStatus = shiftData?.shiftStatus ?? "";
  //     const key = format(new Date(shiftData.shiftDate), "E");
  //     const value = tdays[key];
  //     // const [key, value] = [tdays]

  //     const styles = getColorCode(shiftStatus);
  //     return (
  //       <div
  //         key={nanoid()}
  //         className="py-3 border-l border-r pl-6 cursor-pointer hover:bg-purple-100"
  //         id={index}
  //         onClick={() => {
  //           onClick({ shiftData, user, day: { key, value } });
  //           console.log({ shiftData, user, day: { key, value } });
  //         }}
  //         draggable="true"
  //         onDragStart={(event) => {
  //           event.dataTransfer.setData(
  //             "text",
  //             JSON.stringify({ data: shiftData, key })
  //           );
  //         }}
  //         onDragOver={(event) => {
  //           event.preventDefault();
  //         }}
  //         onDrop={(event) => {
  //           const data = event.dataTransfer.getData("text");
  //           const shift = JSON.parse(data);
  //           event.currentTarget.style.backgroundColor = "white";

  //           // if 'FROM' cell is not empty
  //           // if 'DESTINATION' cell is empty
  //           if (shift.data && !shiftData) {
  //             setShowConfirmation(true);
  //             pasteCopiedCell({
  //               shiftData: shift,
  //               userID: user.worker.userID,
  //               shiftKey: key,
  //             });
  //           }
  //         }}
  //         style={{ ...styles }}
  //       >
  //         <p className="text-xs text-bold">{period}</p>
  //         <p className="text-xs text-text">{roleId}</p>
  //         <p className="text-xs text-text capitalize">
  //           {shiftStatus.toLowerCase()}
  //         </p>
  //       </div>
  //     );
  //   });
  // };

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          title=" Are you sure you want to copy this shift?"
          yesHandler={handleCreateShift}
          noHandler={() => {
            setCurrWeekShift(shiftStore);
            setShowConfirmation(false);
          }}
          loading={isSubmitting}
        />
      )}
      {users.length === 0 ? (
        <div className="grid grid-cols-5 border-b" key={nanoid()}>
          <p className="p-5 leading-5">Add staff to work on this site</p>
        </div>
      ) : (
        users.map((user, index) => (
          <div className="grid grid-cols-5 border-b" key={nanoid()}>
            <div className="flex items-center px-6 py-3 border-r">
              {/* <div className="h-10 w-10 rounded-full bg-primary"> */}
              <Avatar
                src={user.profilePicture}
                size={35}
                alt="user"
                radius="xl"
              />
              {/* </div> */}
              <div className="ml-2">
                <p className="text-xs font-semibold text-bold capitalize">{`${user.worker.userFirstname} ${user.worker.userLastname}`}</p>
                <span className="text-xs"> {user.worker.userID}</span>
              </div>
            </div>
            <div className="col-span-4 grid grid-cols-7 ">
              {renderShift(index, user)}
            </div>
          </div>
        ))
      )}
    </>
  );
};
