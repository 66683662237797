import { BackIcon } from "components/Svgs";

type GroupModalProp = {
  hideModal: () => void;
};
export const GroupModal = ({ hideModal }: GroupModalProp) => {
  return (
    <div className="fixed-modal bg-white w-[450px] max-w-[95%] relative px-6 sm:px-12 rounded-lg pt-9 pb-8">
      <div
        onClick={hideModal}
        className="absolute left-4 top-[43px] cursor-pointer"
      >
        <BackIcon />
      </div>
      <label htmlFor="image" className="cursor-pointer">
        <div className="h-28 w-28 mx-auto rounded-full bg-text"></div>
        <p className="text-center text-primary">Upload picture</p>
      </label>
      <input type="file" name="" className="hidden" id="image" />
      <p>Group Name</p>
      <input type="text" placeholder="name@example.com" />
      <p className="mb-3">Group Description</p>
      <textarea name="" id="" className="w-full"></textarea>
      <input type="button" value="Done" className="mt-3 cursor-pointer" />
    </div>
  );
};

export default GroupModal;
