import { useRef, useState } from "react";
import CreateSiteCard from "components/CreateSiteCard";
import { Month } from "@mantine/dates";
import ReportModal from "./ReportModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";
import { IUser } from "types";
import { tableColumn } from "./model";
import { getRequest, postRequest } from "utils/apiCalls";
import { agencyRequest, siteRequest } from "services/request";
import CustomTable from "components/CustomTable";
import AgencySites from "./AgencySites";
import { ISiteData } from "types";
import { createStyles, Loader, Select } from "@mantine/core";
import { successToast } from "hooks/useToast";
import ShiftDetails from "./ShiftDetails";
import {
  format,
  getYear,
  addYears,
  subYears,
  getMonth,
  startOfWeek,
  endOfWeek,
  formatDistanceToNow,
} from "date-fns";
import ShiftActionsModal from "./ShiftActionsModal";
// import useRolePermissions from "hooks/useRolePermissions";

type ShiftIndexProps = {
  sites: Array<any>;
  hideModals: () => void;
  displayModal: (name: string) => void;
  setView: (name: string) => void;
  modals: {
    site: boolean;
    active: boolean;
    shift: boolean;
    reports: boolean;
    editSite: boolean;
    shiftActions: boolean;
  };
};

const useStyles = createStyles((theme) => ({
  weekday: {
    fontSize: "15px",
    color: "#2D3436",
    textAlign: "left",
    borderBottom: "2px solid #DFE6E9",
    paddingBottom: "10px",
  },
  cell: {
    border: "1px solid #DFE6E9",
    borderTop: "none",
    textAlign: "left",
    width: "60px",
    height: "60px",
  },
  day: {
    verticalAlign: "top",
    fontSize: "16px",
    textAlign: "left",
    width: "100%",
    height: "100%",
    paddingLeft: "10px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&[data-selected='true']": {
      backgroundColor: "#fff",
      color: "#aaa",
      opacity: "0.8",
    },
  },
}));

const ShiftIndex = ({
  sites,
  hideModals,
  displayModal,
  modals,
  setView,
}: ShiftIndexProps) => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([queryKeys.user]) as IUser;
  // const { permissions } = useRolePermissions(user);
  const [currSiteData, setCurrSiteData] = useState<ISiteData>({} as ISiteData);
  const [currDate, setCurrDate] = useState("");
  const [year, setYear] = useState(getYear(new Date()).toString());
  const [month, setMonth] = useState((getMonth(new Date()) + 1).toString());
  const [start, setStart] = useState(startOfWeek(new Date()));
  const [end, setEnd] = useState(endOfWeek(new Date()));
  const [modalData, setModalData] = useState({});
  const shiftRange = useRef<{ start: Date; end: Date } | undefined>();

  const { classes } = useStyles();

  const { isLoading, data = [] } = useQuery(
    [queryKeys.shiftHistory],
    async () =>
      getRequest({ url: agencyRequest.SHIFT_HISTORY(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isLoadingShift, data: monthShift = [] } = useQuery(
    [queryKeys.yearMonthShift, user.agency.agencyID, month, year],
    async () =>
      postRequest({
        url: agencyRequest.GET_YEAR_MONTH_SHIFT({
          agencyId: user.agency.agencyID,
          month,
          year,
        }),
        data,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    isLoading: isLoadingReport,
    data: shiftReport = [],
    refetch,
  } = useQuery(
    [queryKeys.weeklyShiftReport, user.agency.agencyID, start, end],
    async () => {
      const startDate = shiftRange.current?.start
        ? format(shiftRange.current?.start, "yyyy-MM-dd")
        : format(start, "yyyy-MM-dd");
      const endDate = shiftRange.current?.end
        ? format(shiftRange.current?.end, "yyyy-MM-dd")
        : format(end, "yyyy-MM-dd");
      return postRequest({
        url: siteRequest.GET_REPORT_BY_DATE_RANGE({
          agencyId: user.agency.agencyID,
          startDate,
          endDate,
        }),
        data: {},
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const column = [
    ...tableColumn,
    {
      accessorFn: (row) => {
        return (
          <span
            className=" text-primary cursor-pointer text-[14px] capitalize"
            onClick={() => {
              setModalData(row);
              displayModal("shiftActions");
            }}
          >
            View
          </span>
        );
      },
      id: "action",
      header: "",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableSorting: false,
    },
  ];
  const monthData = [
    { value: "1", label: "Jan" },
    { value: "2", label: "Feb" },
    { value: "3", label: "Mar" },
    { value: "4", label: "Apr" },
    { value: "5", label: "May" },
    { value: "6", label: "Jun" },
    { value: "7", label: "Jul" },
    { value: "8", label: "Aug" },
    { value: "9", label: "Sept" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];
  const yearData = [
    {
      value: getYear(subYears(new Date(), 2)).toString(),
      label: getYear(subYears(new Date(), 2)).toString(),
    },
    {
      value: getYear(subYears(new Date(), 1)).toString(),
      label: getYear(subYears(new Date(), 1)).toString(),
    },
    {
      value: getYear(new Date()).toString(),
      label: getYear(new Date()).toString(),
    },
    {
      value: getYear(addYears(new Date(), 1)).toString(),
      label: getYear(addYears(new Date(), 1)).toString(),
    },
    {
      value: getYear(addYears(new Date(), 2)).toString(),
      label: getYear(addYears(new Date(), 2)).toString(),
    },
  ];

  // const test = `"${Array(3).fill("•").join("")}"`;
  const generateHex = () =>
    `#${Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padEnd(0, "0")}`;

  const formatMonth = () => {
    const currMonth = Number(month) - 1;
    return new Date(Number(year), currMonth, Number("01"));
  };

  return (
    <>
      {isLoadingShift && (
        <>
          <div className="w-screen h-screen fixed top-0 left-0 bg-text opacity-20 z-10"></div>
          <div className="fixed left-[50%] flex justify-center mt-5 z-50">
            <Loader size="lg" color="blue" />
          </div>
        </>
      )}
      <p className="text-2xl font-semibold">Shift</p>
      <div className="sm:grid grid-cols-4 gap-x-6">
        <div className="col-span-3 h-full flex flex-col">
          <div className="flex items-center justify-between">
            <p className="text-sm sm:text-lg">
              Click on a calendar to view full details
            </p>
            <button
              className="px-4 sm:px-16 w-fit text-xs sm:text-lg"
              onClick={() => {
                if (sites.length > 0) setView("create-shift");
                else successToast(`Please kindly add a site.`);
              }}
            >
              Create Rota
            </button>
          </div>
          <div className="border flex-1 rounded-xl grid grid-cols-5 p-3 mt-3">
            <div className="pr-3 border-r hidden sm:block">
              <p className="font-semibold">Sites</p>
              <div>
                <span>Click on site to edit site details</span>
                <div className="relative h-full">
                  <AgencySites
                    data={sites}
                    onSelect={(data) => {
                      displayModal("editSite");
                      setCurrSiteData(data);
                    }}
                  />
                  <button
                    onClick={() => displayModal("site")}
                    className="px-0 w-full block mt-auto text-sm"
                  >
                    Add Site
                  </button>
                </div>
              </div>
            </div>

            <div className="col-span-4">
              <div className="flex justify-end items-end">
                <div className="mr-3">
                  <Select
                    value={year}
                    onChange={(value) => setYear(value as string)}
                    data={yearData}
                  />
                </div>
                <div>
                  <Select
                    value={month}
                    onChange={(value) => setMonth(value as string)}
                    data={monthData}
                  />
                </div>
              </div>
              <Month
                value={monthShift.map((i) => new Date(i.shiftDate))}
                // month={new Date("2022-11-11")}
                month={formatMonth()}
                fullWidth={true}
                dayStyle={(day) => {
                  // console.log({ day });
                  return {
                    // backgroundColor: "red",
                    // "data-selected": {
                    //   position: "relative",
                    //   backgroundColor: "red",
                    //   "&::after": {
                    //     // content: "'•••'",
                    //     content: test,
                    //     color: "blue",
                    //     fontWeight: 30,
                    //     fontSize: 30,
                    //     position: "absolute",
                    //     right: 0,
                    //     left: 0,
                    //     top: 55,
                    //     bottom: 0,
                    //     zIndex: -1,
                    //   },
                    // },
                    // position: "relative",
                    // content: "@@@",
                    // color: "blue",
                  };
                }}
                onChange={(evt) => {
                  setCurrDate(format(evt, "yyyy-MM-dd"));
                  displayModal("shift");
                }}
                classNames={{
                  weekday: classes.weekday,
                  cell: classes.cell,
                  day: classes.day,
                }}
                styles={(theme) => ({
                  day: {
                    "&[data-selected]": {
                      position: "relative",
                      backgroundColor: "red",

                      "&::after": {
                        content: "'•••'",
                        // content: test,
                        // color: "blue",
                        color: generateHex(),
                        fontWeight: 30,
                        fontSize: 30,
                        position: "absolute",
                        right: 0,
                        left: 0,
                        top: 55,
                        bottom: 0,
                        zIndex: -1,
                      },
                    },
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="h-full flex flex-col">
          {/* {(permissions.all || permissions.timesheet) && ( */}
          <button
            onClick={() => {
              if (sites.length > 0) setView("timesheet");
              else successToast(`Please kindly add a site.`);
            }}
            className="outline-btn"
          >
            Timesheet
          </button>
          {/* )} */}
          <div className="border rounded-lg h-[100px] flex-1 mt-2 py-2 px-4">
            <p className="text-sm font-semibold mb-2 mt-1">Recent Reports</p>
            {[...shiftReport]
              .slice(0, 10)
              .map(({ report, site, shift, user }, idx) => (
                <div className="flex mb-2" key={idx}>
                  <div className="flex">
                    <p className="text-xs font-semibold capitalize">
                      {user.userFirstname} {user.userLastname}:{" "}
                      <span className="text-xs">
                        {`(${site?.siteName} ${shift.startTime} - ${shift.endTime}) ${report?.report}`}
                      </span>
                    </p>
                  </div>
                  <span className="text-xs ml-[2px] mt-auto mb-1 whitespace-nowrap">
                    {formatDistanceToNow(new Date(report?.createdAt))}
                  </span>
                </div>
              ))}
            <p
              onClick={() => displayModal("reports")}
              className="text-primary text-xs text-center font-semibold cursor-pointer"
            >
              View all
            </p>
          </div>
        </div>
      </div>

      <div>
        <p className="text-xl font-bold mb-2 mt-5">Shift History</p>
        <CustomTable
          showSearch
          searchPlaceholder="Search shift by Site / Name / Role / Status"
          loading={isLoading}
          data={data}
          columns={column}
        />
      </div>
      <div>
        {modals.active && (
          <>
            <div
              className="modal-background"
              onClick={() => hideModals()}
            ></div>
            {modals.site && (
              <CreateSiteCard
                hideModal={hideModals}
                agencyID={user.agency.agencyID}
              />
            )}
            {modals.editSite && (
              <CreateSiteCard
                edit
                data={currSiteData}
                hideModal={hideModals}
                agencyID={user.agency.agencyID}
              />
            )}
            {modals.reports && (
              <ReportModal
                {...{
                  data: shiftReport,
                  start,
                  end,
                  shiftRange,
                  setStart,
                  setEnd,
                  refetch,
                  isLoading: isLoadingReport,
                }}
              />
            )}
            {modals.shift && (
              <ShiftDetails
                column={tableColumn}
                loading={isLoadingShift}
                data={monthShift.filter((item) => item.shiftDate === currDate)}
              />
            )}
            {modals.shiftActions && (
              <ShiftActionsModal
                data={modalData}
                hideModal={hideModals}
                user={user}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ShiftIndex;
