import CustomTable from "components/CustomTable";
import { useState } from "react";
import { IRole, IUser } from "types";
import { rolesColumn } from "./model";
import UpdateRoleModal from "./UpdateRoleModal";
import useRolePermissions from "hooks/useRolePermissions";

type AddSubUserProps = {
  roles: Array<IRole>;
  isLoading: boolean;
  user: IUser;
};
const RoleSettings = ({ user, roles, isLoading }: AddSubUserProps) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const { permissions } = useRolePermissions(user);

  const column = [
    ...rolesColumn,
    {
      accessorFn: (row) => {
        return (
          <span
            className=" text-primary cursor-pointer text-[14px] capitalize"
            onClick={() => {
              setModalData(row);
              setShowModal(true);
            }}
          >
            Edit
          </span>
        );
      },
      id: "action",
      header: "",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableSorting: false,
    },
  ];

  const hideModals = () => {
    setShowModal(false);
    setModalData({});
  };

  return (
    <div className="h-full flex flex-col max-w-full sm:px-6 pt-6">
      {(permissions.all || permissions.createRoles) && (
        <button
          onClick={() => setShowModal(true)}
          className="w-fit ml-8 sm:ml-0 btn px-12 py-2 mb-6"
        >
          Add new role
        </button>
      )}

      <div>
        <CustomTable
          height="fit-content"
          loading={isLoading}
          data={roles}
          columns={column}
        />
      </div>
      {showModal && (
        <UpdateRoleModal
          hideModals={hideModals}
          modalData={modalData}
          user={user}
        />
      )}
    </div>
  );
};

export default RoleSettings;
