export const tableColumn = [
  {
    id: "workerName",
    header: "Worker Name",
    accessorFn: (row) => row?.workerName ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "shiftDate",
    header: "Shift Date",
    accessorFn: (row) => {
      const date = row?.validated?.shiftDate
        ? new Date(row?.validated?.shiftDate).toLocaleDateString()
        : "N/A";
      return date;
    },
    cell: (info) => <p className="text-[14px]">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    id: "shiftRate",
    header: "Shift Rate",
    accessorFn: (row) => row?.validated.shiftRate,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "startTime",
    header: "Start Time",
    accessorFn: (row) => row?.validated.startTime,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "endTime",
    header: "End Time",
    accessorFn: (row) => row?.validated.endTime,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "validatedHours",
    header: "Validated Hours",
    accessorFn: (row) => {
      const isInternalValidation = row?.validated.validatedInternally;
      const isValidatedWithWorked = row?.validated.validatedWithWorked;
      if (isInternalValidation) return row?.validated.finalInternalHours;
      else if (isValidatedWithWorked) return row?.validated.actualHours;
      else return row?.validated.bookedHours;
    },
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "payoutHour",
    header: "Payout Amount",
    accessorFn: (row) => {
      const isInternalValidation = row?.validated.validatedInternally;
      const isValidatedWithWorked = row?.validated.validatedWithWorked;
      if (isInternalValidation) return row?.validated.finalInternalPayout;
      else if (isValidatedWithWorked) return row?.validated.actualPayout;
      else return row?.validated.bookedPayout;
    },
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
];
