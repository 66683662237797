import { Divider, Modal } from "@mantine/core";
import { Link } from "react-router-dom";
import { PROTECTED_PATHS } from "routes";

const GotoSettingsModal = ({ visible }) => {
  const location = new URL(window.location.href);

  function handleLogout() {
    window.localStorage.clear();
    window.location.href = "/";
  }

  return (
    <Modal
      opened={!visible && location.pathname !== PROTECTED_PATHS.SETTINGS}
      onClose={() => {}}
      title="SET AGENCY CONFIGURATION"
      withCloseButton={false}
      size={550}
    >
      <Divider />
      <p className="mt-3">
        Kindly set your rota configuration to continue using the platform.
      </p>
      <p>
        If it has already be done, logout and login again to sync your agency
        settings.
      </p>
      <Link to="/settings">
        <p className="bg-primary text-white text-center p-2 mt-3 rounded">
          Go to settings
        </p>
      </Link>
      <p
        className="bg-white border-primary border text-primary text-center p-2 mt-3 rounded cursor-pointer"
        onClick={handleLogout}
      >
        Logout
      </p>
    </Modal>
  );
};

export default GotoSettingsModal;
