import { useEffect, useRef, useState } from "react";
import * as SockJS from "sockjs-client";
import * as Stomp from "@stomp/stompjs";
import { useQueryClient } from "@tanstack/react-query";
import { CompatClient } from "@stomp/stompjs";
import { queryKeys } from "utils/queryKeys";
import { IChannels } from "types";
import config from "config";
import chatAudioUrl from "audio/beyond-doubt-580.mp3";
import notificationSoundUrl from "audio/elegant-notification-sound.mp3";

interface IRes {
  connected: boolean;
  stompClient: CompatClient | null;
}
interface ISocket {
  userID: string | number;
  channels?: IChannels[];
}
export const useSocketConnection = ({ userID, channels }: ISocket): IRes => {
  const queryCache = useQueryClient();
  const [connected, setConnected] = useState(false);
  const [chatAudio] = useState(new Audio(chatAudioUrl));
  const [notificationAudio] = useState(new Audio(notificationSoundUrl));
  const stompClient: React.MutableRefObject<CompatClient | null> = useRef(null);
  const subscriptions = Array.isArray(channels) ? channels : [];

  const url = config.REACT_APP_WEB_SOCKET_URL;

  // subscribe to all the channels for the logged-in user
  useEffect(() => {
    stompClient.current = Stomp.Stomp.over(() => new SockJS(url));
    stompClient.current.connect({}, (frame) => {
      setConnected(true);
      subscriptions.forEach((channel) => {
        const channelPath = `${channel.channelPath}${channel.channelID}`;
        stompClient.current?.subscribe(channelPath, (message) => {
          let result = JSON.parse(message.body);
          if (result?.responseType === "chat" && result?.chatResponse) {
            result = result?.chatResponse;
            const isSender = userID?.toString() === result.senderID.toString();
            // PLAY SOUND IF THE CURRENT USER IS THE SENDER
            !isSender && chatAudio.play();
            saveNewChat(result, channel.channelID);
          }
          if (
            result?.responseType === "notify" &&
            result?.notificationResponse
          ) {
            result = result?.notificationResponse;
            notificationAudio.play();
            saveNewNotification(result);
          }
        });
      });
    });
    stompClient.current.debug = () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveNewChat = (data, channelID) => {
    let prevMsg: any = queryCache.getQueryData([queryKeys.chat]);
    prevMsg = prevMsg ?? {};
    const channelData = Array.isArray(prevMsg[channelID])
      ? prevMsg[channelID]
      : [];
    const newData = {
      ...prevMsg,
      [channelID]: [...channelData, data],
    };
    queryCache.setQueryData([queryKeys.chat], newData);
  };

  const saveNewNotification = (data) => {
    let oldData: any[] | undefined = queryCache.getQueryData([
      queryKeys.notification,
    ]);
    oldData = Array.isArray(oldData) ? oldData : [];
    queryCache.setQueryData([queryKeys.notification], [data, ...oldData]);
  };

  return {
    connected,
    stompClient: stompClient.current,
  };
};

export default useSocketConnection;
