import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, TextInput } from "@mantine/core";
import { PUBLIC_PATHS } from "routes";
import useSendToAPI from "hooks/useSendToAPI";
import { getRequest } from "utils/apiCalls";
import { authRequest } from "services/request";
import HeroImage from "images/hero2.png";
import Hero3 from "images/hero3.png";
import Logo from "images/logo.png";
import "styles/auth.css";

const PasswordSent = ({ email, isLoading, handleResend }) => (
  <main>
    <div className="hero-col">
      <div>
        <img src={Hero3} className="hero-image" alt="" />
        <p className="text-center">
          We’ll e-mail you instructions on how to reset your password.
        </p>
      </div>
    </div>
    <div className="w-1/2">
      <p className="section-title">Password Sent</p>
      <p>
        An email has been sent to{" "}
        <span className="text-primary font-semibold">{email}</span>. You’ll
        receive instructions on how to set a new password
      </p>
      <div className="w-full">
        <Button
          className="btn mb-2 w-full py-3 mt-4"
          disabled={isLoading}
          loading={isLoading}
          onClick={handleResend}
        >
          Resend Email
        </Button>
      </div>
      <Link to={PUBLIC_PATHS.LOGIN}>
        <span className="text-primary">Go back to Sign In</span>
      </Link>
    </div>
  </main>
);

const RecoverPassword = () => {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  const onSuccess = () => {
    setShow(true);
  };
  const { mutate, isLoading } = useSendToAPI(getRequest, onSuccess);

  const handleRequest = () => {
    mutate({
      url: `${authRequest.RESET_PASSWORD}?email=${email}&user=${false}`,
    });
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleRequest();
  };

  if (show) {
    return (
      <PasswordSent
        isLoading={isLoading}
        email={email}
        handleResend={handleRequest}
      />
    );
  }
  return (
    <main>
      <div className="hero-col">
        <div>
          <img src={HeroImage} className="hero-image" alt="" />
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <img src={Logo} className="logo" alt="" />
          <p className="section-title">Recover Password</p>
          <div className="mb-6">
            <TextInput
              size="lg"
              name="email"
              type="email"
              label="Email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="w-full">
            <Button type="submit" disabled={isLoading} loading={isLoading}>
              Reset Password
            </Button>
          </div>
          <Link to={PUBLIC_PATHS.LOGIN}>
            <span className="text-primary">Go back to Sign In</span>
          </Link>
        </form>
      </div>
    </main>
  );
};

export default RecoverPassword;
