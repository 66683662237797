import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useQuery } from "@tanstack/react-query";
import { addHours } from "date-fns";
import { queryKeys } from "utils/queryKeys";
import { agencyRequest } from "services/request";
import { useRegisterValidation } from "hooks/useValidation";
import { fetchifyRequest, postRequest } from "utils/apiCalls";
import useSendToAPI from "hooks/useSendToAPI";
import SignupForm from "./forms/SignupForm";
import HeroImage from "images/hero1.png";
import Logo from "images/logo.png";
import "styles/auth.css";
import { sessionInfo } from "utils/constants";

const SignUp = () => {
  const [postcode, setPostcode] = useState<string>("");
  const [geocode, setGeocode] = useState<Record<string, string>>({});
  const debouncedPostcode = useDebounce(postcode, 200);
  const [showSettingUp] = useState(false);

  const { isLoading, data } = useQuery(
    [queryKeys.listOfAddress, debouncedPostcode],
    async () => fetchifyRequest({ postcode: debouncedPostcode[0] }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(debouncedPostcode[0]),
      onSuccess: (data) => {
        setGeocode(data?.geocode ?? {});
      },
    }
  );
  const onSuccess = (res) => {
    window.localStorage.setItem(
      sessionInfo,
      JSON.stringify({ ...res, expiresIn: addHours(new Date(), 1) })
    );
    window.location.href = "/";
    // setShowSettingUp(true);
  };
  const { mutate, isLoading: loading } = useSendToAPI(postRequest, onSuccess);

  const handleSubmit = (data: any) => {
    const payload = {
      ...data,
      agencyLatitude: geocode?.lat ?? "",
      agencyLongitude: geocode?.lng ?? "",
    };
    delete payload.confirmPassword;
    mutate({ url: agencyRequest.SIGNUP, data: payload });
  };
  const form = useRegisterValidation();
  return (
    <>
      {!showSettingUp && (
        <main>
          <div className="hero-col">
            <div>
              <img src={HeroImage} className="hero-image" alt="" />
            </div>
          </div>
          <div>
            <SignupForm
              {...{
                form,
                data,
                postcode,
                setPostcode,
                isLoading,
                loading,
                handleSubmit,
              }}
            />
          </div>
        </main>
      )}
      {showSettingUp && (
        <div className="text-center">
          <img src={Logo} alt="" className="logo m-auto" />
          <p className="section-title my-5">Setting up your ZShift account</p>
          <br className="mb-6" />
          <img className="hero-image" src={HeroImage} alt="" />
        </div>
      )}
    </>
  );
};

export default SignUp;
