import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { HideModalProp } from "types";
import {
  Button,
  Center,
  Checkbox,
  Group,
  Input,
  Loader,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import {
  CancelIcon,
  DeleteIcon,
  DownTriangleIcon,
  UpTriangleIcon,
} from "components/Svgs";
import useSendToAPI from "hooks/useSendToAPI";
import { deleteRequest, postRequest } from "utils/apiCalls";
import { siteRequest } from "services/request";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";
import { addDays, eachDayOfInterval, format, isBefore } from "date-fns";
import { errorToast, successToast } from "hooks/useToast";
import { AddShiftStaffPopover } from "./ShiftComponents";
import { BsFileEarmarkPlusFill } from "react-icons/bs";
interface IProps extends HideModalProp {
  startDate: Date;
  isLoadingStaff: boolean;
  isLoadingRoles: boolean;
  agencyId: string | number;
  siteId: string | number;
  modalData: Record<string, any>;
  roles: Array<Record<string, any>>;
  allStaff: Array<Record<string, any>>;
  siteStaffs: Array<Record<string, any>>;
  agencyRoles: Array<Record<string, any>>;
  sendDraft: (args: number) => void;
  createShift: (args: Record<string, any>) => void;
  isSendingDraft: boolean;
}
const CreateShiftModal = ({
  hideModal,
  agencyId,
  siteId,
  roles,
  isLoadingRoles,
  modalData,
  allStaff,
  startDate,
  agencyRoles,
  createShift,
  sendDraft,
  isSendingDraft,
  isLoadingStaff,
}: IProps) => {
  const { user, day, shiftData } = modalData;

  const [rate, setRate] = useState("");
  const [breakAllowed, setBreakAllowed] = useState("30");
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [checkedToday, setCheckedToday] = useState(false);
  const [checkedNotif, setCheckedNotif] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [description, setDescription] = useState("");
  const [displayDate, setDisplayDate] = useState("");
  const [siteStaffs, setSiteStaffs] = useState([user.worker.userID]);
  const [role, setRole] = useState<string>("");
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (shiftData) {
      const {
        startTime = "",
        endTime = "",
        breakAllowed,
        shiftRate,
        roleID,
        shiftNotes,
      } = shiftData;
      const [hour, min] = startTime.split(":");
      const [endHour, endMin] = endTime.split(":");
      setStartHour(hour ?? 0);
      setStartMinute(min ?? 0);
      setEndHour(endHour ?? 0);
      setEndMinute(endMin ?? 0);
      setBreakAllowed(breakAllowed ?? "");
      setRate(shiftRate ?? "");
      setRole(roleID ?? "");
      setCheckedToday(true);
      setDescription(shiftNotes ?? "");
    }
  }, [shiftData]);

  const changeDate = (e: [Date, Date]) => {
    let displayString = "";
    e.forEach((date, index) => {
      if (date !== null) {
        displayString += new Date(date).toDateString();
      }
      if (index === 0) displayString += " - ";
    });
    if (e[1] !== null) setShowCalendar(false);
    setDisplayDate(displayString);
    setDate(e);
    setCheckedToday(false);
  };

  function startHourController(increase: Boolean) {
    if (increase) {
      setStartHour((prev) => (Number(prev) < 23 ? Number(prev) + 1 : 0));
    } else {
      setStartHour((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 23));
    }
  }

  function startMinuteController(increase: Boolean) {
    if (increase) {
      setStartMinute((prev) => (Number(prev) < 59 ? Number(prev) + 1 : 0));
    } else {
      setStartMinute((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 59));
    }
  }
  function endHourController(increase: Boolean) {
    if (increase) {
      setEndHour((prev) => (Number(prev) < 23 ? Number(prev) + 1 : 0));
    } else {
      setEndHour((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 23));
    }
  }
  function endMinuteController(increase: Boolean) {
    if (increase) {
      setEndMinute((prev) => (Number(prev) < 59 ? Number(prev) + 1 : 0));
    } else {
      setEndMinute((prev) => (Number(prev) > 0 ? Number(prev) - 1 : 59));
    }
  }

  const onSuccess = () => {
    successToast("Shift Created Successfully!");
    queryClient.invalidateQueries([queryKeys.currWeekShift, agencyId]);
    hideModal();
  };
  const onDeleteSuccess = () => {
    successToast("Shift Deleted Successfully!");
    queryClient.invalidateQueries([queryKeys.currWeekShift, agencyId]);
    hideModal();
  };
  const formatRange = (dates: Array<Date>) => {
    const intervals = eachDayOfInterval({
      start: new Date(dates[0] ?? ""),
      end: new Date(dates[1] ?? ""),
    });
    return intervals.map((date) => format(new Date(date), "yyyy-MM-dd"));
  };
  const formatDate = () => {
    const shiftDate = addDays(new Date(startDate), Number(day.key ?? 0));
    return [format(new Date(shiftDate), "yyyy-MM-dd")];
  };

  const { mutate, isLoading } = useSendToAPI(postRequest, onSuccess);
  const { mutate: onDeleteShift, isLoading: isLoadingShift } = useSendToAPI(
    deleteRequest,
    onDeleteSuccess
  );

  const handleSubmit = () => {
    if (role.length === 0) {
      errorToast("Role is empty");
      return;
    }
    const shiftDates = checkedToday
      ? formatDate()
      : formatRange(date as Array<Date>);

    const startTimeHour =
      startHour.toString().length === 1
        ? "0" + startHour.toString()
        : startHour.toString();
    const startTimeMinute =
      startMinute.toString().length === 1
        ? "0" + startMinute.toString()
        : startMinute.toString();
    const endTimeHour =
      endHour.toString().length === 1
        ? "0" + endHour.toString()
        : endHour.toString();
    const endTimeMinute =
      endMinute.toString().length === 1
        ? "0" + endMinute.toString()
        : endMinute.toString();

    const shiftDataFormat = {
      sendNow: checkedNotif,
      shiftDates,
      shiftDescription: description,
      breakAllowed: breakAllowed,
      roleID: role.toString(),
      startTime: {
        hour: startTimeHour,
        minute: startTimeMinute,
        second: "0",
        nano: "0",
      },
      endTime: {
        hour: endTimeHour,
        minute: endTimeMinute,
        second: "0",
        nano: "0",
      },
      shiftRate: rate,
      transferred: false,
      transferredFromID: "0",
      siteID: siteId,
      agencyID: agencyId,
    };
    const payload = {
      shiftDaysAndUsers: siteStaffs.map((userId) => ({
        ...shiftDataFormat,
        userID: userId,
      })),
    };
    if (!shiftData?.shiftDayID) {
      mutate({
        url: siteRequest.CREATE_SHIFT,
        data: payload,
      });
    }
  };

  const handleDelete = () => {
    const shiftDayID = shiftData?.shiftDayID;
    onDeleteShift({
      url: siteRequest.DELETE_SHIFT(shiftDayID),
    });
  };

  const formatedRoles = useMemo(() => {
    return roles.map(({ roleID, roleName }: any) => ({
      roleName,
      label: roleName,
      value: roleID.toString(),
    }));
  }, [roles]);

  const SelectItem = forwardRef<HTMLDivElement, any>(
    ({ roleName, roleID, ...others }: any, ref) => (
      <div ref={ref} key={roleID} {...others}>
        <Group noWrap>
          <div className="w-full flex justify-between items-center">
            <Text size="sm">{roleName}</Text>
          </div>
        </Group>
      </div>
    )
  );
  const Loadertem = forwardRef<HTMLDivElement, any>(
    ({ roleID, ...others }: any, ref) => (
      <div ref={ref} key={roleID} {...others}>
        <Center>
          <Loader size="sm" color="#aaa" />
        </Center>
      </div>
    )
  );

  const isNotAllowedToCreateShift = useCallback(() => {
    const shiftDate = addDays(new Date(startDate), Number(day.key ?? 0));
    const isToday = new Date(shiftDate).getDate() === new Date().getDate();
    if (isToday) return false;
    return isBefore(shiftDate, new Date());
  }, [startDate, day.key]);

  const renderShift = () => {
    return (
      <Fragment>
        <p className="mb-2">Time</p>
        <div className="flex justify-between items-center border-2 py-3 px-3 rounded-2xl">
          <p>Start</p>
          <div className="flex items-center">
            <div className="flex flex-col justify-center items-center">
              <div
                className="cursor-pointer"
                onClick={() => startHourController(true)}
              >
                <UpTriangleIcon />
              </div>
              <div className="my-2">
                <TextInput
                  classNames={{
                    root: "border-0 m-0 w-[60px]",
                    input: "!m-0",
                  }}
                  type="number"
                  value={("00" + startHour).slice(-2)}
                  onChange={(e) => {
                    const val = Number(e.target.value);
                    if (val >= 0 && val <= 23) {
                      setStartHour(val);
                    } else setStartHour(0);
                  }}
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => startHourController(false)}
              >
                <DownTriangleIcon />
              </div>
            </div>
            <p className="mx-2">:</p>
            <div className="flex flex-col justify-center items-center">
              <div
                className="cursor-pointer"
                onClick={() => startMinuteController(true)}
              >
                <UpTriangleIcon />
              </div>
              <div className="my-2">
                <TextInput
                  classNames={{
                    root: "border-0 m-0 w-[60px]",
                    input: "!m-0",
                  }}
                  type="number"
                  value={("00" + startMinute).slice(-2)}
                  onChange={(e) => {
                    const val = Number(e.target.value);
                    if (val >= 0 && val <= 59) {
                      setStartMinute(val);
                    } else setStartMinute(0);
                  }}
                />
              </div>
              {/* <span className="my-1">{("00" + startMinute).slice(-2)}</span> */}
              <div
                className="cursor-pointer"
                onClick={() => startMinuteController(false)}
              >
                <DownTriangleIcon />
              </div>
            </div>
          </div>
          <div className="h-1 w-4 bg-text"></div>
          <div className="flex items-center">
            <div className="flex flex-col justify-center items-center">
              <div
                className="cursor-pointer"
                onClick={() => endHourController(true)}
              >
                <UpTriangleIcon />
              </div>
              <div className="my-2">
                <TextInput
                  classNames={{
                    root: "border-0 m-0 w-[60px]",
                    input: "!m-0",
                  }}
                  type="number"
                  value={("00" + endHour).slice(-2)}
                  onChange={(e) => {
                    const val = Number(e.target.value);
                    if (val >= 0 && val <= 23) {
                      setEndHour(val);
                    } else setEndHour(0);
                  }}
                />
              </div>
              {/* <span className="my-1">{("00" + endHour).slice(-2)}</span> */}
              <div
                className="cursor-pointer"
                onClick={() => endHourController(false)}
              >
                <DownTriangleIcon />
              </div>
            </div>
            <p className="mx-2">:</p>
            <div className="flex flex-col justify-center items-center">
              <div
                className="cursor-pointer"
                onClick={() => endMinuteController(true)}
              >
                <UpTriangleIcon />
              </div>
              <div className="my-2">
                <TextInput
                  classNames={{
                    root: "border-0 m-0 w-[60px]",
                    input: "!m-0",
                  }}
                  type="number"
                  value={("00" + endMinute).slice(-2)}
                  onChange={(e) => {
                    const val = Number(e.target.value);
                    if (val >= 0 && val <= 59) {
                      setEndMinute(val);
                    } else setEndMinute(0);
                  }}
                />
              </div>
              {/* <span className="my-1">{("00" + endMinute).slice(-2)}</span> */}
              <div
                className="cursor-pointer"
                onClick={() => endMinuteController(false)}
              >
                <DownTriangleIcon />
              </div>
            </div>
          </div>
          <p>End</p>
        </div>
        <AddShiftStaffPopover
          allStaff={allStaff}
          loading={isLoadingStaff}
          siteStaffs={siteStaffs}
          setSiteStaffs={setSiteStaffs}
          siteID={siteId}
          agencyRoles={agencyRoles}
        />
        <p className="mt-4">Break</p>
        <div className="flex items-center">
          <div className="my-0 w-20 mb-0">
            <Input
              type="number"
              value={breakAllowed}
              onChange={(evt) => setBreakAllowed(evt.target.value)}
            />
          </div>
          <span className="ml-4 text-lg">mins</span>
        </div>
        {/* <p className="mt-4">Rate/hr</p>
        <TextInput
          size="lg"
          type="number"
          placeholder="Numbers only"
          value={rate}
          onChange={(evt) => setRate(evt.target.value)}
        /> */}
        <label className="label">Role</label>
        <Select
          value={role}
          placeholder="Select role"
          data={
            isLoadingRoles
              ? Array(1).fill({ label: "", value: "" })
              : formatedRoles
          }
          itemComponent={isLoadingRoles ? Loadertem : SelectItem}
          onChange={(value: string) => setRole(value)}
        />
        <p className="mt-4">Repeat</p>
        <p
          onClick={() => setShowCalendar(true)}
          className="py-2 cursor-pointer border-2 p-3 w-fit rounded-xl"
        >
          {displayDate === "" ? "Date Range" : displayDate}
        </p>
        {showCalendar && (
          <RangeCalendar
            value={date}
            onChange={changeDate}
            fullWidth={true}
            hideOutsideDates={true}
          />
        )}
        <div className="flex mt-2">
          <Checkbox
            checked={checkedToday}
            onChange={(event) => {
              setCheckedToday(event.currentTarget.checked);
              setDisplayDate("");
              setDate([null, null]);
            }}
          />
          <p className="ml-2">Set for this day only</p>
        </div>
        <div className="pt-5 pb-1">
          <Textarea
            label="Shift Description"
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
            minRows={4}
          />
        </div>
        <div className="flex mt-2">
          <Checkbox
            checked={checkedNotif}
            onChange={(event) => setCheckedNotif(event.currentTarget.checked)}
          />
          <p className="ml-2">Send shift notification to staff</p>
        </div>
        <div className="w-full flex mt-4">
          {shiftData?.shiftStatus === "DRAFT" ? (
            <Button
              loading={isLoading || isLoadingShift || isSendingDraft}
              onClick={() => sendDraft(shiftData?.shiftDayID)}
            >
              Send Notification
            </Button>
          ) : (
            <Button
              loading={isLoading || isLoadingShift}
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}

          {shiftData?.shiftDayID && (
            <>
              <div
                className=" ml-4 flex items-center cursor-pointer"
                onClick={() => createShift({ shiftData: undefined, user, day })}
              >
                <BsFileEarmarkPlusFill size="30px" color="green" />
              </div>
              <div
                className=" ml-4 flex items-center cursor-pointer"
                onClick={handleDelete}
              >
                <DeleteIcon />
              </div>
            </>
          )}
        </div>
      </Fragment>
    );
  };

  const renderCompletedShift = () => {
    return (
      <Fragment>
        <div className="flex">
          <h1 className="text-bold font-semibold my-5">SHIFT SUMMARY</h1>
          <div
            className=" ml-4 flex items-center cursor-pointer"
            onClick={() => createShift({ shiftData: undefined, user, day })}
          >
            <BsFileEarmarkPlusFill size="25px" color="green" />
          </div>
        </div>
        <div className="flex justify-between my-3">
          <p>Shift Date</p>
          <p>
            {shiftData?.shiftDate
              ? new Date(shiftData?.shiftDate).toLocaleDateString()
              : "--"}
          </p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Start Time</p>
          <p>{shiftData?.startTime}</p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>End Time</p>
          <p>{shiftData?.endTime}</p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Break Allowed</p>
          <p>{shiftData?.breakAllowed}</p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Rate</p>
          <p>{shiftData?.shiftRate}</p>
        </div>
        <hr />
        <div className="flex justify-between my-3">
          <p>Shift Status</p>
          <p className="capitalize">{shiftData?.shiftStatus?.toLowerCase()}</p>
        </div>
        <hr />
        <div className="my-3">
          <p>Shift Note</p>
          <p className="capitalize">{shiftData?.shiftNotes}</p>
        </div>
      </Fragment>
    );
  };

  const screenHeight = useCallback(() => {
    const isCompleted = shiftData?.shiftStatus === "COMPLETED";
    let height = "95vh";
    if (!shiftData && isNotAllowedToCreateShift()) {
      height = "fit-content";
    }
    if (isCompleted) {
      height = "fit-content";
    }
    return height;
  }, [shiftData, isNotAllowedToCreateShift]);

  const isCompleted = shiftData?.shiftStatus === "COMPLETED";
  const renderData = () => {
    if (!shiftData && isNotAllowedToCreateShift()) {
      return (
        <div>
          <h1 className="text-bold font-semibold my-5">
            You are not allowed to create shift for past date.
          </h1>
        </div>
      );
    } else return isCompleted ? renderCompletedShift() : renderShift();
  };

  return (
    <>
      <div
        className="w-screen h-screen fixed top-0 left-0 bg-text opacity-40 z-20"
        onClick={hideModal}
      ></div>
      <div
        className={
          "fixed-modal w-[500px] max-w-[95vw] px-10 overflow-auto py-6 relative "
        }
        style={{
          // height: !shiftData || isCompleted ? "fit-content" : "95vh",
          height: screenHeight(),
          // zIndex: 20000,
        }}
      >
        <div
          className="absolute top-5 cursor-pointer right-3"
          onClick={hideModal}
        >
          <CancelIcon />
        </div>
        {renderData()}
        {/* {isCompleted ? renderCompletedShift() : renderShift()} */}
      </div>
    </>
  );
};

export default CreateShiftModal;
