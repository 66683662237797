import { useEffect, useState } from "react";
import { IUser } from "types";
import { allPermissions } from "utils/constants";

const useRolePermissions = (user: IUser) => {
  const [permissions, setPermissions] = useState({
    all: false,
    validations: false,
    shifts: false,
    staff: false,
    financials: false,
    editAgencyInfo: false,
    createUser: false,
    createRoles: false,
    activityLog: false,
    timesheet: false,
  });
  const [siteIDs, setSiteIDs] = useState<string[]>([]);

  useEffect(() => {
    const temp = {};
    // const tempIds: string[] = [];
    const format = user?.role.rolePermissions?.split(",") ?? [];
    format.forEach((i: string) => {
      if (i.includes("all")) {
        temp["all"] = true;
      } else {
        if (i.includes(allPermissions.validations)) {
          // const curr = i.replace("[", "").split(":");

          // const ids = JSON.parse(curr[1]);
          // setSiteIDs(ids);
          // tempIds.push(ids?.toString());
          //   setSiteIDs([...siteIDs, ids]);
          temp[allPermissions.validations] = true;
        }
        // if (i.includes("]")) {
        //   const curr = i.replace("]", "");
        //   //   const ids = JSON.parse(curr[1]);
        //   //   setSiteIDs([...siteIDs, curr]);
        //   tempIds.push(curr?.toString());
        // }
        if (i.includes(allPermissions.shifts)) {
          temp[allPermissions.shifts] = true;
        }
        if (i.includes(allPermissions.staff)) {
          temp[allPermissions.staff] = true;
        }
        if (i.includes(allPermissions.financials)) {
          temp[allPermissions.financials] = true;
        }
        if (i.includes(allPermissions.editAgencyInfo)) {
          temp[allPermissions.editAgencyInfo] = true;
        }
        if (i.includes(allPermissions.createUser)) {
          temp[allPermissions.createUser] = true;
        }
        if (i.includes(allPermissions.createRoles)) {
          temp[allPermissions.createRoles] = true;
        }
        if (i.includes(allPermissions.activityLog)) {
          temp[allPermissions.activityLog] = true;
        }
        if (i.includes(allPermissions.timesheet)) {
          temp[allPermissions.timesheet] = true;
        }
      }
    });
    setPermissions(temp as typeof permissions);
    const siteValidationids = user?.role.siteValidationIDs?.split(",") ?? [];
    setSiteIDs(siteValidationids);
    // console.log(format, siteIDs);
  }, [user]);

  return {
    permissions,
    siteIDs,
  };
};

export default useRolePermissions;
