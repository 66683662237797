import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Rating, Select, Switch } from "@mantine/core";
import style from "styles/Shift.module.css";
import Logo from "images/logo.png";
import Hero from "images/hero5.png";
import { queryKeys } from "utils/queryKeys";
import { getRequest, postRequest } from "utils/apiCalls";
import { agencyRequest } from "services/request";
import useSendToAPI from "hooks/useSendToAPI";
import { successToast } from "hooks/useToast";
import MapScreen from "pages/home/MapScreen";

const ValidateShift = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const navigate = useNavigate();
  const [booked, setBooked] = useState("true");
  const [rating, setRating] = useState("2.5");
  const [assessment, setAssessment] = useState({
    row1: "2",
    row2: "2",
    row3: "2",
    row4: "2",
    row5: "2",
    row6: "2",
  });

  const location = new URL(window.location.href);
  const shiftDayID = location.searchParams.get("shiftDayID");
  const token = location.searchParams.get("token");
  const email = location.searchParams.get("email");

  const { data = {} } = useQuery(
    [queryKeys.shiftSummary],
    async () =>
      getRequest({
        url: agencyRequest.SHIFT_SUMMARY(shiftDayID),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!shiftDayID,
    }
  );

  const handleAssessment = (key, value) => {
    setAssessment({ ...assessment, [key]: value });
  };

  const onSuccess = () => {
    successToast("Successfully validated shift!");
    navigate("/");
  };

  const { mutate, isLoading: isSubmitting } = useSendToAPI(
    postRequest,
    onSuccess
  );

  const handleSubmit = () => {
    const payload = {
      validator: {
        token,
        isCalculatedWithWorked: booked,
        shiftDayID: shiftDayID,
        userRating: rating,
        validatedBy: email,
      },
      assessment,
    };
    mutate({
      url: agencyRequest.VALIDATE_SHIFT,
      data: payload,
    });
  };

  const hideModal = () => setShowModal(false);
  const handleModal = (data) => {
    setShowModal(true);
    setModalData(data);
  };

  return (
    <>
      {showModal && <SiteModal hideModal={hideModal} data={modalData} />}
      <div className="bg-[#FAFAFF]">
        <div className={style.container}>
          <img src={Logo} className={style.logo} alt="logo" />
          <img src={Hero} alt="hero" />
          <div className="border-b-[.5px] border-[#8353F7] pb-7 px-14">
            <h1 className="text-[24px] text-center">Validate Shift</h1>
            <p className="text-[16px] text-center">
              Please validate <strong>{data?.shiftWorker?.trim()}’s</strong>{" "}
              shift at {data?.site}
            </p>
          </div>
          <div className={style.shiftTable}>
            <div className="flex justify-between mt-3 mb-0 mx-5">
              <p className={`${style.text} mt-3 `}>
                Shift Date - {data?.shiftDate?.day}/{data?.shiftDate?.month}/
                {data?.shiftDate?.year}
              </p>
              <Select
                placeholder="Pick one"
                defaultValue={booked}
                onChange={(value) => setBooked(value ?? "")}
                data={[
                  { label: "Actual hours worked", value: "true" },
                  { label: "Hours booked", value: "false" },
                ]}
              />
            </div>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className={style.td}>
                    Expected <br /> Start Time
                  </td>
                  <td className={style.td}>
                    Expected <br /> End Time
                  </td>
                  <td className={style.td}>
                    Actual <br /> Start Time
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    Actual <br /> End Time
                  </td>
                </tr>
                <tr>
                  <td className={style.td}>
                    {data?.expectedStartTime?.hour}:
                    {data?.expectedStartTime?.minute}
                  </td>
                  <td className={style.td}>
                    {data?.expectedEndTime?.hour}:
                    {data?.expectedEndTime?.minute}
                  </td>
                  <td className={style.td}>
                    {data?.actualStartTime?.time?.hour}:
                    {data?.actualStartTime?.time?.minute}
                    <span
                      className={style.view}
                      onClick={() => {
                        const [lat, long] = data?.startedAt?.split(",");
                        handleModal({
                          site: {
                            siteLatitude: Number(lat),
                            siteLongitude: Number(long),
                            siteName: data?.site,
                          },
                          user: {
                            userFirstname: data?.shiftWorker,
                            userLastname: "",
                          },
                        });
                      }}
                    >
                      View
                    </span>
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    {data?.actualEndTime?.time?.hour}:
                    {data?.actualEndTime?.time?.minute}
                    <span
                      className={style.view}
                      onClick={() => {
                        const [lat, long] = data?.endedAt?.split(",");
                        handleModal({
                          site: {
                            siteLatitude: Number(lat),
                            siteLongitude: Number(long),
                            siteName: data?.site,
                          },
                          user: {
                            userFirstname: data?.shiftWorker,
                            userLastname: "",
                          },
                        });
                      }}
                    >
                      View
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot className="border-b">
                <tr>
                  <td className={style.td} colSpan={2}>
                    Total hours booked - {data?.expectedhours}
                  </td>

                  <td className={`${style.td} ${style.last}`} colSpan={2}>
                    Actual hours worked - {data?.actualHours}
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className="ml-5 py-8 flex">
              <p className={`${style.text} mr-14`}>Rate = {data?.shiftRate}</p>
              <p className={style.text}>
                Gross Earnings =
                {booked === "true"
                  ? data?.actualEarnings
                  : data?.expectedEarnings}
              </p>
            </div>
          </div>
          <div className={style.rating}>
            <h5 className="mb-3">How will you Rate {data?.shiftWorker}</h5>
            <Rating
              size="xl"
              fractions={2}
              defaultValue={Number(rating)}
              onChange={(value) => setRating(value.toString())}
            />
          </div>
          <div className={style.shiftTable}>
            <p className={style.candidate}>
              Candidate Assessment – Please Complete
            </p>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className={style.td}></td>
                  <td className={style.td}>Excellent</td>
                  <td className={style.td}>Good</td>
                  <td className={style.td}>Satisfactory</td>
                  <td className={`${style.td} ${style.last}`}>Poor</td>
                </tr>
                <tr>
                  <td className={style.tdd}>Clinical Knowledge</td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row1 === "0"}
                      onChange={() => handleAssessment("row1", "0")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row1 === "1"}
                      onChange={() => handleAssessment("row1", "1")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row1 === "2"}
                      onChange={() => handleAssessment("row1", "2")}
                    />
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    <Switch
                      checked={assessment.row1 === "3"}
                      onChange={() => handleAssessment("row1", "3")}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={style.tdd}>Attitude</td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row2 === "0"}
                      onChange={() => handleAssessment("row2", "0")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row2 === "1"}
                      onChange={() => handleAssessment("row2", "1")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row2 === "2"}
                      onChange={() => handleAssessment("row2", "2")}
                    />
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    <Switch
                      checked={assessment.row2 === "3"}
                      onChange={() => handleAssessment("row2", "3")}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={style.tdd}>Timekeeping</td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row3 === "0"}
                      onChange={() => handleAssessment("row3", "0")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row3 === "1"}
                      onChange={() => handleAssessment("row3", "1")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row3 === "2"}
                      onChange={() => handleAssessment("row3", "2")}
                    />
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    <Switch
                      checked={assessment.row3 === "3"}
                      onChange={() => handleAssessment("row3", "3")}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={style.tdd}>Relationships with Colleagues</td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row4 === "0"}
                      onChange={() => handleAssessment("row4", "0")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row4 === "1"}
                      onChange={() => handleAssessment("row4", "1")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row4 === "2"}
                      onChange={() => handleAssessment("row4", "2")}
                    />
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    <Switch
                      checked={assessment.row4 === "3"}
                      onChange={() => handleAssessment("row4", "3")}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={style.tdd}>Relationships with Patients</td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row5 === "0"}
                      onChange={() => handleAssessment("row5", "0")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row5 === "1"}
                      onChange={() => handleAssessment("row5", "1")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row5 === "2"}
                      onChange={() => handleAssessment("row5", "2")}
                    />
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    <Switch
                      checked={assessment.row5 === "3"}
                      onChange={() => handleAssessment("row5", "3")}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={style.tdd}>Communication Skills</td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row6 === "0"}
                      onChange={() => handleAssessment("row6", "0")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row6 === "1"}
                      onChange={() => handleAssessment("row6", "1")}
                    />
                  </td>
                  <td className={style.td}>
                    <Switch
                      checked={assessment.row6 === "2"}
                      onChange={() => handleAssessment("row6", "2")}
                    />
                  </td>
                  <td className={`${style.td} ${style.last}`}>
                    <Switch
                      checked={assessment.row6 === "3"}
                      onChange={() => handleAssessment("row6", "3")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-[150px]">
            <Button loading={isSubmitting} onClick={handleSubmit}>
              Done
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const SiteModal = ({ hideModal, data }) => {
  return (
    <>
      <div
        className="w-screen h-screen fixed top-0 left-0 bg-text opacity-40 z-10"
        onClick={hideModal}
      ></div>
      <div className="fixed bg-white z-20 h-1/2 w-1/2 translate-x-1/2 translate-y-1/2">
        <MapScreen siteMap={[data]} />
      </div>
    </>
  );
};

export default ValidateShift;
