import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Badge, Divider, Skeleton } from "@mantine/core";
import Logo from "images/logo.png";
import { getRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import { agencyRequest } from "services/request";

const Loader = () => {
  return (
    <div className="bg-white rounded my-2 p-4 w-1/2">
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} width="70%" radius="xl" />
      <Skeleton height={8} mt={6} width="60%" radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
    </div>
  );
};

const PaymentSuccess = () => {
  const location = new URL(window.location.href);
  const sessionID = location.searchParams.get("sessionID");

  const { isLoading, data = {} } = useQuery(
    [queryKeys.paymentSummary],
    async () => getRequest({ url: agencyRequest.PAYMENT_SUCCESS(sessionID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="h-screen bg-slate-200">
      <div className="w-1/2 mx-auto pt-20 flex flex-col justify-center items-center">
        <img src={Logo} alt="zshift logo" className="logo" />

        <h1 className="font-medium text-lg my-5">Payment Summary</h1>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="bg-white rounded my-2 p-4 w-1/2 min-w-[400px]">
            <p className="text-center leading-6">
              Your payment has completed successfully. <br /> A receipt has been
              sent to your email on file, for your records. <br />
            </p>
            <Divider
              mt="md"
              mb="xs"
              variant="dashed"
              labelPosition="center"
              label="Summary"
            />
            <div>
              <p className="text-[16px]">
                Amount Paid:{" "}
                <span className="text-[18px] text-black font-medium">
                  £ {data?.amount}
                </span>
              </p>
              <div className="flex items-center">
                <p className="text-[16px]">Payment Status: </p>
                <Badge color="teal" variant="outline">
                  <span className="text-sm text-black font-medium">
                    {data?.sessionStatus}
                  </span>
                </Badge>
              </div>
            </div>
            <Link to="/">
              <p className="bg-primary text-white text-center p-1 mt-3 rounded">
                View Dashboard
              </p>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
