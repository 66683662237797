import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import useGraphSummary from "hooks/useGraphSummary";

type BarChartProps = {
  showMonth: string;
  currYear: string;
  data: any;
};

const monthsDropDown = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BarChart = ({ showMonth, currYear, data }: BarChartProps) => {
  const result = useGraphSummary(data, currYear, showMonth);

  const weeks = ["Week 1", "Week 2", "Week 3", "Week 4"];
  const [barWidth, setBarWidth] = useState({
    month: 15,
    week: 35,
  });
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setBarWidth({
        month: 8,
        week: 10,
      });
    }
  }, []);

  const chart = {
    labels: showMonth === "All" ? monthsDropDown : weeks,
    backgroundColor: "blue",
    datasets: [
      {
        label: "Hours Booked",
        data: result.booked,
        backgroundColor: ["#F0F0FF"],
        barThickness: showMonth === "All" ? barWidth.month : barWidth.week,
        borderColor: ["#F0F0FF"],
        borderWidth: 1,
        borderRadius: 5,
      },
      {
        label: "Hours Worked",
        data: result.worked,
        backgroundColor: ["#9091FE"],
        barThickness: showMonth === "All" ? barWidth.month : barWidth.week,
        borderColor: ["#9091FE"],
        borderWidth: 1,
        borderRadius: 5,
      },
    ],
  };
  const scales = {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
    y: {
      ticks: {
        callback: (context: number | string, index: number) => {
          return index * 100;
        },
      },
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
  };

  return <Bar data={chart} options={{ scales: scales }} />;
};

export default BarChart;
