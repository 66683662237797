import { useCallback, useRef, useState } from "react";
import { nanoid } from "nanoid";
import { useQuery } from "@tanstack/react-query";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { Loader } from "@mantine/core";
import {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  format,
  getWeek,
  startOfWeek,
  subDays,
} from "date-fns";
import { BackIcon } from "components/Svgs";
import { ISiteData, IUser } from "types";
import { queryKeys } from "utils/queryKeys";
import { fetchPDFStream, getRequest, postRequest } from "utils/apiCalls";
import { agencyRequest, siteRequest } from "services/request";
import CreateShiftModal from "./CreateShiftModal";
import useSendToAPI from "hooks/useSendToAPI";
import { errorToast, successToast } from "hooks/useToast";
import {
  SelectSitePopover,
  SelectStaffPopover,
  UserShiftsByWeek,
} from "./ShiftComponents";
import MultipleShiftModal from "./MutlipleShiftModal";

type IProp = {
  user: IUser;
  sites: Array<ISiteData>;
  setView: () => void;
};

const options: any = {
  weekStartsOn: 1,
};

const CreateShift = ({ user, sites, setView }: IProp) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<Record<string, unknown>>({});
  const [currSite, setCurrSite] = useState(sites?.[0] ?? []);
  const [currWeekShift, setCurrWeekShift] = useState([]);
  const [start, setStart] = useState(startOfWeek(new Date(), options));
  const [end, setEnd] = useState(endOfWeek(new Date(), options));
  const shiftRange = useRef<{ start: Date; end: Date } | undefined>();

  const {
    isFetching: isFetchingShift,
    data: shiftStore,
    refetch,
  } = useQuery(
    [
      queryKeys.currWeekShift,
      currSite.siteData.agencyID,
      currSite.siteData.siteID,
    ],
    async () => {
      const startDate = shiftRange.current?.start
        ? format(shiftRange.current?.start, "yyyy-MM-dd")
        : format(start, "yyyy-MM-dd");
      const endDate = shiftRange.current?.end
        ? format(shiftRange.current?.end, "yyyy-MM-dd")
        : format(end, "yyyy-MM-dd");
      return postRequest({
        url: siteRequest.GET_SHIFT_BY_DATE_RANGE,
        data: {
          agencyID: currSite.siteData.agencyID,
          siteID: currSite.siteData.siteID,
          startDate,
          endDate,
        },
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!currSite.siteData.agencyID,
      onSuccess: (data) => {
        setCurrWeekShift(data);
      },
    }
  );

  const { isLoading, data = [] } = useQuery(
    [queryKeys.allStaffs],
    async () =>
      getRequest({ url: agencyRequest.ALL_STAFFS(currSite.siteData.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!currSite.siteData.agencyID,
    }
  );

  const { isLoading: isLoadingRoles, data: agencyRoles = [] } = useQuery(
    [queryKeys.agencyRoles],
    async () =>
      getRequest({
        url: agencyRequest.GET_AGENCY_ROLES(currSite.siteData.agencyID),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    isLoading: isLoadingWorkers,
    isFetching,
    data: siteStaffs = [],
  } = useQuery(
    [queryKeys.siteWorkers, currSite.siteData.siteID],
    async () =>
      getRequest({
        url: siteRequest.GET_SITE_WORKER(currSite.siteData.siteID),
      }),
    {
      enabled: !!currSite.siteData.siteID,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const hideModal = useCallback(() => {
    setModal(false);
    setModalData({});
  }, []);

  const handleChange = (args: "inc" | "dec") => {
    if (args === "inc") {
      const newStart = addDays(end, 1);
      const newEnd = addDays(end, 7);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      refetch();
    }
    if (args === "dec") {
      const newStart = subDays(start, 7);
      const newEnd = subDays(start, 1);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      refetch();
    }
  };

  const onSuccess = (res) => {
    if (res) {
      successToast(`Successfully sent draft`);
      refetch();
      hideModal();
    } else {
      errorToast("Error occurred!, Pls try again");
    }
  };

  const onError = () => {
    errorToast("Error occurred!, Pls try again");
  };

  const { mutate, isLoading: isDownload } = useSendToAPI(
    postRequest,
    onSuccess,
    onError
  );

  const handleSendDraft = (id?: number) => {
    const data: number[] = [];
    if (id) {
      data.push(id);
    } else {
      currWeekShift.forEach((shift: any) => {
        if (shift.shiftStatus === "DRAFT") {
          data.push(shift.shiftDayID);
        }
      });
    }
    mutate({
      url: siteRequest.SEND_SHIFT_DRAFT,
      data: {
        shiftList: data,
      },
    });
  };

  const getDateRange = () => {
    const startDate = shiftRange.current?.start
      ? format(shiftRange.current?.start, "yyyy-MM-dd")
      : format(start, "yyyy-MM-dd");
    const endDate = shiftRange.current?.end
      ? format(shiftRange.current?.end, "yyyy-MM-dd")
      : format(end, "yyyy-MM-dd");
    return { startDate, endDate };
  };

  const onRotaSuccess = (blob) => {
    const { startDate, endDate } = getDateRange();
    const fileURL = window.URL.createObjectURL(blob);
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = `rota-${startDate}_${endDate}.pdf`;
    alink.click();
  };

  const { mutate: downloadRota, isLoading: isDownloadingRota } = useSendToAPI(
    fetchPDFStream,
    onRotaSuccess
  );

  const handleDownload = () => {
    const { startDate, endDate } = getDateRange();
    const data = {
      agencyId: user.agency.agencyID,
      siteId: currSite.siteData.siteID,
      startDate,
      endDate,
      email: user.staff.staffEmail,
    };
    downloadRota({
      url: siteRequest.DOWNLOAD_WEEKLY_ROTA(data),
    });
  };

  // const pasteCopiedCell = (data, cellIndex, destinationIndex) => {
  //   let cloneShifts = [...shifts];
  //   let editDays = cloneShifts[cellIndex].user?.days ?? [];
  //   editDays[destinationIndex] = data;
  //   if (cloneShifts[cellIndex]?.user?.days) {
  //     cloneShifts[cellIndex].user.days = editDays;
  //     setShifts(cloneShifts);
  //   }
  // };

  const formatDateRange = () => {
    const intervals = eachDayOfInterval({
      start,
      end,
    });
    return intervals.map((date) => format(new Date(date), "d E"));
  };

  return (
    <>
      {isFetchingShift && (
        <>
          <div className="w-screen h-screen fixed top-0 left-0 bg-text opacity-20 z-10"></div>
          <div className="fixed left-[50%] flex justify-center mt-5 z-50">
            <Loader size="sm" color="blue" />
          </div>
        </>
      )}
      <div className="h-full flex flex-col">
        <div className="flex items-center mb-2">
          <div className="cursor-pointer" onClick={setView}>
            <BackIcon />
          </div>
          <p className="ml-2 font-semibold text-2xl">Create Shift</p>
        </div>
        <div className="flex justify-between">
          <p>Click on a calendar date to create/edit shift full details</p>
          <div className="flex items-center">
            {isDownload ? (
              <Loader size="sm" />
            ) : (
              <p
                className="mx-3 text-primary text-sm cursor-pointer "
                onClick={() => handleSendDraft()}
              >
                Send All
              </p>
            )}

            <p className="mx-3">{`Week ${getWeek(start, {
              weekStartsOn: 1,
              firstWeekContainsDate: 4,
            })}`}</p>
            <div className="flex items-center mx-3">
              <span
                className="cursor-pointer"
                onClick={() => handleChange("dec")}
              >
                <AiFillCaretLeft size={25} color="black" />
              </span>
              <p>{`${start.getDate()} - ${end.getDate()} ${format(
                end,
                "MMM"
              )}`}</p>
              <span
                className="cursor-pointer"
                onClick={() => handleChange("inc")}
              >
                <AiFillCaretRight size={25} color="black" />
              </span>
            </div>
          </div>
        </div>
        <div className="border rounded-xl flex-1">
          <div className="pt-2 grid grid-cols-5 border-b">
            <div className="border-r">
              <div className="flex items-center px-6">
                <SelectSitePopover
                  currSite={currSite}
                  allSites={sites}
                  setCurrSite={setCurrSite}
                />
              </div>
              <SelectStaffPopover
                siteID={currSite.siteData.siteID}
                loading={isLoading || isLoadingWorkers || isFetching}
                allStaff={data}
                siteStaffs={siteStaffs}
                agencyRoles={agencyRoles}
              />
            </div>
            <div className="col-span-4 pt-2 self-end">
              <div className="flex justify-between">
                <p className="pl-6 mb-2">Drag and drop shift to re-use</p>

                {isDownloadingRota ? (
                  <span className="px-6 mb-2">
                    <Loader size="sm" />
                  </span>
                ) : (
                  <p
                    className="px-6 mb-2 text-[14px] text-primary cursor-pointer"
                    onClick={handleDownload}
                  >
                    Download
                  </p>
                )}
              </div>
              <div className="grid grid-cols-7">
                {formatDateRange().map((day) => (
                  <div className="border-l border-r" key={nanoid()}>
                    <p className="text-center bg-primary-50 py-3 text-sm">
                      {day}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <UserShiftsByWeek
            {...{
              // pasteCopiedCell,
              users: siteStaffs,
              currWeekShift,
              setCurrWeekShift,
              shiftStore,
              agencyId: currSite.siteData.agencyID,
              siteId: currSite.siteData.siteID,
              roles: agencyRoles,
              onClick: (data) => {
                console.log({ data });
                setModal(true);
                setModalData(data);
              },
            }}
          />
        </div>
      </div>
      <div className="fixed px-3 top-0 right-1/2 w-fit max-w-full h-fit z-20  py-5">
        {modal && !Array.isArray(modalData?.shiftData) && (
          <CreateShiftModal
            isLoadingStaff={isLoading}
            isLoadingRoles={isLoadingRoles}
            agencyId={currSite.siteData.agencyID}
            siteId={currSite.siteData.siteID}
            roles={agencyRoles}
            hideModal={hideModal}
            allStaff={data}
            siteStaffs={siteStaffs}
            agencyRoles={agencyRoles}
            modalData={modalData}
            startDate={start}
            isSendingDraft={isDownload}
            sendDraft={handleSendDraft}
            createShift={(data) => {
              setModal(true);
              setModalData(data);
            }}
          />
        )}
        {modal && Array.isArray(modalData?.shiftData) && (
          <MultipleShiftModal
            hideModal={hideModal}
            data={modalData}
            roles={agencyRoles}
            onClick={(data) => {
              setModal(true);
              setModalData(data);
            }}
          />
        )}
      </div>
    </>
  );
};

export default CreateShift;
