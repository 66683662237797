export const sessionInfo = "sessionInfo";

export const monthsObj = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const allPermissions = {
  all: "all",
  staff: "staff",
  shifts: "shifts",
  financials: "financials",
  editAgencyInfo: "editAgencyInfo",
  createUser: "createUser",
  createRoles: "createRoles",
  activityLog: "activityLog",
  timesheet: "timesheet",
  validations: "validations",
};

export const getBankHolidayRules = (args: string) => {
  switch (args) {
    case "STANDARD_RATE_ONLY":
      return "0";
    case "HOLIDAY_RATE_ONLY":
      return "1";
    case "HOLIDAY_PLUS_STANDARD_RATE":
      return "2";
    case "A_TERM_AND_A_HALF":
      return "3";
    case "STANDARD_PLUS_CUSTOM_AMOUNT":
      return "4";
    case "STANDARD_RATE_PLUS_PERCENTAGE_OF_STANDARD_RATE":
      return "5";
    case "TWO_TIMES_STANDARD_RATE":
      return "6";
    default:
      return "";
  }
};

export const getBankHolidayWeekendRules = (args: string) => {
  switch (args) {
    case "HOLIDAY_RATE_ONLY":
      return "0";
    case "HOLIDAY_AND_WEEKEND_RATE":
      return "1";
    case "WEEKEND_RATE_ONLY":
      return "2";
    case "A_TERM_AND_A_HALF":
      return "3";
    case "STANDARD_PLUS_CUSTOM_AMOUNT":
      return "4";
    case "STANDARD_RATE_PLUS_PERCENTAGE_OF_STANDARD_RATE":
      return "5";
    default:
      return "";
  }
};
