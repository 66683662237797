import GoogleMapReact from "google-map-react";
import { Tooltip } from "@mantine/core";
import { MdLocationOn } from "react-icons/md";
import { ActionIcon } from "@mantine/core";
import config from "config";

const SpotCard = ({ lat, lng, text, firstName, lastName }) => {
  // const colors = ["red", "green", "blue", "yellow"];
  const generateHex = () =>
    `#${Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padEnd(0, "0")}`;
  return (
    <div className="w-fit flex flex-col justify-center items-center">
      <Tooltip
        multiline
        transition="fade"
        transitionDuration={200}
        label={`${firstName} ${lastName}`}
      >
        <ActionIcon>
          <MdLocationOn
            size="18px"
            // color={colors[Math.floor(Math.random() * 4)]}
            color={generateHex()}
          />
        </ActionIcon>
      </Tooltip>
      <p className="z-50 text-black text-[15px] leading-3 whitespace-nowrap">
        {text}
      </p>
    </div>
  );
};

const MapScreen = ({ siteMap }: { siteMap: any[] }) => {
  // default to london
  const siteLatitude = siteMap?.[0]?.site?.siteLatitude ?? 51.5073509;
  const siteLongitude = siteMap?.[0]?.site?.siteLongitude ?? -0.1277583;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: config.REACT_APP_GOOGLE_ID }}
      center={[siteLatitude, siteLongitude]}
      zoom={8}
    >
      {siteMap.map((map, idx) => (
        <SpotCard
          key={idx}
          lat={map.site.siteLatitude}
          lng={map.site.siteLongitude}
          text={map.site.siteName}
          firstName={map.user.userFirstname}
          lastName={map.user.userLastname}
        />
      ))}
    </GoogleMapReact>
  );
};

export default MapScreen;
