type PayRollModalProps = {
  showPayModal: () => void;
};
const PayRollModal = ({ showPayModal }: PayRollModalProps) => {
  const customers = [2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  return (
    <div className="fixed-modal flex flex-col h-[90vh] w-[95vw] sm:w-[80vw]">
      <div className="grid grid-cols-7 mt-6 border-b px-5 pb-5 mb-5">
        <div className="col-span-2 sm:flex">
          <p>Date</p>
          <p className="sm:mx-auto">Site</p>
        </div>
        <div className="col-span-2 sm:flex">
          <p>Name</p>
          <p className="mx-auto">Role</p>
        </div>
        <div className="col-span-2 text-center sm:text-left sm:flex">
          <p>Rate</p>
          <p className="mx-auto">Status</p>
        </div>
        <p className="text-center">Action</p>
      </div>
      <div className="flex-1 overflow-auto">
        {customers.map((customer, idx) => (
          <div
            className="grid grid-cols-7 mb-4 items-center px-2 sm:px-5"
            key={idx}
          >
            <div className="col-span-2 sm:flex">
              <span className="text-xs">15/06/2022</span>
              <div className="flex items-center sm:mx-auto">
                <div className="shrink-0 w-4 h-4 bg-primary rounded-full"></div>
                <span className="text-xs ml-1 sm:ml-2">Jay Care Home</span>
              </div>
            </div>
            <div className="col-span-2 sm:flex">
              <span className="text-xs block">Andrew Garfield</span>
              <span className="text-xs sm:mx-auto">Accounts Manager</span>
            </div>
            <div className="col-span-2 text-center sm:text-left sm:flex">
              <span className="text-xs block font-semibold">£20</span>
              <span className="text-red-500 text-xs sm:mx-auto">pending</span>
            </div>
            <div className="text-center sm:text-left">
              <span
                className="text-xs text-primary font-semibold cursor-pointer"
                onClick={showPayModal}
              >
                Pay
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PayRollModal;
