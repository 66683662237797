import CustomTable from "components/CustomTable";
import { addDays, format, getWeek, subDays } from "date-fns";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { reportColumn } from "./model";

const ReportModal = ({
  data,
  start,
  end,
  shiftRange,
  setStart,
  setEnd,
  refetch,
  isLoading,
}) => {
  const handleChange = (args: "inc" | "dec") => {
    if (args === "inc") {
      const newStart = addDays(end, 1);
      const newEnd = addDays(end, 7);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      refetch();
    }
    if (args === "dec") {
      const newStart = subDays(start, 7);
      const newEnd = subDays(start, 1);
      shiftRange.current = { start: newStart, end: newEnd };
      setStart(newStart);
      setEnd(newEnd);
      refetch();
    }
  };

  return (
    <div className="fixed-modal pt-6 w-[90vw] flex flex-col h-[90vh]">
      <div className="flex justify-between">
        <p className="text-xl ml-6 font-semibold">Reports</p>
        <div className="flex">
          <p className="mx-3">{`Week ${getWeek(start)}`}</p>
          <div className="flex items-center mx-3">
            <span
              className="cursor-pointer"
              onClick={() => handleChange("dec")}
            >
              <AiFillCaretLeft size={25} color="black" />
            </span>
            <p>{`${start.getDate()} - ${end.getDate()} ${format(
              end,
              "MMM"
            )}`}</p>
            <span
              className="cursor-pointer"
              onClick={() => handleChange("inc")}
            >
              <AiFillCaretRight size={25} color="black" />
            </span>
          </div>
        </div>
      </div>
      <div className="mt-3 mx-5">
        <CustomTable
          height="fit-content"
          loading={isLoading}
          data={data}
          columns={reportColumn}
        />
      </div>
    </div>
  );
};

export default ReportModal;
