import { useCallback, useEffect, useState } from "react";
import { Calendar } from "@mantine/dates";
import { Avatar, Button, createStyles, TextInput } from "@mantine/core";
import ProgressCard from "components/ProgressCard";
import {
  BackIcon,
  CancelIcon,
  DeleteIcon,
  DownloadIcon,
} from "components/Svgs";
import { HideModalProp, SelectType } from "types";
import style from "styles/Staff.module.css";
import PersonalDetails from "pages/staff/PersonalDetails";
import dayjs from "dayjs";
import { monthsObj } from "utils/constants";
import { agencyRequest } from "services/request";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteRequest, getRequest, postRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import { profileColumn } from "pages/staff/model";
import CustomTable from "./CustomTable";
import RoleSelect from "./RoleSelect";
import { successToast } from "hooks/useToast";
import useSendToAPI from "hooks/useSendToAPI";

const useStyles = createStyles((theme) => ({
  calendarHeader: {
    display: "none",
  },
  weekday: {
    fontSize: "15px",
    color: "#2D3436",
    textAlign: "left",
    borderBottom: "2px solid #DFE6E9",
    paddingBottom: "10px",
  },
  cell: {
    border: "1px solid #DFE6E9",
    borderTop: "none",
    textAlign: "left",
    width: "60px",
    height: "60px",
  },
  day: {
    verticalAlign: "top",
    fontSize: "16px",
    textAlign: "left",
    width: "100%",
    height: "100%",
    paddingLeft: "10px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&[data-selected='true']": {
      backgroundColor: "#CFE5FC",
      color: "#aaa",
      opacity: "0.8",
    },
  },
  outline: {
    backgroundColor: "#F87171 !important",
  },
  label: {
    color: "#fff !important",
  },
}));

interface IStaff extends HideModalProp {
  data?: Record<string, any>;
  roles?: Array<Record<string, any>>;
  agencyId?: string | number;
}

const StaffProfileModal = ({
  agencyId,
  data = {},
  roles,
  hideModal,
}: IStaff) => {
  const [temp, setTemp] = useState({});
  const [currMonth, setCurrMonth] = useState("");
  const [currentModal, setCurrentModal] = useState("profile");
  const [view, setView] = useState("work");
  const [email, setEmail] = useState(data?.user?.userEmail);
  const [role, setRole] = useState(data?.role?.[0]?.roleID);
  const [rate, setRate] = useState<Record<string, string>>({});
  const [modalData, setModalData] = useState<any>({});
  const queryClient = useQueryClient();
  const { classes } = useStyles();

  const calcData = useCallback(() => {
    const availability = data?.availabilities;
    const arr = Array.isArray(availability) ? availability : [];
    const store = {};
    arr.forEach((i) => {
      const month = i.availability;
      const curr = dayjs(month).month() + 1;
      if (store[curr]) {
        store[curr] = [...store[curr], new Date(month)];
      } else {
        store[curr] = [new Date(month)];
      }
    });
    setTemp(store);
    setCurrMonth(Object.keys(store)[0]);
  }, [data]);

  useEffect(() => {
    calcData();
  }, [calcData]);

  const { isFetching, data: result = [] } = useQuery(
    [queryKeys.workHistory],
    async () =>
      getRequest({
        url: agencyRequest.WORK_HISTORY(data?.user?.userID, agencyId),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!agencyId,
    }
  );

  const { data: staffDetails = {} } = useQuery(
    [queryKeys.staffDetails],
    async () =>
      getRequest({
        url: agencyRequest.STAFF_DETAILS(data?.user?.userID),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: staffRate } = useQuery(
    [queryKeys.staffRate],
    async () =>
      getRequest({
        url: agencyRequest.STAFF_RATE({
          agencyID: agencyId,
          staffId: data?.user?.userID,
        }),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  console.log({ staffRate });

  const onSuccess = () => {
    successToast("Role Added Successfully!");
    queryClient.invalidateQueries([queryKeys.allStaffs]);
    hideModal();
  };

  const onSuccessRate = () => {
    successToast("Rate Updated Successfully!");
    queryClient.invalidateQueries([queryKeys.staffRate]);
    hideModal();
  };
  const onRemoveSuccess = () => {
    successToast("User Removed Successfully!");
    queryClient.invalidateQueries([queryKeys.allStaffs]);
    queryClient.invalidateQueries([queryKeys.staffSummary]);
    hideModal();
  };

  const { mutate: removeUser, isLoading: isRemoving } = useSendToAPI(
    deleteRequest,
    onRemoveSuccess
  );

  const handleRemove = () => {
    removeUser({
      url: agencyRequest.REMOVE_USER_FROM_AGENCY(agencyId, data?.user?.userID),
    });
  };
  const { mutate, isLoading } = useSendToAPI(postRequest, onSuccess);
  const { mutate: mutateRate, isLoading: isLoadingRate } = useSendToAPI(
    postRequest,
    onSuccessRate
  );

  const handleRole = () => {
    const payload = {
      agencyID: agencyId,
      userID: data?.user?.userID,
      roleID: role,
    };
    if (data?.role?.[0]?.roleID !== role) {
      mutate({
        url: agencyRequest.ADD_USER_ROLE,
        data: payload,
      });
    }
  };

  const handleRate = () => {
    mutateRate({
      url: agencyRequest.SET_RATE,
      data: {
        agencyID: agencyId,
        userID: data?.user?.userID,
        dailyRate: rate?.dailyRate || "0",
        bankHolidayRate: rate.bankHolidayRate || "0",
        nightlyRate: rate?.nightlyRate || "0",
        weekendDailyRate: rate?.weekendDailyRate || "0",
        weekendNightlyRate: rate?.weekendNightlyRate || "0",
      },
    });
  };

  const changeMonth: SelectType = (e) => {
    const { value } = e.target;
    setCurrMonth(value);
  };

  const getUserRole = () => {
    const roleId = data?.role?.[0]?.roleID?.toString();
    const userRole = roles?.find((role) => role?.roleID?.toString() === roleId);
    return userRole?.roleName;
  };

  const getServiceWorkerRoles = () => {
    const userRoles = roles?.filter((role) => role?.serviceWorkerRole === true);
    return userRoles;
  };

  const handleModal = (view, data) => {
    setView(view);
    setModalData(data);
  };

  return (
    <>
      {currentModal === "profile" && (
        <div className="fixed-modal overflow-y-auto w-[90vw] h-[95vh] pb-3">
          <div className="bg-white h-full w-full overflow-y-auto rounded-lg sm:flex">
            <div className="min-w-[250px] pt-8 border-r sm:mr-10 relative">
              <div
                className="absolute left-4 top-10 cursor-pointer"
                onClick={hideModal}
              >
                <BackIcon />
              </div>
              <div className="mx-auto bg-primary rounded-full w-[120px] h-[120px]">
                <Avatar
                  src={data?.pictureLink}
                  size={120}
                  alt="user"
                  radius={100}
                />
              </div>
              <p className="text-center mb-4 mt-2 text-lg font-semibold">
                {data?.user?.userFirstname} {data?.user?.userLastname}
              </p>
              <div className="px-6">
                <div className={style.field}>
                  <p>ID:</p>
                  <span>{data?.user?.userID ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Email:</p>
                  <span>{data?.user?.userEmail ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Phone No:</p>
                  <span>{data?.user?.userPhone ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Date of birth:</p>
                  <span>
                    {data?.user?.userDOB
                      ? new Date(data?.user?.userDOB).toLocaleDateString()
                      : "N/A"}
                  </span>
                </div>
                <div className={style.field}>
                  <p>Date joined:</p>
                  <span>
                    {data?.user?.createdAt
                      ? new Date(data?.user?.createdAt).toLocaleDateString()
                      : "N/A"}
                  </span>
                </div>
                <div className={style.field}>
                  <p>Role:</p>
                  <span>{getUserRole() ?? "N/A"}</span>
                </div>
              </div>
              <div className="border-b border-border mb-4"></div>
              <div className="px-6">
                <div className={style.field}>
                  <p>Bank Name:</p>
                  <span>{data?.userBankData?.bankName ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Account No:</p>
                  <span>{data?.userBankData?.accountNumber ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Bank Sort Code:</p>
                  <span>{data?.userBankData?.sortCode ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>NI No:</p>
                  <span>
                    {data?.userBankData?.nationalInsuranceNumber ?? "N/A"}
                  </span>
                </div>
                <div className="border-b border-border mb-4"></div>
                <div className={style.field}>
                  <p>Rate Summary</p>
                  <div className="flex gap-4">
                    <span
                      className="cursor-pointer text-primary"
                      onClick={() => {
                        setRate(staffRate);
                        setCurrentModal("editRate");
                      }}
                    >
                      Edit All
                    </span>
                  </div>
                </div>
                <div className={style.field}>
                  <p>Daily Rate:</p>
                  <span>{staffRate?.dailyRate ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Nightly Rate:</p>
                  <span>{staffRate?.nightlyRate ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Weekend Daily Rate:</p>
                  <span>{staffRate?.weekendDailyRate ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Weekend Nightly Rate:</p>
                  <span>{staffRate?.weekendNightlyRate ?? "N/A"}</span>
                </div>
                <div className={style.field}>
                  <p>Bank Holiday Rate:</p>
                  <span>{staffRate?.bankHolidayRate ?? "N/A"}</span>
                </div>
              </div>
              <span
                className="text-primary block w-full text-center text-xs cursor-pointer"
                onClick={() => setView("personal")}
              >
                View personal details
              </span>
              <div className="border-b border-border my-3"></div>
              <div className="flex justify-between px-6 items-center">
                <p
                  onClick={() => setCurrentModal("edit")}
                  className="cursor-pointer border-2 border-primary rounded-lg py-1 px-8 text-primary text-sm"
                >
                  Edit
                </p>
                <div
                  className="w-fit h-fit cursor-pointer"
                  onClick={() => setCurrentModal("delete")}
                >
                  <DeleteIcon />
                </div>
              </div>
            </div>
            {view === "work" && (
              <div className="flex-1 px-3 sm:px-10">
                <div className="grid gird-cols-2 sm:grid-cols-4 gap-x-10">
                  <ProgressCard
                    name="Total earnings"
                    value={data?.earningsAndShifts?.totalEarnings}
                  />
                  <ProgressCard
                    name="Rotas"
                    value={data?.earningsAndShifts?.totalShifts}
                  />
                  <ProgressCard
                    name="Hours worked"
                    value={data?.earningsAndShifts?.hoursWorked}
                  />
                  <ProgressCard
                    name="Average rating"
                    value={data?.earningsAndShifts?.averageRating}
                  />
                </div>
                <div className="flex mt-6 mb-4">
                  <p>Availability</p>
                  <select
                    className="w-40 border border-border rounded-md py-1 ml-3"
                    name=""
                    id=""
                    onChange={changeMonth}
                  >
                    {Object.keys(temp).map((value, index) => (
                      <option
                        value={value}
                        key={index}
                        defaultChecked={index === 0}
                      >
                        {monthsObj[value]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full sm:w-[70%]">
                  <div className="w-full">
                    <Calendar
                      multiple
                      fullWidth={true}
                      firstDayOfWeek="sunday"
                      allowLevelChange={false}
                      month={temp[Number(currMonth)]?.[0]}
                      value={temp[Number(currMonth)]}
                      classNames={{
                        calendarHeader: classes.calendarHeader,
                        weekday: classes.weekday,
                        cell: classes.cell,
                        day: classes.day,
                      }}
                    />
                  </div>
                </div>
                <p className="text-xl mt-7">Rota History</p>
                <div className="w-full max-h-[50vh] overflow-auto rounded-t-2xl pt-6 sm:w-[70%] my-3">
                  <CustomTable
                    searchPlaceholder="Search staff by Staff ID / Name / Role / Date joined"
                    loading={isFetching}
                    data={result}
                    columns={profileColumn}
                  />
                </div>
              </div>
            )}
            {view === "personal" && (
              <PersonalDetails
                data={staffDetails}
                setView={(data) => {
                  handleModal("document", data);
                  // setView("document"}
                }}
              />
            )}
            {view === "document" && (
              <div className="pt-3 pb-14 px-2 sm:px-0">
                <div className="border flex mt-8 flex-col border-bold h-full max-w-full w-[650px]">
                  <div className="flex justify-between items-center p-2">
                    <div
                      className="cursor-pointer"
                      onClick={() => setView("personal")}
                    >
                      <CancelIcon />
                    </div>
                    <div className="cursor-pointer">
                      <a
                        href={`${modalData?.imageUrl}`}
                        download="file"
                        // rel="noreferrer"
                        // target="_blank"
                      >
                        <DownloadIcon />
                      </a>
                    </div>
                  </div>
                  <div className="h-60 overflow-scroll sm:flex-1 bg-primary">
                    <img
                      src={modalData?.imageUrl}
                      alt={modalData?.name}
                      height="100%"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {currentModal === "delete" && (
        <div className="fixed-modal bg-white w-[340px] px-6 py-5 rounded-lg">
          <span className="text-red-500 text-lg text-center">
            Are you sure you want to remove {data?.user?.userFirstname}{" "}
            {data?.user?.userLastname} from your agency
          </span>
          <br />
          <span className="block my-4 text-base">
            Delete is permanent and can't be undone
          </span>
          <div className="flex gap-3 justify-between">
            <Button
              disabled={isLoading || isRemoving}
              onClick={() => setCurrentModal("profile")}
            >
              No
            </Button>
            <Button
              classNames={{
                root: classes.outline,
                label: classes.label,
              }}
              disabled={isLoading || isRemoving}
              loading={isLoading || isRemoving}
              onClick={handleRemove}
            >
              Yes
            </Button>
          </div>
        </div>
      )}
      {currentModal === "edit" && (
        <div className="fixed-modal bg-white w-[450px] max-w-[95%] relative px-8 sm:px-12 rounded-lg pt-9 pb-12">
          <div
            className="absolute left-4 top-[43px] cursor-pointer"
            onClick={() => setCurrentModal("profile")}
          >
            <BackIcon />
          </div>
          <TextInput
            disabled
            label="Email"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <p>Role</p>
          <RoleSelect
            role={role}
            setRole={setRole}
            isLoading={false}
            roles={getServiceWorkerRoles()}
          />
          <Button disabled={isLoading} loading={isLoading} onClick={handleRole}>
            Done
          </Button>
        </div>
      )}
      {currentModal === "editRate" && (
        <div className="fixed-modal bg-white w-[450px] max-w-[95%] relative px-8 sm:px-12 rounded-lg pt-9 pb-12">
          <div
            className="absolute left-4 top-[43px] cursor-pointer"
            onClick={() => setCurrentModal("profile")}
          >
            <BackIcon />
          </div>

          <TextInput
            label="Daily Rate"
            placeholder="Enter rate"
            type="number"
            value={rate?.dailyRate}
            onChange={(evt) =>
              setRate({ ...rate, dailyRate: evt.target.value })
            }
          />
          <TextInput
            label="Nightly Rate"
            placeholder="Enter rate"
            type="number"
            value={rate?.nightlyRate}
            onChange={(evt) =>
              setRate({ ...rate, nightlyRate: evt.target.value })
            }
          />
          <TextInput
            label="Weekend Daily Rate"
            placeholder="Enter rate"
            type="number"
            value={rate?.weekendDailyRate}
            onChange={(evt) =>
              setRate({ ...rate, weekendDailyRate: evt.target.value })
            }
          />
          <TextInput
            label="Weekend Nightly Rate"
            placeholder="Enter rate"
            type="number"
            value={rate?.weekendNightlyRate}
            onChange={(evt) =>
              setRate({ ...rate, weekendNightlyRate: evt.target.value })
            }
          />
          <TextInput
            label="Bank Holiday Rate"
            placeholder="Enter rate"
            type="number"
            value={rate?.bankHolidayRate}
            onChange={(evt) =>
              setRate({ ...rate, bankHolidayRate: evt.target.value })
            }
          />

          <Button
            disabled={isLoadingRate}
            loading={isLoadingRate}
            onClick={handleRate}
          >
            Done
          </Button>
        </div>
      )}
    </>
  );
};

export default StaffProfileModal;
