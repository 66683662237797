import { Link } from "react-router-dom";
import Logo from "images/logo.png";

const OnboardingConfirmation = () => {
  return (
    <div className="h-screen bg-slate-200">
      <div className="w-1/2 mx-auto pt-20 flex flex-col justify-center items-center">
        <img src={Logo} alt="zshift logo" className="logo" />
        <h1 className="font-medium text-lg my-5">Onboarding Completed</h1>
        <div className="bg-white rounded my-2 p-4 w-1/2 min-w-[400px]">
          <p className="text-center leading-6">
            Your account creation/onboarding has been completed, you can now
            proceed to use all the services available on the platorm. <br />{" "}
            Thank you
          </p>
          <Link to="/login">
            <p className="bg-primary text-white text-center p-2 mt-3 rounded">
              Proceed to Login
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OnboardingConfirmation;
