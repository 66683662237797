import { Button, TextInput } from "@mantine/core";
// import { BackIcon } from "components/Svgs";
import useSendToAPI from "hooks/useSendToAPI";
import { errorToast, successToast } from "hooks/useToast";
import { useState } from "react";
import { agencyRequest } from "services/request";
import { IUser } from "types";
import { putRequest } from "utils/apiCalls";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import { useForm } from "@mantine/form";

type IProps = {
  hideModals: () => void;
  user: IUser;
};
const ChangePassword = ({ hideModals, user }: IProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [showOld, setShowOld] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: {
      newPassword: (value) =>
        value.length < 6 ? "Password must have at least 6 letters" : null,
      confirmPassword: (value) =>
        value.length < 6
          ? "New Password and Confirm Password has to be the same"
          : null,
    },
  });

  function handleLogout() {
    window.localStorage.clear();
    window.location.href = "/";
  }
  const onSuccess = (res) => {
    const isSuccess = typeof res === "boolean" ? res : res?.data;
    if (isSuccess) {
      successToast("Password Changed Successfully!");
      setTimeout(() => {
        handleLogout();
      }, 300);
      hideModals();
    } else {
      errorToast("Invalid Old Password");
    }
  };

  const { mutate, isLoading } = useSendToAPI(putRequest, onSuccess);

  const handleSubmit = (data: any) => {
    if (data.newPassword !== data.confirmPassword) {
      form.setFieldError(
        "confirmPassword",
        "New Password and Confirm Password has to be the same"
      );
    } else {
      const payload = {
        staffId: user.staff.staffID,
        password: data.newPassword,
        oldPass: data.oldPassword,
      };
      mutate({
        url: agencyRequest.CHANGE_AGENCY_STAFF_PASSWORD(payload),
        data: {},
      });
    }
  };

  return (
    <div className="fixed-modal relative py-3 px-8 sm:px-10 max-h-[90vh] overflow-y-auto w-[450px] max-w-[95vw]">
      {/* <div
        className="absolute left-5 cursor-pointer top-[20px]"
        onClick={hideModals}
      >
        <BackIcon />
      </div> */}
      <form className="w-full p-0" onSubmit={form.onSubmit(handleSubmit)}>
        <div className="mb-6">
          <TextInput
            size="lg"
            rightSection={
              showOld ? (
                <BsEyeSlashFill
                  color="#000"
                  className="mr-5 cursor-pointer"
                  onClick={() => setShowOld(!showOld)}
                />
              ) : (
                <IoEyeSharp
                  color="#000"
                  className="mr-5 cursor-pointer"
                  onClick={() => setShowOld(!showOld)}
                />
              )
            }
            type={showOld ? "text" : "password"}
            label="Old Password"
            placeholder="Enter at least six characters"
            {...form.getInputProps("oldPassword")}
          />
          <TextInput
            size="lg"
            rightSection={
              show ? (
                <BsEyeSlashFill
                  color="#000"
                  className="mr-5 cursor-pointer"
                  onClick={() => setShow(!show)}
                />
              ) : (
                <IoEyeSharp
                  color="#000"
                  className="mr-5 cursor-pointer"
                  onClick={() => setShow(!show)}
                />
              )
            }
            type={show ? "text" : "password"}
            label="New Password"
            placeholder="Enter at least six characters"
            {...form.getInputProps("newPassword")}
          />
        </div>
        <div className="mb-6">
          <TextInput
            size="lg"
            rightSection={
              showConfirm ? (
                <BsEyeSlashFill
                  color="#000"
                  className="mr-5 cursor-pointer"
                  onClick={() => setShowConfirm(!showConfirm)}
                />
              ) : (
                <IoEyeSharp
                  color="#000"
                  className="mr-5 cursor-pointer"
                  onClick={() => setShowConfirm(!showConfirm)}
                />
              )
            }
            type={showConfirm ? "text" : "password"}
            label="Confirm New Password"
            placeholder=""
            {...form.getInputProps("confirmPassword")}
          />
        </div>
        <div className="w-full">
          <Button type="submit" disabled={isLoading} loading={isLoading}>
            Change Password
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ChangePassword;
