import { Button, createStyles, Select } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { addYears, getMonth, getYear, subYears } from "date-fns";
import { useState } from "react";
import useSendToAPI from "hooks/useSendToAPI";
import CustomTable from "components/CustomTable";
import { agencyRequest } from "services/request";
import { getRequest, postRequestP3 } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import { invoiceColumn } from "./model";

const useStyles = createStyles((theme) => ({
  outline: {
    backgroundColor: "rgba(227, 61, 148, .9) !important",
    color: "#fff !important",
    borderRadius: "0.375rem",
    padding: "5px 25px",
  },
  label: {
    color: "#fff !important",
  },
}));

type InvoiceModalProps = {
  showPayModal: () => void;
  agencyID: string | number;
};

const InvoiceModal = ({ agencyID, showPayModal }: InvoiceModalProps) => {
  const { classes } = useStyles();
  const [year, setYear] = useState(getYear(new Date()).toString());
  const [month, setMonth] = useState((getMonth(new Date()) + 1).toString());

  const monthData = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const yearData = [
    {
      value: getYear(subYears(new Date(), 2)).toString(),
      label: getYear(subYears(new Date(), 2)).toString(),
    },
    {
      value: getYear(subYears(new Date(), 1)).toString(),
      label: getYear(subYears(new Date(), 1)).toString(),
    },
    {
      value: getYear(new Date()).toString(),
      label: getYear(new Date()).toString(),
    },
    {
      value: getYear(addYears(new Date(), 1)).toString(),
      label: getYear(addYears(new Date(), 1)).toString(),
    },
    {
      value: getYear(addYears(new Date(), 2)).toString(),
      label: getYear(addYears(new Date(), 2)).toString(),
    },
  ];

  const padNumber = (value) => {
    return Number(value) < 10 ? value.padStart(2, "0") : value;
  };

  const formatDescription = (month, year) => {
    let result = "";
    const monthKey = monthData.find(
      (item) => item.label.toLowerCase() === month?.toLowerCase()
    );
    const yearKey = year.toString().substring(2);
    if (monthKey?.value) {
      result = result + padNumber(monthKey?.value);
    }
    result = result + "-" + yearKey;
    return result;
  };

  const onSuccess = (res) => {
    window.open(res?.chargeURL);
  };

  const { mutate, isLoading } = useSendToAPI(postRequestP3, onSuccess);

  const handleClick = () => {
    const payload = {
      agencyID,
      productName: "zshift-subscription",
      quantity: data?.totalEligibleCharge,
      price: data?.unitCost,
      productId: 1000,
      description: formatDescription(data?.month, data?.year),
    };
    if (data?.totalEligibleCharge) {
      mutate({ url: agencyRequest.PAYMENT_INTENT, data: payload });
    }
  };

  const {
    isFetching,
    data = {},
    refetch,
  } = useQuery(
    [queryKeys.invoices],
    async () =>
      getRequest({
        url: agencyRequest.GET_INVOICE_BY_MONTH({
          agencyId: agencyID,
          month: padNumber(month),
          year,
        }),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="fixed-modal flex flex-col h-[90vh] w-[95vw] sm:w-[80vw]">
      <div className="p-5 flex justify-between">
        <div className=" flex items-start">
          <div className="mr-3 mt-2 w-[55%]">
            <Select
              value={month}
              onChange={(value) => setMonth(value as string)}
              data={monthData}
            />
          </div>
          <div className="mr-3 mt-2 w-[55%]">
            <Select
              value={year}
              onChange={(value) => setYear(value as string)}
              data={yearData}
            />
          </div>
          <div className="w-[45%] h-[50px]">
            <Button loading={isFetching} onClick={() => refetch()}>
              View Invoice
            </Button>
          </div>
        </div>
        {!data?.paid && (
          <div className="my-0 flex justify-end">
            <div className="min-w-[150px]">
              <Button
                classNames={{
                  root: classes.outline,
                  label: classes.label,
                }}
                loading={isLoading}
                onClick={handleClick}
              >
                Pay Now
              </Button>
            </div>
          </div>
        )}
      </div>
      <div
        className={`px-5 flex justify-between flex-wrap  ${
          data?.paid ? "mt-5" : ""
        }`}
      >
        <div className="min-w-[150px] bg-primary-50  text-center border-2 border-dotted border-primary p-3 rounded">
          <p className="text-black italic">Month & Year</p>
          <p className="text-primary font-bold">
            {data?.month}, {data?.year}
          </p>
        </div>
        <div className="min-w-[150px] bg-primary-50  text-center border-2 border-dotted border-primary p-3 rounded">
          <p className="text-black italic">Total Eligible Charge</p>
          <p className="text-primary font-bold">{data?.totalEligibleCharge}</p>
        </div>
        <div className="min-w-[150px] bg-primary-50  text-center border-2 border-dotted border-primary p-3 rounded">
          <p className="text-black italic">Unit Cost</p>
          <p className="text-primary font-bold">£ {data?.unitCost}</p>
        </div>
        <div className="min-w-[150px] bg-primary-50  text-center border-2 border-dotted border-primary p-3 rounded">
          <p className="text-black italic">Amount Due</p>
          <p className="text-primary font-bold">£ {data?.amountDue}</p>
        </div>
        <div className="min-w-[150px] bg-primary-50  text-center border-2 border-dotted border-primary p-3 rounded">
          <p className="text-black italic">Status</p>
          <p className="text-primary font-bold">
            {data?.paid ? "Paid" : "Unpaid"}
          </p>
        </div>
      </div>
      <div className="max-h-[60vh] mt-6 px-5 mb-5 overflow-scroll">
        <CustomTable
          height="100%"
          loading={false}
          data={data?.shiftsForInvoiceData ?? []}
          columns={invoiceColumn}
        />
      </div>
    </div>
  );
};

export default InvoiceModal;
