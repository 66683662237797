export const tableColumn = [
  {
    id: "shiftDate",
    header: "Shift Date",
    accessorFn: (row) => {
      const date = row?.shiftDate
        ? new Date(row?.shiftDate).toLocaleDateString()
        : "N/A";
      return date;
    },
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.site?.siteName ?? "N/A",
    id: "siteName",
    header: "Site",
    cell: (info) => {
      const colorTag = info.row?.original?.site?.colorTag;
      return (
        <>
          <span
            style={{ backgroundColor: colorTag }}
            className={`inline-block  h-[8px] w-[8px] mr-1 rounded-[50%]`}
          ></span>
          <span className="text-[14px] capitalize">{info.getValue()}</span>
        </>
      );
    },
    footer: (props) => props.column.id,
    // filterFn: 'fuzzy',
    // sortingFn: fuzzySort,
  },
  {
    accessorFn: (row) => {
      const time = `${row?.startTime} - ${row?.endTime}`;
      return time.trim().toString().length > 0 ? time : "N/A";
    },
    id: "time",
    header: "Time",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => {
      const name = `${row?.user?.userFirstname} ${row?.user?.userLastname}`;
      return name.trim().toString().length > 0 ? name : "N/A";
    },
    id: "name",
    header: "Name",
    cell: (info) => <p className="capitalize text-[14px]">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.role ?? "N/A",
    id: "role",
    header: "Role",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.shiftRate ?? "N/A",
    id: "shiftRate",
    header: "Shift Rate",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => {
      let status = row?.shiftStatus
        ? row?.shiftStatus.toString().toLowerCase()
        : "N/A";
      status = status.replace("_", "-");
      return status;
    },
    id: "shiftStatus",
    header: "Status",
    cell: (info) => {
      return (
        <span className={`text-[14px] capitalize`}>{info.getValue()}</span>
      );
    },
    footer: (props) => props.column.id,
  },
];
// const statusColor = {
//   accepted: "#71D342",
//   completed: "#F0F0FF",
//   "in-progress": "#FFF7DA",
//   pending: "#FFE4F1",
//   rejected: "red",
// };

export const actionColumn = ({ header, cell, onClick }) => {
  return {
    accessorFn: (row) => {
      return (
        <span className="text-[14px] capitalize" onClick={() => onClick(row)}>
          {cell}
        </span>
      );
    },
    id: "shiftStatus",
    header: { header },
    //   cell: (info) => info.getValue(),
    //   footer: (props) => props.column.id,
    disableSortBy: true,
  };
};

export const reportColumn = [
  {
    accessorFn: (row) => {
      const name = `${row?.user?.userFirstname} ${row?.user?.userLastname}`;
      return name.trim().toString().length > 0 ? name : "N/A";
    },
    id: "name",
    header: "Name",
    cell: (info) => <p className="text-[14px] capitalize">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    id: "time",
    header: "Time",
    accessorFn: (row) => {
      const period = `${row?.shift?.startTime} - ${row?.shift?.endTime}`;
      return period.trim().toString().length > 0 ? period : "N/A";
    },
    cell: (info) => <p className="text-[14px]">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.site?.siteName ?? "N/A",
    id: "siteName",
    header: "Site",
    cell: (info) => {
      const colorTag = info.row?.original?.site?.colorTag;
      return (
        <>
          <span
            style={{ backgroundColor: colorTag }}
            className={`inline-block  h-[8px] w-[8px] mr-1 rounded-[50%]`}
          ></span>
          <span className="text-[14px] capitalize">{info.getValue()}</span>
        </>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    id: "role",
    header: "Role",
    accessorFn: (row) => row?.report?.role ?? "N/A",
    cell: (info) => <p className="text-[14px] capitalize">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
  {
    id: "report",
    header: "Report",
    accessorFn: (row) => row?.report?.report ?? "N/A",
    cell: (info) => <p className="text-[14px] capitalize">{info.getValue()}</p>,
    footer: (props) => props.column.id,
  },
];

export const shiftColumn = [
  {
    id: "id",
    header: "ID",
    accessorFn: (row) => row.userID,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "shiftDate",
    header: "Date",
    accessorFn: (row) => {
      const date = row?.shiftDate
        ? new Date(row?.shiftDate).toLocaleDateString()
        : "N/A";
      return date;
    },
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "breakAllowed",
    header: "Break Allowed",
    accessorFn: (row) => row.breakAllowed,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "startTime",
    header: "Start Time",
    accessorFn: (row) => row.startTime,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "endTime",
    header: "End Time",
    accessorFn: (row) => row.endTime,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "shiftStatus",
    header: "Status",
    accessorFn: (row) => row.shiftStatus,
    cell: (info) => (
      <p className="text-[14px] capitalize">{info.getValue()?.toLowerCase()}</p>
    ),
    footer: (props) => props.column.id,
  },
];
