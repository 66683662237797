import { Link } from "react-router-dom";
import { BiErrorAlt } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";

const NotFound = () => {
  return (
    <div className="h-screen bg-primary pt-20">
      <div className="bg-white w-1/2 p-6 mx-auto flex flex-col justify-center items-center">
        <BiErrorAlt size={100} />
        <h1 className="font-medium text-lg mt-5">Page Not Found</h1>
        <div className="bg-white rounded p-4 w-1/2">
          <p className="text-center leading-6">
            Sorry, the page you seem to be looking for does not exist.
          </p>
          <Link to="/">
            <div className="w-fit mx-auto px-6 mt-3 flex bg-primary justify-center items-center">
              <p className="text-white p-2 mr-2">Back to Home</p>
              <BsArrowRight size={20} color="#fff" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
