import { CompatClient } from "@stomp/stompjs";
import { useQueryClient } from "@tanstack/react-query";
import useSocketConnection from "hooks/useSockSocket";
import { createContext, useEffect, useState } from "react";
import { IUser } from "types";
import { queryKeys } from "utils/queryKeys";

export interface IProvider {
  connected: boolean;
  stompClient: CompatClient | null;
  messages: any[];
  notifications: any[];
}

interface ISubscriber {
  children: React.ReactNode;
  user: IUser;
}

const SubScriberContext = createContext<IProvider>({} as IProvider);
export const SubscriberProvider = ({ children, user }: ISubscriber) => {
  const data = useSocketConnection({
    userID: user.staff.staffID,
    channels: user?.channelSubscriptions,
  });
  const queryClient = useQueryClient();
  const [messages, setMessages] = useState([]);
  const [notifications, setNotications] = useState([]);

  // subscribe to changes in chat and notification queries
  useEffect(() => {
    queryClient.getQueryCache().subscribe((evt) => {
      const queryKey = evt.query.queryKey;
      // log user out
      // if (queryKey?.[0] === queryKeys.user) {
      //   window.localStorage.clear();
      //   window.location.href = "/";
      //   console.log("I got here");
      // }
      if (queryKey?.[0] === queryKeys.chat) {
        const data = evt.query.state.data;
        setMessages(data);
      }
      if (queryKey?.[0] === queryKeys.notification) {
        const data = evt.query.state.data;
        setNotications(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SubScriberContext.Provider
      value={{
        messages,
        notifications,
        connected: data.connected,
        stompClient: data.stompClient,
      }}
    >
      {children}
    </SubScriberContext.Provider>
  );
};

export default SubScriberContext;
