import "styles/auth.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import { Button, Checkbox, TextInput } from "@mantine/core";
import { PUBLIC_PATHS } from "routes";
import { useSignInValidation } from "hooks/useValidation";
import { errorToast } from "hooks/useToast";
import useSendToAPI from "hooks/useSendToAPI";
import { postRequest } from "utils/apiCalls";
import { sessionInfo } from "utils/constants";
import { authRequest } from "services/request";
import HeroImage from "images/hero2.png";
import Logo from "images/logo.png";
import { usePostHog } from "posthog-js/react";
import { addHours } from "date-fns";

const Login = () => {
  const posthog = usePostHog();
  const [show, setShow] = useState<boolean>(false);
  const [showSettingUp] = useState(false);

  const onSuccess = (data) => {
    if (data?.agency && data?.staff) {
      window.localStorage.setItem(
        sessionInfo,
        JSON.stringify({ ...data, expiresIn: addHours(new Date(), 1) })
      );
      window.location.href = "/";
    } else {
      errorToast("Invalid login credentials");
    }
  };
  const { mutate, isLoading } = useSendToAPI(postRequest, onSuccess);
  const handleSubmit = (data) => {
    posthog?.capture("clicked_log_in");
    mutate({ url: authRequest.LOGIN, data });
  };
  const form = useSignInValidation();
  return (
    <>
      {!showSettingUp && (
        <main>
          <div className="hero-col">
            <div>
              <img src={HeroImage} className="hero-image" alt="" />
              <p className="section-title text-center">Welcome back</p>
            </div>
          </div>
          <div>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <img src={Logo} alt="" className="logo" />
              <p className="section-title">Please Sign In</p>
              <div className="mb-6">
                <TextInput
                  size="lg"
                  type="email"
                  label="Email"
                  placeholder="name@example.com"
                  {...form.getInputProps("email")}
                />
              </div>
              <div className="mb-6">
                <TextInput
                  size="lg"
                  rightSection={
                    show ? (
                      <BsEyeSlashFill
                        color="#000"
                        className="mr-5 cursor-pointer"
                        onClick={() => setShow(!show)}
                      />
                    ) : (
                      <IoEyeSharp
                        color="#000"
                        className="mr-5 cursor-pointer"
                        onClick={() => setShow(!show)}
                      />
                    )
                  }
                  type={show ? "text" : "password"}
                  label="Password"
                  placeholder="Enter at least six characters"
                  {...form.getInputProps("secret")}
                />
              </div>
              <div className="flex items-center -mt-3 mb-3">
                <Checkbox />
                <p className="ml-2  text-gray-400 mr-auto">Remember me</p>
                <Link to={PUBLIC_PATHS.RECOVER_PASSWORD}>
                  <p>Forgot Password?</p>
                </Link>
              </div>
              <div className="w-full">
                <Button type="submit" disabled={isLoading} loading={isLoading}>
                  Signin
                </Button>
              </div>
              <div className="flex items-center">
                <p>Don't have an account? </p>
                <Link to={PUBLIC_PATHS.REGISTER}>
                  <span className="text-primary ml-3"> Sign Up</span>
                </Link>
              </div>
            </form>
          </div>
        </main>
      )}
      {showSettingUp && (
        <div className="text-center">
          <img src={Logo} alt="" className="logo m-auto" />
          <p className="section-title my-5">Setting up your ZShift account</p>
          <br className="mb-6" />
          <img className="hero-image" src={HeroImage} alt="" />
        </div>
      )}
    </>
  );
};
export default Login;
