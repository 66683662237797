import { useState } from "react";
import { Avatar } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { DeleteModal } from "components/DeleteModal";
import {
  BackIcon,
  // DeleteIcon
} from "components/Svgs";
import AddStaffModal from "./AddStaffModal";
import GroupModal from "./GroupModal";
import { queryKeys } from "utils/queryKeys";
import { getRequest } from "utils/apiCalls";
import { agencyRequest, siteRequest } from "services/request";
import CustomTable from "components/CustomTable";
import { staffTableColumn } from "./model";

type GroupInfoProps = {
  goBack: () => void;
  data: Record<string, any>;
};
const GroupInfo = ({ goBack, data }: GroupInfoProps) => {
  const { isLoading: isLoadingRoles, data: agencyRoles = [] } = useQuery(
    [queryKeys.agencyRoles],
    async () =>
      getRequest({
        url: agencyRequest.GET_AGENCY_ROLES(data.siteData.agencyID),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading, data: siteStaffs = [] } = useQuery(
    [queryKeys.siteWorkers, data.siteData.siteID],
    async () =>
      getRequest({
        url: siteRequest.GET_SITE_WORKER(data.siteData.siteID),
      }),
    {
      enabled: !!data.siteData.siteID,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const getUserRole = (data) => {
    const roleId = data?.role?.[0]?.roleID?.toString();
    const userRole = agencyRoles?.find(
      (role) => role?.roleID?.toString() === roleId
    );
    return userRole?.roleName;
  };

  const column = [
    ...staffTableColumn,
    {
      accessorFn: (row) => getUserRole(row) ?? "N/A",
      id: "role",
      header: "Role",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
  ];

  const [modals, setModals] = useState({
    active: false,
    delete: false,
    addStaff: false,
    remove: false,
    edit: false,
  });
  // function displayModal(name: string) {
  //   setModals((prev) => ({
  //     ...prev,
  //     active: true,
  //     [name]: true,
  //   }));
  // }
  function hideModals() {
    Object.keys(modals).forEach((modal) => {
      setModals((prev) => ({
        ...prev,
        active: false,
        [modal]: false,
      }));
    });
  }

  return (
    <>
      <div className="flex px-4 pt-5">
        <div onClick={goBack} className="mr-5 mt-4 cursor-pointer">
          <BackIcon />
        </div>
        <div className="flex items-center">
          <Avatar size={60} color="#8353F7" radius={100}>
            {data?.siteData?.siteName.toUpperCase().substring(0, 1)}
          </Avatar>
          <div className="w-full ml-2">
            <p className="font-semibold text-sm mb-2 mt-2 capitalize">
              {data.siteData.siteName}
            </p>
            <div className="flex justify-between"></div>
          </div>
        </div>
        {/* <div className="w-[80px] h-[80px] bg-primary rounded-full shrink-0"></div>
        <div className="mr-auto ml-3 flex-1 sm:flex-auto">
          <div className="flex justify-between items-center">
            <p className="font-semibold">{data?.siteData?.siteName}</p>
            <button
              onClick={() => displayModal("addStaff")}
              className="px-2 py-2 text-xs sm:hidden"
            >
              Add Staff
            </button>
          </div>
          <span className="font-semibold">Nurses for Ada hospital</span>
          <div className="flex items-center mt-2">
            <button
              onClick={() => displayModal("edit")}
              className="outline-btn px-10 py-1 mr-10"
            >
              Edit
            </button>
            <span
              className="cursor-pointer"
              onClick={() => displayModal("delete")}
            >
              <DeleteIcon />
            </span>
          </div>
        </div> */}
        {/* <button
          onClick={() => displayModal("addStaff")}
          className="hidden sm:block rounded-lg bg-primary text-white h-fit px-12 py-2"
        >
          Add Staff
        </button> */}
      </div>
      <div className="ml-5 mt-8">
        <p className="mb-2">Group Members</p>
        <CustomTable
          loading={isLoading || isLoadingRoles}
          data={siteStaffs}
          columns={column}
        />
      </div>
      {/* <div className="border-b my-3"></div>
      <div className="grid grid-cols-3 sm:grid-cols-4 px-2 sm:px-5 mb-5">
        <p>Name</p>
        <p>Phone No.</p>
        <p>Role</p>
      </div>
      {members.map((member, index) => (
        <div
          className="grid grid-cols-3 sm:grid-cols-4 px-2 sm:px-5 mb-4 items-center"
          key={index}
        >
          <div className="flex items-center">
            <div className="h-10 shrink-0 w-10 rounded-full bg-primary"></div>
            <p className="text-xs sm:text-sm ml-2">Andrew Garfield</p>
          </div>
          <p className="text-xs sm:text-sm">+02 4677 888</p>
          <div>
            <p className="text-xs sm:text-sm">Account Manager</p>
            <p
              onClick={() => displayModal("remove")}
              className="text-xs sm:hidden text-red-600"
            >
              Remove
            </p>
          </div>
          <p
            onClick={() => displayModal("remove")}
            className=" hidden sm:block text-xs text-red-600"
          >
            Remove
          </p>
        </div>
      ))} */}
      {modals.active && (
        <>
          <div className="modal-background" onClick={hideModals}></div>
          {modals.delete && (
            <DeleteModal
              noHandler={hideModals}
              yesHandler={() => {}}
              title="Are you sure you want to delete this group?"
            />
          )}
          {modals.remove && (
            <DeleteModal
              noHandler={hideModals}
              yesHandler={() => {}}
              title="Are you sure you want to remove Joe James this group?"
            />
          )}
          {modals.edit && <GroupModal hideModal={hideModals} />}
          {modals.addStaff && <AddStaffModal hideModal={hideModals} />}
        </>
      )}
    </>
  );
};

export default GroupInfo;
