import { Checkbox, Input, Loader } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { eachDayOfInterval, format, getWeek } from "date-fns";
import useSendToAPI from "hooks/useSendToAPI";
import { successToast } from "hooks/useToast";
import { useRef, useState } from "react";
import { siteRequest } from "services/request";
import { getRequest, postRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";
import * as xlsx from "xlsx";
import { nanoid } from "nanoid";

const CompletedShift = ({
  shiftRange,
  start,
  end,
  currSite,
  user,
  isLoading,
}) => {
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [records, setRecords] = useState<Record<string, any>[]>([]);
  const [selectedID, setSelectedID] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const { isLoading: isLoadingItem } = useQuery(
    [
      queryKeys.internalValidation,
      user.agency.agencyID,
      currSite?.siteData.siteID,
      start,
      end,
    ],
    async () => {
      const startDate = shiftRange.current?.start
        ? format(shiftRange.current?.start, "yyyy-MM-dd")
        : format(start, "yyyy-MM-dd");
      const endDate = shiftRange.current?.end
        ? format(shiftRange.current?.end, "yyyy-MM-dd")
        : format(end, "yyyy-MM-dd");
      //   const startDate = "2022-11-12";
      //   const endDate = "2022-11-17";
      return getRequest({
        url: siteRequest.INTERNAL_VALIDATION({
          agencyId: user.agency.agencyID,
          siteId: currSite?.siteData.siteID,
          startDate,
          endDate,
        }),
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!currSite,
      onSuccess: (res) => {
        setRecords(res);
      },
    }
  );

  const onSuccess = (res) => {
    successToast(res || "Succssfully validated data");
  };

  const { mutate, isLoading: isValidating } = useSendToAPI(
    postRequest,
    onSuccess
  );

  const formatDateRange = () => {
    const intervals = eachDayOfInterval({
      start,
      end,
    });
    return intervals.map((date) => ({
      format: format(new Date(date), "dd/MM/yy"),
      day: format(new Date(date), "E"),
    }));
  };

  const formatRange = () => {
    const intervals = eachDayOfInterval({
      start,
      end,
    });
    return intervals.map(
      (date) =>
        `${format(new Date(date), "E")} ${format(new Date(date), "dd/MM/yy")}`
    );
  };

  const handleDownload = () => {
    let alink = document.createElement("a");
    const days = formatRange();
    const data = records.map((i) => {
      const shiftDayIDs = [0, 1, 2, 3, 4, 5, 6].map(
        (d) => i?.daysData?.[d]?.shiftDayID ?? 0
      );
      return {
        metaData: shiftDayIDs.toString(),
        workerID: i.workerID,
        workerName: i.workerName,
        [days[0]]: i?.daysData?.[0]?.hours ?? 0,
        [days[1]]: i?.daysData?.[1]?.hours ?? 0,
        [days[2]]: i?.daysData?.[2]?.hours ?? 0,
        [days[3]]: i?.daysData?.[3]?.hours ?? 0,
        [days[4]]: i?.daysData?.[4]?.hours ?? 0,
        [days[5]]: i?.daysData?.[5]?.hours ?? 0,
        [days[6]]: i?.daysData?.[6]?.hours ?? 0,
        totalWeekDay: i.totalWeekDay,
        totalWeekend: i.totalWeekend,
        totalHours: i.totalHours,
      };
    });

    const week = getWeek(start, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    });

    const siteName = currSite?.siteData.siteName;

    const header = [
      "Meta Data",
      "Worker ID",
      "Worker Name",
      days[0].split("-").toString(),
      days[1].split("-").toString(),
      days[2].split("-").toString(),
      days[3].split("-").toString(),
      days[4].split("-").toString(),
      days[5].split("-").toString(),
      days[6].split("-").toString(),
      "Total Hours",
      "Total WeekDay",
      "Total Weekend",
    ];
    const ws = xlsx.utils.book_new();
    const sheetNames = [`Week ${week}`];
    xlsx.utils.sheet_add_aoa(ws, [header]);
    xlsx.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    const wb = {
      Sheets: { [sheetNames[0]]: ws },
      SheetNames: sheetNames,
    };
    const excelBuffer = xlsx.write(wb, {
      bookType: "xlsx",
      type: "array",
      // type: "binary",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    const fileURL = window.URL.createObjectURL(finalData);
    alink.href = fileURL;
    alink.download = `${siteName}_Week-${week}.xlsx`;
    alink.click();
  };

  const formatValidationData = () => {
    const days = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];
    const data: unknown[] = [];
    if (uploadedData.length > 0) {
      for (let x of uploadedData) {
        const metaData = x?.["Meta Data"]?.split(",");
        const isSelected = selectedID.includes(x?.["Worker ID"]);
        if (isSelected) {
          metaData.forEach((i, idx) => {
            if (i !== "0") {
              const currDay = days[idx];
              const curr = Object.entries(x).find(([key, value]) =>
                key.includes(currDay)
              );
              const format = {
                shiftDayID: i,
                validatedby: user.staff.staffEmail,
                validatedHours: curr?.[1] ?? 0,
              };
              data.push(format);
            }
          });
        }
      }
    } else {
      for (let x of records) {
        const daysData = x?.daysData;
        const isSelected = selectedID.includes(x.workerID);
        if (isSelected) {
          daysData.forEach((i) => {
            const format = {
              shiftDayID: i.shiftDayID,
              validatedby: user.staff.staffEmail,
              validatedHours: i.hours,
            };
            data.push(format);
          });
        }
      }
    }
    return data;
  };

  const handleValidation = () => {
    const data = formatValidationData();
    // api call
    mutate({
      url: siteRequest.SHIFT_VALIDATION,
      data: { validator: data },
    });
  };

  const renderUploadedData = () => {
    return uploadedData.map((item, index) => {
      return (
        <div
          className="grid grid-cols-12 px-4 py-1 items-center"
          key={nanoid()}
        >
          <div className="flex pb-3">
            <Checkbox
              checked={selectedID.includes(item?.["Worker ID"])}
              onChange={(e) => {
                const position = selectedID.indexOf(item?.["Worker ID"]);
                if (position === -1) {
                  setSelectedID([...selectedID, item?.["Worker ID"]]);
                } else {
                  let data = [...selectedID];
                  data.splice(position, 1);
                  setSelectedID(data);
                }
              }}
            />
            <p className="text-sm ml-3">{item?.["Worker ID"]}</p>
          </div>
          <p className="text-sm pb-3 capitalize">{item?.["Worker Name"]}</p>
          {["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"].map((i, idx) => {
            let curr =
              Object.entries(item).find(([key, value]) => key.includes(i)) ??
              {};

            return (
              <div key={nanoid()} className="w-fit mr-2">
                <Input
                  classNames={{ input: "p-2" }}
                  type="number"
                  value={curr[1]}
                  disabled={true}
                  onChange={(e) => {}}
                />
              </div>
            );
          })}
          <p className="text-sm pb-3">{item?.["Total WeekDay"]}</p>
          <p className="text-sm pb-3">{item?.["Total Weekend"]}</p>
          <p className="text-sm pb-3">{item?.["Total Hours"]}</p>
        </div>
      );
    });
  };
  const renderServerData = () => {
    return records.map((record, index) => {
      const daysData = record?.daysData ?? [];
      const weekdays = [1, 2, 3, 4, 5].reduce((total, i, idx) => {
        if (daysData[idx]?.day === i) {
          const value = daysData[idx].hours;
          return total + Number(value);
        } else return total;
      }, 0);
      const weekends = [6, 7].reduce((total, i, idx) => {
        if (daysData[idx]?.day === i) {
          const value = daysData[idx].hours;
          return total + Number(value);
        } else return total;
      }, 0);
      return (
        <div
          className="grid grid-cols-12 px-4 py-1 items-center"
          key={nanoid()}
        >
          <div className="flex pb-3">
            <Checkbox
              checked={selectedID.includes(record?.workerID)}
              onChange={(e) => {
                const position = selectedID.indexOf(record?.workerID);
                if (position === -1) {
                  setSelectedID([...selectedID, record?.workerID]);
                } else {
                  let data = [...selectedID];
                  data.splice(position, 1);
                  setSelectedID(data);
                }
              }}
            />
            <p className="text-sm ml-3">{record?.workerID}</p>
          </div>
          <p className="text-sm pb-3 capitalize">{record?.workerName}</p>
          {[1, 2, 3, 4, 5, 6, 7].map((i, idx) => {
            const currDay = daysData[idx]?.day === i ? daysData[idx] : {};
            const value = currDay?.hours;
            return (
              <div key={nanoid()} className="w-fit mr-2">
                <Input
                  classNames={{ input: "p-2" }}
                  type="string"
                  value={value ?? "---"}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (!value) return;
                    const newDays = daysData.map((x) => {
                      if (currDay?.shiftDayID === x?.shiftDayID) {
                        return { ...currDay, hours: newValue };
                      } else return x;
                    });
                    const newRecords = records.map((r) => {
                      if (record.workerID === r.workerID) {
                        return { ...record, daysData: newDays };
                      } else return r;
                    });
                    setRecords(newRecords);
                  }}
                />
              </div>
            );
          })}

          {/* <p className="text-sm pb-3">{record?.totalWeekend}</p> */}
          <p className="text-sm pb-3">{weekends.toFixed(2)}</p>
          {/* <p className="text-sm pb-3">{record?.totalWeekDay}</p> */}
          <p className="text-sm pb-3">{weekdays.toFixed(2)}</p>
          {/* <p className="text-sm pb-3">{record?.totalHours}</p> */}
          <p className="text-sm pb-3">{(weekends + weekdays).toFixed(2)}</p>
        </div>
      );
    });
  };

  return (
    <>
      {isLoading ||
        (isLoadingItem && (
          <>
            <div className="w-screen h-screen fixed top-0 left-0 bg-text opacity-20 z-10"></div>
            <div className="fixed left-[50%] flex justify-center mt-5 z-50">
              <Loader size="lg" color="blue" />
            </div>
          </>
        ))}
      <div className="border rounded-lg ">
        <div className="col-span-12">
          <div className="grid grid-cols-12 border-b py-3 px-4">
            <div className="flex mt-1">
              <Checkbox
                onChange={() => {
                  const data = uploadedData.length > 0 ? uploadedData : records;
                  if (data.length !== selectedID.length) {
                    const ids = data.map(
                      (item) => item?.["Worker ID"] || item?.workerID
                    );
                    setSelectedID(ids);
                  } else setSelectedID([]);
                }}
              />
              <p className="text-sm ml-3">ID</p>
            </div>
            <p>Name</p>
            {formatDateRange().map((i, idx) => (
              <div key={nanoid()}>
                <p>{i.day}</p>
                <p>{i.format}</p>
              </div>
            ))}
            <p>Weekday</p>
            <p>Weekend</p>
            <p>Total (hrs)</p>
          </div>
          {uploadedData.length > 0 ? renderUploadedData() : renderServerData()}
        </div>
      </div>
      <div className=" flex gap-5 items-center justify-between mt-5">
        <div className="flex gap-3 items-center">
          <input
            ref={inputRef}
            className="hidden"
            type="file"
            onChange={(e) => {
              if (e.target.files) {
                const reader = new FileReader();
                reader.onload = (e) => {
                  const data = e.target?.result;
                  const workbook = xlsx.read(data, { type: "array" });
                  const sheetName = workbook.SheetNames[0];
                  const worksheet = workbook.Sheets[sheetName];
                  const json = xlsx.utils.sheet_to_json(worksheet);
                  setUploadedData(json);
                };
                reader.readAsArrayBuffer(e.target.files[0]);
              }
            }}
          />
          <p className="text-black">
            Note: Please look through before submitting. Data not accurate?
          </p>
          <button
            className="bg-white border border-primary text-primary px-6 py-2 rounded-md"
            onClick={() => inputRef.current?.click()}
          >
            Upload
          </button>
        </div>
        <div className="flex gap-3 ">
          <button
            className="bg-white border border-primary text-primary px-6 py-2 rounded-md"
            onClick={handleDownload}
          >
            Export Data
          </button>
          <button
            className="bg-primary border border-primary text-white px-6 py-2 rounded-md min-w-[180px]"
            disabled={selectedID.length === 0 || isValidating}
            onClick={handleValidation}
          >
            <span className="flex justify-center text-white">
              {isValidating ? (
                <Loader color="white" size="sm" />
              ) : (
                "Validate Selected"
              )}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CompletedShift;
