import {
  Button,
  Checkbox,
  Group,
  MultiSelect,
  Radio,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BackIcon } from "components/Svgs";
import useSendToAPI from "hooks/useSendToAPI";
import { successToast } from "hooks/useToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { agencyRequest, siteRequest } from "services/request";
import { IUser } from "types";
import { getRequest, postRequest, putRequest } from "utils/apiCalls";
import { allPermissions } from "utils/constants";
import { queryKeys } from "utils/queryKeys";

type IProps = {
  hideModals: () => void;
  user: IUser;
  modalData: Record<string, any>;
};

const UpdateRoleModal = ({ hideModals, user, modalData }: IProps) => {
  const queryClient = useQueryClient();
  const [siteIDs, setSiteIDs] = useState<number[]>([]);

  useEffect(() => {
    const format = modalData.siteValidationIDs?.split(",") ?? [];
    const ids = format.map((id) => Number(id));
    setSiteIDs(ids);
    // const format = modalData?.rolePermissions?.split(",") ?? [];
    // const tempIDs: number[] = [];
    // format.forEach((i: string) => {
    //   if (i.includes("validations")) {
    //     const curr = i.replace("[", "").split(":");
    //     const ids = JSON.parse(curr[1]);
    //     tempIDs.push(Number(ids));
    //   }
    //   if (i.includes("]")) {
    //     const curr = i.replace("]", "");
    //     tempIDs.push(Number(curr));
    //   }
    // });
    // setSiteIDs(tempIDs);
  }, [modalData]);

  const { data: allSites = [] } = useQuery(
    [queryKeys.allAgencySites],
    async () =>
      getRequest({ url: siteRequest.GET_AGENCY_SITES(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      // onSuccess: () => {
      //   // const format = modalData?.rolePermissions?.split(",") ?? [];
      //   const siteValidationids =
      //     user?.role.siteValidationIDs?.split(",") ?? [];
      //   const tempIDs: number[] = [];
      //   siteValidationids.forEach((id: string) => {
      //     // if (i.includes("validations")) {
      //     //   const curr = i.replace("[", "").split(":");
      //     //   const ids = JSON.parse(curr[1]);
      //     //   tempIDs.push(Number(ids));
      //     // }
      //     // if (i.includes("]")) {
      //     //   const curr = i.replace("]", "");
      //     //   tempIDs.push(Number(curr));
      //     // }
      //     tempIDs.push(Number(id));
      //     console.log({ siteValidationids });
      //   });
      //   setSiteIDs(tempIDs);
      // },
    }
  );

  const formatSiteData = useMemo(() => {
    return allSites.map((site) => ({
      label: site?.siteData.siteName,
      value: site?.siteData.siteID,
    }));
  }, [allSites]);

  const formatValidations = useCallback((value) => {
    const res: string[] = [];
    const format = value?.split(",") ?? [];
    format.forEach((i: string) => {
      if (i.includes("validations")) {
        res.push("validations");
      } else res.push(i);
    });
    return res;
  }, []);

  const form = useForm({
    initialValues: {
      roleName: modalData.roleName ?? "",
      agencyID: user.agency.agencyID ?? "",
      roleID: modalData.roleID ?? "",
      permissions: formatValidations(modalData?.rolePermissions),
      roleType: modalData?.serviceWorkerRole?.toString() ?? "",
    },
    validate: {
      roleName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
    },
  });

  const onSuccess = (res) => {
    queryClient.invalidateQueries([queryKeys.agencyRoles]);
    successToast(`Roles successfully updated`);
    hideModals();
  };

  const { mutate: createRole, isLoading: isAddingRole } = useSendToAPI(
    postRequest,
    onSuccess
  );
  const { mutate: updateRole, isLoading: isUpdatingRole } = useSendToAPI(
    putRequest,
    onSuccess
  );

  const handleSubmit = (values) => {
    const hasValidation = values.permissions.includes("validations");
    let temp = values.permissions.join(",");
    // const ids = siteIDs.join(",");

    if (hasValidation && siteIDs.length === 0) return;
    // if (hasValidation) {
    //   temp = temp.replace("validations", `validations:[${ids}]`);
    // }
    if (values.permissions.includes("all")) temp = "all";
    const payload: Record<string, string | boolean> = {
      agencyID: values.agencyID,
      roleName: values.roleName,
      isServiceWorkerRole: values.roleType === "true",
      permissions: temp,
      siteValidationIDs: siteIDs.join(),
      // permissions: temp.replace("validations", `validations:[${337}]`),
    };

    if (values.roleID.toString().length > 0) {
      payload.roleID = values.roleID;

      updateRole({
        url: agencyRequest.UPDATE_ROLE,
        data: payload,
      });
    } else {
      createRole({
        url: agencyRequest.ADD_ROLE,
        data: payload,
      });
    }
  };

  return (
    <>
      <div className="modal-background" onClick={hideModals}></div>
      <div className="fixed-modal max-w-[95%] px-8 sm:px-12 pt-8 w-[60%]">
        <div
          className="fixed left-3 sm:left-6 cursor-pointer top-10"
          onClick={hideModals}
        >
          <BackIcon />
        </div>
        <form className="w-full p-0" onSubmit={form.onSubmit(handleSubmit)}>
          <div className="mb-6 mt-8">
            <TextInput
              size="lg"
              type="text"
              label="Role Name"
              placeholder="New role name"
              {...form.getInputProps("roleName")}
            />
          </div>
          <div className="">
            <p className="border-b mb-2 pb-2">Role Type:</p>
            <Radio.Group {...form.getInputProps("roleType")}>
              <Group>
                <Radio value="true" label="Service Worker" />
                <Radio value="false" label="Non Service worker" />
              </Group>
            </Radio.Group>
          </div>
          <div className="mt-6">
            <p className="border-b mb-2 pb-2">Permissions</p>

            <Checkbox.Group {...form.getInputProps("permissions")}>
              <Group>
                <Checkbox value={allPermissions.all} label="All" />
                <Checkbox value={allPermissions.staff} label="Staff" />
                <Checkbox value={allPermissions.shifts} label="Shifts" />
                <Checkbox
                  value={allPermissions.financials}
                  label="Financials"
                />
                <Checkbox
                  value={allPermissions.editAgencyInfo}
                  label="Edit Agency Info"
                />
                <Checkbox
                  value={allPermissions.createUser}
                  label="Create User"
                />
                <Checkbox
                  value={allPermissions.createRoles}
                  label="Create Roles"
                />
                <Checkbox
                  value={allPermissions.activityLog}
                  label="Activity Log"
                />
                <Checkbox value={allPermissions.timesheet} label="Timesheet" />
                <Checkbox
                  value={allPermissions.validations}
                  label="Validations"
                />
              </Group>
            </Checkbox.Group>
          </div>
          {form.values.permissions.includes(allPermissions.validations) && (
            <div className="mt-6">
              <MultiSelect
                id="validations"
                className="text-primary"
                searchable
                nothingFound="Nothing found"
                data={formatSiteData}
                label="Select multiple sites user can validate"
                placeholder="Select site"
                clearable
                maxDropdownHeight={200}
                classNames={{
                  defaultValueLabel: "text-black",
                  label: "mb-2",
                }}
                value={siteIDs as any}
                onChange={setSiteIDs as any}
              />
            </div>
          )}

          <div className="w-full mt-6">
            <Button type="submit" loading={isAddingRole || isUpdatingRole}>
              Done
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateRoleModal;
