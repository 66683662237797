import { useState } from "react";
import { nanoid } from "nanoid";
import {
  ActionIcon,
  Button,
  ColorInput,
  Group,
  Select,
  TextInput,
} from "@mantine/core";
import { DeleteModal } from "./DeleteModal";
import { BackIcon, DeleteIcon } from "./Svgs";
import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";
import { useSiteValidation } from "hooks/useValidation";
import useFetchPostcode from "hooks/useFetchPostcode";
import { siteRequest } from "services/request";
import useSendToAPI from "hooks/useSendToAPI";
import { deleteRequest, postRequest, putRequest } from "utils/apiCalls";
import { successToast } from "hooks/useToast";
import { ISiteData } from "types";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "utils/queryKeys";

type SiteCardProps = {
  agencyID: string | number;
  hideModal: () => void;
  edit?: boolean;
  data?: ISiteData;
};

const CreateSiteCard = ({
  hideModal,
  edit,
  agencyID,
  data: siteData,
}: SiteCardProps) => {
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [postcode, setPostcode] = useState(
    siteData?.siteData.sitePostCode ?? ""
  );
  const { isLoading, data } = useFetchPostcode(postcode);

  const form = useSiteValidation(siteData ?? ({} as ISiteData));

  const onSuccess = (res) => {
    successToast(`Site ${edit ? "edited" : "created"} successfully`);
    queryClient.invalidateQueries([queryKeys.allAgencySites]);
    hideModal();
  };
  const onDeleteSuccess = (res) => {
    queryClient.invalidateQueries([queryKeys.allAgencySites]);
    successToast(`Site deleted successfully`);
    hideModal();
  };

  const requestType = edit ? putRequest : postRequest;
  const { mutate, isLoading: loading } = useSendToAPI(requestType, onSuccess);
  const { mutate: deleteSite, isLoading: isDeleting } = useSendToAPI(
    deleteRequest,
    onDeleteSuccess
  );

  const handleSubmit = (values: any) => {
    if (edit) {
      const payload = {
        // siteData: {
        ...values,
        agencyID,
        siteID: siteData?.siteData.siteID,
        siteLatitude: siteData?.siteData.siteLatitude ?? "",
        siteLongitude: siteData?.siteData.siteLongitude ?? "",
        timeSheetRequired: siteData?.siteData.timeSheetRequired,
        // },
        contactPersons: values.contactPersons.map(
          ({ key, createdAt, updatedAt, siteID, ...others }) => others
        ),
      };
      mutate({ url: siteRequest.EDIT_SITE, data: payload });
    } else {
      const payload = {
        ...values,
        agencyID,
        siteLatitude: data?.geocode?.lat ?? "",
        siteLongitude: data?.geocode?.lng ?? "",
        contactPersons: values.contactPersons.map(
          ({ key, ...others }) => others
        ),
      };
      mutate({ url: siteRequest.CREATE_SITE, data: payload });
    }
  };

  const handleDelete = () => {
    deleteSite({ url: siteRequest.DELETE_SITE(siteData?.siteData.siteID) });
  };

  const handleAddInput = () => {
    form.insertListItem("contactPersons", {
      key: nanoid(),
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhone: "",
    });
  };

  const handleRemoveInput = (index) => {
    if (form.values.contactPersons.length > 1) {
      form.removeListItem("contactPersons", index);
    }
  };

  const fields = form.values.contactPersons.map((item, index) => (
    <Group key={item.key} mt="xs">
      <TextInput
        size="lg"
        type="text"
        label="Contact Person"
        placeholder="e.g John"
        {...form.getInputProps(`contactPersons.${index}.contactPersonName`)}
      />
      <TextInput
        size="lg"
        type="email"
        label="Email"
        placeholder="name@example.com"
        {...form.getInputProps(`contactPersons.${index}.contactPersonEmail`)}
      />
      <TextInput
        size="lg"
        type="text"
        label="Phone number"
        placeholder="e.g +2222222"
        {...form.getInputProps(`contactPersons.${index}.contactPersonPhone`)}
      />
      <ActionIcon color="red" onClick={() => handleRemoveInput(index)}>
        <AiFillCloseCircle fontSize="20px" color="red" />
      </ActionIcon>
    </Group>
  ));

  const formatAddessOption = () => {
    const lists = Array.isArray(data?.delivery_points)
      ? data.delivery_points
      : [];
    return lists.map(({ line_1, line_2 }) => `${line_1} ${line_2}`);
  };

  return (
    <>
      <div
        className="w-screen h-screen fixed top-0 left-0 bg-text opacity-40 z-10"
        onClick={hideModal}
      ></div>
      {!showDeleteModal && (
        <div className="fixed px-3 top-0 right-1/2 w-fit max-w-full h-fit z-50 translate-x-1/2 py-5">
          <form
            className="bg-white max-h-[90vh] overflow-scroll relative border max-w-full  w-[50vw] border-text mx-auto px-9 sm:px-10 rounded-[15px]"
            onSubmit={form.onSubmit(handleSubmit)}
          >
            <div
              className="fixed left-6 cursor-pointer top-[70px]"
              onClick={hideModal}
            >
              <BackIcon />
            </div>
            <div className="mb-5">
              <h1 className="my-2 text-[20px] font-bold">Site Details</h1>
              <hr />
            </div>
            <div className="flex justify-between flex-wrap">
              <div>
                <TextInput
                  size="lg"
                  type="text"
                  label="Site Name"
                  placeholder="Site Name"
                  {...form.getInputProps("siteName")}
                />
              </div>
              <div>
                <TextInput
                  size="lg"
                  type="text"
                  label="Post Code"
                  placeholder="EC3N 4AB"
                  name="sitePostCode"
                  value={postcode}
                  error={form.errors.sitePostCode}
                  onChange={(e) => {
                    setPostcode(e.target.value);
                    form.setFieldValue("sitePostCode", e.target.value);
                    form.setFieldValue("siteAddress", "");
                  }}
                />
              </div>
              <div>
                <label className="label">Address</label>
                <Select
                  nothingFound={
                    isLoading && postcode.length > 0
                      ? "Loading..."
                      : "No options"
                  }
                  placeholder="Select address"
                  data={formatAddessOption()}
                  defaultValue={siteData?.siteData.siteAddress ?? ""}
                  {...form.getInputProps("siteAddress")}
                />
              </div>
            </div>
            <div className="my-5">
              <h1 className="my-2 text-[20px] font-bold">Contact Details</h1>
              <hr />
            </div>
            {fields}
            <div
              className="mb-3 flex w-fit items-center cursor-pointer"
              onClick={handleAddInput}
            >
              <p>Add more</p>
              <div className="shadow-sm mx-1">
                <AiFillPlusCircle color="#8353F7" fontSize="20px" />
              </div>
            </div>
            <hr />
            <div className="my-3 max-w-[300px] ">
              <p>Select tag</p>
              <ColorInput
                placeholder="Pick color"
                label=""
                className="w-full rounded"
                style={{
                  height: "50px",
                  borderRadius: "20px",
                }}
                {...form.getInputProps("colorTag")}
              />
              <div className="flex items-center">
                <Button
                  type="submit"
                  loading={loading}
                  styles={(theme) => ({
                    root: {
                      border: 0,
                      height: "55px !important",
                    },
                  })}
                >
                  Done
                </Button>
                {edit && (
                  <div
                    className=" ml-4 flex items-center  cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
      {showDeleteModal && (
        <DeleteModal
          title={`"Are you sure you want to delete ${siteData?.siteData.siteName}"`}
          yesHandler={handleDelete}
          loading={isDeleting}
          noHandler={hideModal}
        />
      )}
    </>
  );
};

export default CreateSiteCard;
