/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "@mantine/core";
import {
  AttachmentIcon,
  EmojiIcon,
  InfoIcon,
  MenuIcon,
  // SearchIcon,
} from "components/Svgs";
import MessageBox from "./MessageBox";

const MessageSection = ({
  subscriber,
  activeTab,
  activeGroupChatInfo,
  activeSingleChatInfo,
  user,
  allMessages,
  agencyStaff,
  setView,
  markAsRead,
  lastReadTimestamp,
}) => {
  const [value, setValue] = useState("");
  const messagesRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
    // SET NEW TIMESTAMP FOR THE ACTIVE CHANNEL WHEN NEW THERE IS NEW MESSAGE
    markAsRead();
  }, [allMessages]);

  useEffect(() => {
    markAsRead();
  }, [subscriber.connected]);

  function showChats() {
    const chatsContainer = document.getElementById("chats");
    chatsContainer?.classList.add("slideIn");
  }

  function hideChats() {
    const chatsContainer = document.getElementById("chats");
    chatsContainer?.classList.remove("slideIn");
  }

  const handleSend = () => {
    if (subscriber.stompClient?.connected) {
      let path = activeTab;
      if (activeTab === "public") {
        const isAgency =
          activeGroupChatInfo?.siteData?.siteID.toString() ===
          user.agency.agencyID.toString();
        path = !isAgency ? "sites" : path;
      }
      const payload = {
        senderID: user.staff.staffID,
        channelID: activeGroupChatInfo?.siteData?.siteID,
        messageSource: "agency",
        messageDestination: path,
        channelPath: `/channel/chat/${path}/`,
        messageText: value,
        createdAt: new Date(),
      };
      if (value.length > 0) {
        subscriber.stompClient?.send(
          "/app/channel/send",
          {},
          JSON.stringify(payload)
        );
      }
    }
    setValue("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && value.length > 0) {
      handleSend();
    }
  };

  const renderChatHeader = () => {
    const isAgency =
      activeGroupChatInfo?.siteData?.siteID === user.agency.agencyID;
    return (
      <>
        {activeTab !== "public" && (
          <Avatar
            src={activeSingleChatInfo?.pictureLink}
            size={50}
            alt="user"
            radius={100}
          />
        )}
        {activeTab === "public" && isAgency && (
          <Avatar src={user.agencyLogo} size={50} alt="user" radius={100} />
        )}
        {activeTab === "public" && !isAgency && (
          <Avatar size={50} color="#8353F7" radius={100}>
            {activeGroupChatInfo?.siteData?.siteName
              .toUpperCase()
              .substring(0, 1)}
          </Avatar>
        )}
        <p className="font-semibold ml-2 mr-auto">
          {activeTab === "public"
            ? activeGroupChatInfo?.siteData?.siteName
            : `${activeSingleChatInfo?.user?.userFirstname} ${activeSingleChatInfo?.user?.userLastname}`}
        </p>
      </>
    );
  };

  return (
    <div className="h-full">
      {/* <div className="h-[90%] relative overflow-auto"> */}
      <div className="h-[90%]  overflow-auto">
        <div
          className="sm:hidden absolute z-[5] left-4 top-6"
          onClick={showChats}
        >
          <MenuIcon />
        </div>
        <div onClick={hideChats} className="h-[100%] relative">
          <div className="sticky top-0  z-[5] w-full">
            <div className="flex  px-4 py-3 border-b bg-white items-center">
              {renderChatHeader()}
              <div className="flex items-center">
                {/* <div className=" mt-1">
                  <SearchIcon />
                </div> */}
                {activeTab === "public" && (
                  <div
                    className="ml-4 w-fit cursor-pointer"
                    onClick={() => setView("info")}
                  >
                    <InfoIcon />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <p className="text-center mt-2">June 23, 2022</p> */}
          <div className="px-4 mb-6 h-[80vh]">
            <MessageBox
              messages={
                allMessages?.[activeGroupChatInfo?.siteData?.siteID] ?? []
              }
              users={agencyStaff}
              currUserID={user.staff.staffID}
              lastReadTimestamp={
                lastReadTimestamp[activeGroupChatInfo?.siteData?.siteID]
              }
            />
            <div ref={messagesRef}></div>
          </div>
        </div>
      </div>
      <div className="absolute bg-gray-50 bottom-0 left-0 w-full h-[10%] flex px-2 sm:px-6 items-center">
        <EmojiIcon />
        <div className="ml-3 mr-4">
          <AttachmentIcon />
        </div>
        <input
          placeholder="Start a message"
          type="text"
          className="flex-1 bg-gray-50 rounded-none mr-6 border-t-0 pl-0 border-x-0 border-b-2 focus:outline-none"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button className="px-2 sm:px-6 text-xs " onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default React.memo(MessageSection);
