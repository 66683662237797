import { useMutation } from "@tanstack/react-query";
import { errorToast } from "./useToast";

// TODO: write JSDocs for this function
const useSendToAPI = (request, onSuccess?, onError?): any => {
  return useMutation(request, {
    onSuccess: (res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    },

    onError: (error: any) => {
      console.log({ err: error?.response?.data });

      if (onError) {
        onError(error);
      } else {
        const err = error as Record<any, any>;
        const message = err?.response?.data?.error;
        errorToast(`${message ?? "An error occured"}`);
      }
    },
  });
};

export default useSendToAPI;
