import { Button, Select, Textarea, TextInput } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BackIcon } from "components/Svgs";
import useSendToAPI from "hooks/useSendToAPI";
import { successToast } from "hooks/useToast";
import { useUpdateAgencyValidation } from "hooks/useValidation";
import { useState } from "react";
import { agencyRequest } from "services/request";
import { IUser } from "types";
import { useDebounce } from "use-debounce";
import { fetchifyRequest, putRequest } from "utils/apiCalls";
import { queryKeys } from "utils/queryKeys";

type EditCompanyProps = {
  hideModals: () => void;
  user: IUser;
};
const EditCompanyProfile = ({ hideModals, user }: EditCompanyProps) => {
  const queryClient = useQueryClient();
  const [postcode, setPostcode] = useState<string>(user.agency.agencyPostCode);
  const [geocode, setGeocode] = useState<Record<string, string>>({});
  const debouncedPostcode = useDebounce(postcode, 200);

  const { isLoading, data } = useQuery(
    [queryKeys.listOfAddress, debouncedPostcode],
    async () => fetchifyRequest({ postcode: debouncedPostcode[0] }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(debouncedPostcode[0]),
      onSuccess: (data) => {
        setGeocode(data?.geocode ?? {});
      },
    }
  );
  const form = useUpdateAgencyValidation(user);

  const formatAddessOption = () => {
    const lists = Array.isArray(data?.delivery_points)
      ? data.delivery_points
      : [];
    return lists.map(({ line_1, line_2 }) => `${line_1} ${line_2}`);
  };

  const onSuccess = (res) => {
    queryClient.invalidateQueries([queryKeys.allAgencySites]);
    successToast(`Agency record updated successfully`);
    hideModals();
  };

  const { mutate, isLoading: loading } = useSendToAPI(putRequest, onSuccess);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      agencyLatitude: geocode.lat,
      agencyLongitude: geocode.lng,
    };
    mutate({
      url: agencyRequest.EDIT_AGENCY_DATA,
      data: payload,
    });
  };

  return (
    <div className="fixed-modal relative py-3 px-8 sm:px-10 max-h-[90vh] overflow-y-auto w-[450px] max-w-[95vw]">
      <div
        className="absolute left-6 cursor-pointer top-[70px]"
        onClick={hideModals}
      >
        <BackIcon />
      </div>
      <form className="w-full p-0" onSubmit={form.onSubmit(handleSubmit)}>
        <div className="mb-6">
          <TextInput
            size="lg"
            type="text"
            label="Name"
            placeholder="First name and Last name"
            {...form.getInputProps("agencyName")}
          />
        </div>
        <div className="mb-6">
          <TextInput
            size="lg"
            type="email"
            label="Email"
            placeholder="name@example.com"
            {...form.getInputProps("agencyEmail")}
          />
        </div>
        <div className="mb-6">
          <TextInput
            size="lg"
            type="number"
            label="Phone Number"
            placeholder="Company / personal phone number"
            {...form.getInputProps("agencyPhone")}
          />
        </div>
        <div className="mb-6">
          <TextInput
            size="lg"
            name="agencyPostCode"
            type="text"
            label="Post Code"
            placeholder="Post Code"
            value={postcode}
            onChange={(e) => {
              setPostcode(e.target.value);
              form.setFieldValue("agencyPostCode", e.target.value);
              form.setFieldValue("agencyAddress", "");
            }}
          />
        </div>
        <div className="w-full mb-6">
          <label className="label">Address</label>
          <Select
            nothingFound={
              isLoading && postcode.length > 0 ? "Loading..." : "No options"
            }
            placeholder="Select address"
            data={formatAddessOption()}
            defaultValue={user.agency.agencyAddress ?? ""}
            {...form.getInputProps("agencyAddress")}
          />
        </div>
        <div className="mb-6">
          <Textarea
            placeholder="Brief description of the company"
            label="About the Company"
            rows={4}
            cols={50}
            {...form.getInputProps("agencyAbout")}
          />
        </div>
        <Button type="submit" loading={loading}>
          Done
        </Button>
      </form>
    </div>
  );
};
export default EditCompanyProfile;
