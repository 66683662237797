import { Doughnut } from "react-chartjs-2";
import { MenuIcon } from "./Svgs";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { IoReorderThreeOutline } from "react-icons/io5";

const ProgressCard = ({
  name = "",
  value = 0,
  percentage = "0",
  color = "",
  progress = "",
}) => {
  const percent = Number(percentage) || 0;
  const diff = 100 - Math.abs(percent);
  const dataset = [percent, diff];
  const colors = color !== "" ? color : "rgb(54, 162, 235)";

  const data = {
    datasets: [
      {
        label: "My First Dataset",
        // data: [30, 50],
        data: dataset,
        borderRadius: 20,
        backgroundColor: [colors, "#F9F9F9"],
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  const renderProgress = () => {
    if (progress === "up") return <FaArrowUp color="green" size={15} />;
    if (progress === "down") return <FaArrowDown color="#FF5C5C" size={15} />;
    return <IoReorderThreeOutline color="#A9A9A9" size={15} />;
  };
  return (
    // <div className="w-full border p-5 pb-4 border-[#F0F0FF] rounded-[15px]">
    <div className="w-[200px] mr-3 border p-5 pb-4 border-[#F0F0FF] rounded-[15px]">
      <p>{name}</p>
      {/* <div className="flex flex-wrap justify-between items-center"> */}
      <div className="flex justify-between items-center">
        <p className="text-3xl font-semibold">{value}</p>
        <div className="h-20 w-20 relative">
          {percentage !== "NaN" && percentage !== "0" ? (
            <p className="absolute top-[25px] right-[18px] text-[15px] text-green-500">
              {percentage}%
            </p>
          ) : (
            <p className="absolute top-[25px] right-[27px] text-[15px] text-green-500">
              0%
            </p>
          )}
          <Doughnut data={data} options={{ cutout: "80%" }} />
        </div>
      </div>
      {/* <ArrowDownIcon /> */}
      {/* <FaArrowUp color="green" size={15} />
      <FaArrowDown color="#FF5C5C" size={15} /> */}
      {renderProgress()}
    </div>
  );
};

export const MobileMenu = () => {
  const showSidebar = () => {
    const sideBarElement = document.getElementById("sidebar");
    sideBarElement?.classList.add("slideIn");
  };
  return (
    <div className="block sm:hidden mr-3 cursor-pointer" onClick={showSidebar}>
      <MenuIcon />
    </div>
  );
};

export default ProgressCard;
