const DEV_PORT = 9081;
// const PROD_PORT = 8081

const config = {
  // REACT_APP_API_BASE_URL: `https://zshift.io:8081/app/api`,
  // REACT_APP_API_MESSAGING: "https://zshift.io:8081",
  // REACT_APP_API_BASE_URL: `https://zshift.io:${PROD_PORT}/app/api`,
  // REACT_APP_API_MESSAGING: `https://zshift.io:${PROD_PORT}`,
  REACT_APP_API_BASE_URL: `https://zshift.io:${DEV_PORT}/app/api`,
  REACT_APP_API_MESSAGING: `https://zshift.io:${DEV_PORT}`,
  REACT_APP_WEB_SOCKET_URL: "https://zshift.io:8082/ws",
  REACT_APP_API_PAYMENT: "https://zshift.io:8083/app/api",
  REACT_APP_GOOGLE_ID: "AIzaSyCPoqPsSQB6wVCMF4-3xFmDNMEn-JeLCa4",
  REACT_APP_FETCHIFY_ACCESS_TOKEN: "bc83a-3bfbe-14bfb-07989",
  REACT_APP_PUBLIC_POSTHOG_KEY:
    "phc_GZ6wOp0IP4dBhkCqmNjiv5EHJDiYuUMuxGD4YRHHXoe",
  // REACT_APP_PUBLIC_POSTHOG_HOST: "https://app.zshift.io",
  REACT_APP_PUBLIC_POSTHOG_HOST: "https://eu.posthog.com",
};

export default config;
