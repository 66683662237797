import axios from "axios";
import axiosInstance, { baseURL } from "services/httpService";
import config from "config";
import { sessionInfo } from "utils/constants";

export const fetchifyRequest = async (data) => {
  const reqUrl = "https://pcls1.craftyclicks.co.uk/json/rapidaddress";
  const reqData = {
    include_geocode: "true",
    // key: process.env.REACT_APP_FETCHIFY_ACCESS_TOKEN,
    key: config.REACT_APP_FETCHIFY_ACCESS_TOKEN,
    response: "data_formatted",
    ...data,
  };
  const response = await axios.post(reqUrl, reqData);
  return response?.data || response;
};

export const postRequest = async ({ url, data }) => {
  const response = await axiosInstance.post(url, data);
  return response?.data || response;
};

export const putRequest = async ({ url, data }) => {
  const response = await axiosInstance.put(url, data);
  return response?.data || response;
};
export const patchRequest = async ({ url, data }) => {
  const response = await axiosInstance.patch(url, data);
  return response?.data || response;
};

export const getRequest = async ({ url }) => {
  const response = await axiosInstance.get(url);
  return response?.data || response;
};

export const deleteRequest = async ({ url, data }) => {
  const config = { data };
  const response = await axiosInstance.delete(url, config);
  return response?.data || response;
};

export const fetchPDFStream = async ({ url }) => {
  const session = JSON.parse(
    (typeof window === "object" && window.localStorage.getItem(sessionInfo)) ||
      "{}"
  );
  const response = await fetch(`${baseURL}/${url}`, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + session.token,
    }),
  });
  if (response.status === 401) {
    window.localStorage.clear();
    window.location.href = "/login";
  }
  return await response.blob();
};

export const getMessagingRequest = async ({ url }) => {
  const response = await axiosInstance.get(url, {
    baseURL: config.REACT_APP_API_MESSAGING,
  });
  return response?.data || response;
};

export const postRequestP3 = async ({ url, data }) => {
  const response = await axiosInstance.post(url, data, {
    baseURL: config.REACT_APP_API_PAYMENT,
  });
  return response?.data || response;
};

export const getRequestP3 = async ({ url }) => {
  const response = await axiosInstance.get(url, {
    baseURL: config.REACT_APP_API_PAYMENT,
  });
  return response?.data || response;
};
