import { Group, HoverCard } from "@mantine/core";
import { nanoid } from "nanoid";
import { NotificationIcon } from "components/Svgs";
import { BsBell } from "react-icons/bs";

interface IProps {
  notifications: any[];
}

const NotificationHover = ({ notifications }: IProps) => {
  const renderData = () =>
    notifications.map((item) => {
      const firstName = item?.user?.userFirstname;
      const lastName = item?.user?.userLastname;
      let message = item?.notificationMessage;
      message = message.replace("%name", `${firstName} ${lastName}`);
      return (
        <div className="flex mb-6" key={nanoid()}>
          <p className="text-xs font-extralight">{message}</p>
          {/* <p className="text-[10px] mt-auto w-fit font-extralight flex-shrink-0">
          1 hr
        </p> */}
        </div>
      );
    });
  return (
    <Group position="left">
      <HoverCard shadow="md">
        <HoverCard.Target>
          <div>
            {notifications.length > 0 ? (
              <NotificationIcon />
            ) : (
              <BsBell size="32px" />
            )}
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          {notifications.length > 0 ? (
            <div>{renderData()}</div>
          ) : (
            <p className="text-center">No Notification</p>
          )}
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

export default NotificationHover;
