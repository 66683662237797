import { format } from "date-fns";
import { nanoid } from "nanoid";
import {
  IBrp,
  IPassport,
  IPoa,
  IReference,
  ITraining,
  IWorkExperience,
} from "types";

interface IData {
  workExperience: Array<IWorkExperience>;
  trainings: Array<ITraining>;
  references: Array<IReference>;
  passport: IPassport | null;
  brp: IBrp | null;
  poa: IPoa | null;
  dbs: IPoa | null;
}

interface PersonalDetailsProp {
  data: IData;
  setView: (args: any) => void;
}

const PersonalDetails = ({ data, setView }: PersonalDetailsProp) => {
  const {
    workExperience = [],
    references = [],
    trainings = [],
    passport,
    brp,
    poa,
    dbs,
  } = data;

  const setModalData = ({ userID, name, type }) => {
    const imageUrl = `https://zshift.io:8081/getFile/?userID=${userID}&type=${type}`;
    setView({ imageUrl, name });
  };

  return (
    <div className="w-full sm:flex py-10">
      <div className="px-4 sm:px-0 w-[40%]">
        <p className="text-bold mb-5 text-lg font-semibold">Work Experience</p>
        {workExperience.map((item) => (
          <div key={nanoid()} className="flex mb-3 items-start">
            <div className="mt-2 h-4 w-4 bg-[#9FA0A1] rounded-full mr-3"></div>
            <div>
              <p className="text-bold">{item.companyName || "N/A"}</p>
              <span>Role: {item?.workRole || "N/A"}</span> <br />
              <span>
                {format(new Date(item.startDate), "dd MMM yyyy")} -{" "}
                {format(new Date(item.endDate), "dd MMM yyyy")}
              </span>
              <p className="mt-2 max-w-[350px] leading-6">
                {item.description || "N/A"}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="h-full hidden sm:block border-l"></div>
      <div className="px-4 sm:px-0 w-[60%]">
        <p className="text-bold text-lg font-semibold ml-10">Reference</p>
        <div className=" border-b border-solid border-[#D5D6D7] pb-5">
          {references.map((i) => (
            <div key={nanoid()} className="flex ml-10">
              <span className="font-semibold">{i.referenceName}</span>
              <span className="text-primary block ml-3">
                {i.referenceEmail}
              </span>
            </div>
          ))}
        </div>

        <div className="flex mb-4 items-center border-b border-solid border-[#D5D6D7] py-5">
          <p className="text-bold font-serif ml-10">
            Biometric Resident Permit
          </p>
          <span
            className="text-primary ml-3 cursor-pointer"
            onClick={() =>
              setModalData({
                userID: brp?.userID,
                name: "Biometric Resident Permit",
                type: "BRP",
              })
            }
          >
            View
          </span>
        </div>
        <div className="flex mb-4 items-center border-b border-solid border-[#D5D6D7] pb-5">
          <p className="text-bold font-serif ml-10">Prove of Address</p>
          <span
            className="text-primary ml-3 cursor-pointer"
            onClick={() =>
              setModalData({
                userID: poa?.userID,
                name: "Prove of Address",
                type: "PROOF_OF_ADDRESS",
              })
            }
          >
            View
          </span>
        </div>
        <div className="flex mb-4 items-center border-b border-solid border-[#D5D6D7] pb-5">
          <p className="text-bold font-serif ml-10">DBS</p>
          <span
            className="text-primary ml-3 cursor-pointer"
            onClick={() =>
              setModalData({ userID: dbs?.userID, name: "DBS", type: "DBS" })
            }
          >
            View
          </span>
        </div>
        <div className="flex mb-4 items-center border-b border-solid border-[#D5D6D7] pb-5">
          <p className="text-bold font-serif ml-10">Passport</p>
          <span
            className="text-primary ml-3 cursor-pointer"
            onClick={() =>
              setModalData({
                userID: passport?.userID,
                name: "Passport",
                type: "PASSPORT",
              })
            }
          >
            View
          </span>
        </div>
        <p className="text-bold text-lg font-semibold ml-10">Training</p>
        <div className=" border-b border-solid border-[#D5D6D7] pb-5">
          {trainings.map((i) => (
            <div key={nanoid()} className="flex ml-10">
              <span className="font-semibold">{i.certificateName}</span>
              <span
                className="text-primary ml-3 cursor-pointer"
                onClick={() =>
                  setModalData({
                    userID: i?.userTrainingID,
                    name: i.certificateName,
                    type: "WORKER_TRAININGS",
                  })
                }
              >
                view
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
