import React, { useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import useSendToAPI from "hooks/useSendToAPI";
import { postRequest } from "utils/apiCalls";
import { authRequest } from "services/request";
import Logo from "images/logo.png";
import HeroImage from "images/hero3.png";
import { Link, useNavigate } from "react-router-dom";
import { PUBLIC_PATHS } from "routes";
import { errorToast } from "hooks/useToast";

const ResetPassword = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();

  const location = new URL(window.location.href);
  const staffEmail = location.searchParams.get("staffEmail") ?? "";
  const token = location.searchParams.get("token") ?? "";

  const form = useForm({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validate: {
      newPassword: (value) =>
        value.length < 6 ? "Password must have at least 6 letters" : null,
      confirmPassword: (value) =>
        value.length < 6
          ? "New Password and Confirm Password has to be the same"
          : null,
    },
  });
  const onSuccess = (data) => {
    if (data?.success) {
      window.localStorage.clear();
      navigate(PUBLIC_PATHS.LOGIN);
    } else {
      const message = data?.msg ?? "Pls Try Again!";
      errorToast(message);
    }
  };

  const { mutate, isLoading } = useSendToAPI(postRequest, onSuccess);

  const handleSubmit = (data: any) => {
    if (data.newPassword !== data.confirmPassword) {
      form.setFieldError(
        "confirmPassword",
        "New Password and Confirm Password has to be the same"
      );
    } else {
      const payload = {
        staffEmail,
        token,
        newPassword: data.newPassword,
      };
      mutate({ url: authRequest.SET_PASSWORD, data: payload });
    }
  };

  return (
    <main>
      <div className="hero-col">
        <div>
          <img src={HeroImage} className="hero-image" alt="" />
        </div>
      </div>
      <div>
        <img src={Logo} alt="" className="logo" />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <p className="section-title">Reset Password</p>
          <div className="mb-6">
            <TextInput
              size="lg"
              rightSection={
                show ? (
                  <BsEyeSlashFill
                    color="#000"
                    className="mr-5 cursor-pointer"
                    onClick={() => setShow(!show)}
                  />
                ) : (
                  <IoEyeSharp
                    color="#000"
                    className="mr-5 cursor-pointer"
                    onClick={() => setShow(!show)}
                  />
                )
              }
              type={show ? "text" : "password"}
              label="Password"
              placeholder="Enter at least six characters"
              {...form.getInputProps("newPassword")}
            />
          </div>
          <div className="mb-6">
            <TextInput
              size="lg"
              rightSection={
                showConfirm ? (
                  <BsEyeSlashFill
                    color="#000"
                    className="mr-5 cursor-pointer"
                    onClick={() => setShowConfirm(!showConfirm)}
                  />
                ) : (
                  <IoEyeSharp
                    color="#000"
                    className="mr-5 cursor-pointer"
                    onClick={() => setShowConfirm(!showConfirm)}
                  />
                )
              }
              type={showConfirm ? "text" : "password"}
              label="Confirm Password"
              placeholder=""
              {...form.getInputProps("confirmPassword")}
            />
          </div>
          <div className="w-full">
            <Button type="submit" disabled={isLoading} loading={isLoading}>
              Reset Password
            </Button>
          </div>
        </form>
        <p>
          Go back to
          <Link to={PUBLIC_PATHS.LOGIN}>
            <span className="text-primary ml-1">Sign In</span>
          </Link>
        </p>
      </div>
    </main>
  );
};

export default ResetPassword;

// const PasswordSent = ({ email, loading, handleResend }) => (
//   <div className="form">
//     <p className="section-title">Password Sent</p>
//     <p>
//       An email has been sent to{" "}
//       <span className="text-primary font-semibold">{email}</span>. You’ll
//       receive instructions on how to set a new password.
//     </p>
//     <Button
//       className="?btn mb-2 w-full py-3 mt-4"
//       disabled={loading}
//       loading={loading}
//       onClick={handleResend}
//     >
//       Resend Email
//     </Button>
//   </div>
// );
