import { useContext, useRef, useState } from "react";
import DashboardLayout from "components/DashboardLayout";
import StaffProfileModal from "components/StaffProfileModal";
import AddStaffModal from "./AddStaffModal";
import GroupInfo from "./GroupInfo";
import { queryKeys } from "utils/queryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SubScriberContext from "context/SubscriberContext";
import { ISiteData, IUser } from "types";
import { getMessagingRequest, getRequest } from "utils/apiCalls";
import { agencyRequest, messagingRequest } from "services/request";
import ChannelListSection from "./ChannelListSection";
import MessageSection from "./MessageSection";
import style from "styles/Messages.module.css";
import GotoSettingsModal from "components/GoToSettingsModal";

const Messages = () => {
  const queryCache = useQueryClient();
  const [view, setView] = useState("chats");
  const subscriber = useContext(SubScriberContext);
  const user = queryCache.getQueryData([queryKeys.user]) as IUser;
  const [activeTab, setActiveTab] = useState<string | null>("public");
  const [lastReadTimestamp, setLastReadTimestamp] = useState({
    [user.agency.agencyID]: new Date(),
  });
  const [activeSingleChatInfo, setActiveSingleChatInfo] = useState<any>({});
  const [activeGroupChatInfo, setActiveGroupChatInfo] = useState<ISiteData>({
    siteData: {
      agencyID: user.agency.agencyID,
      siteID: user.agency.agencyID,
      siteName: "Public Room",
    },
    user: {
      userID: user.agency.agencyID,
      userFirstname: user.agency.agencyName,
      userLastname: "",
    },
    pictureLink: user.agencyLogo,
  } as unknown as ISiteData);
  const groupChannelID = useRef(activeGroupChatInfo.siteData.siteID);

  const [modals, setModals] = useState({
    group: false,
    staff: false,
    active: false,
  });

  const { data: agencyStaff = [] } = useQuery(
    [queryKeys.allStaffs],
    async () =>
      getRequest({ url: agencyRequest.ALL_STAFFS(user.agency.agencyID) }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        const data = Array.isArray(res) ? res[0] : {};
        setActiveSingleChatInfo(data);
      },
    }
  );

  const markAsRead = () => {
    const channelID = groupChannelID.current;
    let path = activeTab;
    if (activeTab === "public") {
      const isAgency = channelID.toString() === user.agency.agencyID.toString();
      path = !isAgency ? "sites" : path;
    }
    const payload = {
      channelID,
      channelPath: `/channel/chat/${path}/`,
      userID: 0,
      agencyID: user.agency.agencyID,
      latRead: new Date(),
    };
    if (subscriber.stompClient?.connected) {
      subscriber.stompClient?.send(
        "/app/channel/lastread",
        {},
        JSON.stringify(payload)
      );
    }
    const newData = {
      ...lastReadTimestamp,
      [channelID]: payload.latRead,
    };
    // set new last read
    setTimeout(() => {
      setLastReadTimestamp(newData);
    }, 3000);
  };

  const { refetch } = useQuery(
    [queryKeys.groupChatHistory],
    async () => {
      const channelID = groupChannelID.current;
      let path = activeTab;
      if (activeTab === "public") {
        const isAgency =
          channelID.toString() === user.agency.agencyID.toString();
        path = !isAgency ? "sites" : path;
      }
      return getMessagingRequest({
        url: messagingRequest.GROUP_CHAT_HISTORY({
          channelID: channelID,
          channelPath: `/channel/chat/${path}/`,
          source: "agency",
          agencyID: user.agency.agencyID,
          userID: 0,
        }),
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const channelID = groupChannelID.current;
        let prevMsg: any = queryCache.getQueryData([queryKeys.chat]);
        prevMsg = prevMsg ?? {};
        const newData = {
          ...prevMsg,
          [channelID]: data?.chatHistory ?? [],
        };
        queryCache.setQueryData([queryKeys.chat], newData);
        // const lastRead = data?.channelLastread;
        // markAsRead();
      },
    }
  );

  function showModal(name: string) {
    setModals((prev) => ({
      ...prev,
      active: true,
      [name]: true,
    }));
  }

  function hideModals() {
    setModals({
      active: false,
      staff: false,
      group: false,
    });
  }

  const handleRefetch = () => {
    const channelID = groupChannelID.current;
    const prevMsg: any = queryCache.getQueryData([queryKeys.chat]);
    markAsRead();
    if (!prevMsg?.[channelID]) {
      refetch();
    }
  };

  return (
    <DashboardLayout>
      <GotoSettingsModal visible={user.rulesSet} />
      <div className="h-full flex flex-col pb-20">
        <p className="text-3xl font-semibold mb-5">Messages</p>
        <div className={style.messageContainer}>
          <ChannelListSection
            {...{
              activeTab,
              setActiveTab,
              activeGroupChatInfo,
              setActiveGroupChatInfo,
              activeSingleChatInfo,
              setActiveSingleChatInfo,
              groupChannelID,
              refetch: handleRefetch,
              user,
              agencyStaff,
              showModal,
              allMessages: subscriber.messages,
              lastReadTimestamp,
            }}
          />
          <div className="col-span-3 relative h-full">
            {view === "chats" && (
              <MessageSection
                {...{
                  subscriber,
                  activeTab,
                  activeGroupChatInfo,
                  activeSingleChatInfo,
                  user,
                  allMessages: subscriber.messages,
                  agencyStaff,
                  setView,
                  markAsRead,
                  lastReadTimestamp,
                }}
              />
            )}
            {view === "info" && (
              <GroupInfo
                data={activeGroupChatInfo}
                goBack={() => setView("chats")}
              />
            )}
            {view === "user-info" && (
              <StaffProfileModal hideModal={hideModals} />
            )}
          </div>
        </div>
        {modals.active && (
          <>
            <div className="modal-background" onClick={hideModals}></div>
            {modals.staff && <AddStaffModal hideModal={hideModals} />}
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Messages;
