export const tableColumn = [
  {
    id: "shiftDate",
    header: "Shift Date",
    accessorFn: (row) => {
      const date = row?.validatedShift.shiftDate
        ? new Date(row?.validatedShift.shiftDate).toLocaleDateString()
        : "N/A";
      return date;
    },
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "siteName",
    header: "Site",
    accessorFn: (row) => row?.site ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "name",
    header: "Staff Name",
    accessorFn: (row) => row?.name ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.role ?? "N/A",
    id: "role",
    header: "Role",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) => row?.validatedShift.shiftRate ?? "N/A",
    id: "shiftRate",
    header: "Shift Rate",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "hours",
    header: "Hours",
    accessorFn: (row) => row?.hours ?? "N/A",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) =>
      row?.validatedShift.validatedWithWorked ? "Hours Worked" : "Hours Booked",
    id: "paidWith",
    header: "Paid With",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    accessorFn: (row) =>
      row?.validatedShift.validatedWithWorked
        ? row?.validatedShift.actualPayout
        : row?.validatedShift.bookedPayout,
    id: "payout",
    header: "Payout",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
];

export const invoiceColumn = [
  {
    id: "id",
    header: "Staff ID",
    accessorFn: (row) => row.userID,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "name",
    header: "Staff Name",
    accessorFn: (row) => row.userName,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "site",
    header: "Site",
    accessorFn: (row) => row?.shiftsForInvoiceData?.site,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "shiftId",
    header: "Shift ID",
    accessorFn: (row) => row?.shiftsForInvoiceData?.shiftID,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "shiftDate",
    header: "Shift Date",
    accessorFn: (row) => row?.shiftsForInvoiceData?.shiftDate,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "startTime",
    header: "Start Time",
    accessorFn: (row) => row?.shiftsForInvoiceData?.startTime,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "endTime",
    header: "End Time",
    accessorFn: (row) => row?.shiftsForInvoiceData?.endTime,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
  {
    id: "shiftStatus",
    header: "Shift Status",
    accessorFn: (row) => row?.shiftsForInvoiceData?.shiftStatus,
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
  },
];
